(function(APP, window, undefined){ 'use strict';
  APP.textRendering = {
    createSvgTextRenderer: createSvgTextRenderer,
    createInteractiveTextRenderer: createInteractiveTextRenderer,
    createTextKeyboardListener: createTextKeyboardListener,
  };
  
  function createSvgTextRenderer (textModel) {
    var lines, fieldNode = APP.dom.createSvgNode('g', { 'fill-rule': 'evenodd' });
    toggleListeners(true);
    repopulate();
    redraw();
    reposition();
    return {
      getNode: function(){
        return fieldNode;
      },
      destroy: function(){
        if (!textModel) return;
        toggleListeners(false);
        APP.dom.removeNode(fieldNode);
        fieldNode = null;
        textModel = null;
      }
    };
    function toggleListeners (active) {
      var fnName = active ? 'on' : 'off';
      textModel[fnName]('repopulate', repopulate);
      textModel[fnName]('redraw', redraw);
      textModel[fnName]('rectChange', reposition);
    }
    function repopulate () {
      APP.dom.removeChildren(fieldNode);
      lines = textModel.lines.map(function(line){
        return createLine(line, fieldNode);
      });
    }
    function redraw () {
      lines.forEach(function(line){
        line.redraw();
      });
    }
    function reposition () {
      applyTransform(fieldNode, textModel.x, textModel.y);
    }
    function createLine (lineModel, fieldNode) {
      var lineNode = APP.dom.createSvgNode('g', null, fieldNode),
        chars = lineModel.chars.map(function(charModel){
          return createChar(charModel, lineNode);
        });
      return {
        redraw: redraw,
      };
      function redraw () {
        applyTransform(lineNode, lineModel.x, lineModel.y);
        chars.forEach(function(char){
          char.redraw();
        });
      }
    }
    function createChar (charModel, lineNode) {
      var charNode = APP.dom.createSvgNode('path', null, lineNode);
      return {
        redraw: redraw,
      };
      function redraw () {
        charNode.setAttribute('d', charModel.glyphPath);
        applyTransform(charNode,
          charModel.x + charModel.kerningOffset, charModel.y,
          textModel.fontScaleX, textModel.fontScaleY);
      }
    }
  }
  
  function createInteractiveTextRenderer (textModel, pressFn, dblclickFn) {
    var wrapNode = APP.dom.createSvgNode('g'),
      hitRect = APP.dom.createSvgNode('rect', {
          fill: 'transparent',
          display: 'none',
          pointer: 'cursor'
        }, wrapNode),
      charNodes = [];
    toggleListeners(true);
    repopulate();
    redraw();
    reposition(textModel.rect);
    return {
      getNode: function(){
        return wrapNode;
      },
      toggleHighlight: function(active){
        APP.dom.setAttributes(hitRect, { display: active ? '' : 'none' });
      },
      destroy: function(){
        if (!textModel) return;
        toggleListeners(false);
        APP.dom.removeNode(wrapNode);
        wrapNode = null;
        textModel = null;
      }
    };
    function toggleListeners (active) {
      var fnName = active ? 'on' : 'off';
      textModel[fnName]('repopulate', repopulate);
      textModel[fnName]('redraw', redraw);
      textModel[fnName]('rectChange', onRectChange);
      fnName = active ? 'addEventListener' : 'removeEventListener';
      wrapNode[fnName]('mousedown', onMouseDownTouchStart, false);
      wrapNode[fnName]('touchstart', onMouseDownTouchStart, false);
      wrapNode[fnName]('click', onClick, false);
      wrapNode[fnName]('dblclick', onDblClick, false);
    }
    function onRectChange (event) {
      reposition(event.data);
    }
    function repopulate () {
      // var colorGenerator = APP.utils.createColorGenerator();
      APP.dom.removeChildren(wrapNode);
      wrapNode.appendChild(hitRect);
      charNodes.length = 0;
      textModel.lines.forEach(function(lineModel){
        lineModel.chars.forEach(function(charModel){
          var charNode = APP.dom.createSvgNode('rect', {
            fill: 'transparent'
            // fill: colorGenerator.next()
          }, wrapNode);
          charNode.charModel = charModel;
          charNodes.push(charNode);
        });
      });
    }
    function redraw () {
      var charIndex = 0;
      adjustHitRect(textModel);
      textModel.lines.forEach(function(lineModel){
        lineModel.chars.forEach(function(charModel){
          var charNode = charNodes[charIndex++],
            // minWidth is a quick workaround for an issue that should be addressed in the model.
            // The font 3929 features overly wide caps and rather small minuscules with highly
            // negative kerning. So by subtracting the kerning values from the nominal width
            // we actually end up with negative width values, which is obviously a geometrical
            // impossibility.
            // The Kerning HUD actually suffers from the same issue, but as we don’t use <rect>
            // elements there, we still create valid path segments, albeit with a highlight
            // rectangle that is very far off from the glyph’s position.
            minWidth = charModel.height * 0.15,
            width = Math.max(minWidth, charModel.width),
            offsetX = charModel.width - width;
          APP.dom.setAttributes(charNode, {
            x: lineModel.x + charModel.x + offsetX,
            y: lineModel.y + charModel.y,
            width: width,
            height: charModel.height,
          });
        });
      });
    }
    function reposition (rect) {
      applyTransform(wrapNode, rect.left, rect.top);
    }
    function onMouseDownTouchStart (event) {
      if (event.button) return;
      event.preventDefault();
      event.stopPropagation();
      pressFn(event, event.target && event.target.charModel);
    }
    function onClick (event) {
      event.stopPropagation();
    }
    function onDblClick (event) {
      event.preventDefault();
      dblclickFn(event, event.target && event.target.charModel);
    }
    function adjustHitRect (textModel) {
      var rect = textModel.getHitRect(13);
      APP.dom.setAttributes(hitRect, {
        x: rect.left - textModel.x,
        y: rect.top - textModel.y + textModel.ascent,
        width: rect.width,
        height: rect.height
      });
    }
  }
  
  function createTextKeyboardListener (textModel, editFn, removeFn, ensureValidPosition) {
    var unlisten = null, fnMap = createFnMap();
    textModel.on('destroy', onDestroy);
    return {
      toggleListening: toggleListening,
    };
    function toggleListening (active) {
      if (active && !unlisten) {
        unlisten = APP.keyboard.listen(fnMap);
      } else if (!active && unlisten) {
        unlisten();
        unlisten = null;
      }
    }
    function createFnMap () {
      var fnMap = {}, keyCodes = APP.keyboard.keyCodes;
      fnMap[keyCodes.left]      = createIncrementor('x', true);
      fnMap[keyCodes.up]        = createIncrementor('y', true);
      fnMap[keyCodes.right]     = createIncrementor('x');
      fnMap[keyCodes.down]      = createIncrementor('y');
      fnMap[keyCodes.enter]     = editFn;
      fnMap[keyCodes.backspace] = removeFn;
      fnMap[keyCodes.del]       = removeFn;
      return fnMap;
    }
    function createIncrementor (key, negative) {
      var mult = negative ? -1 : 1;
      return function incrementProperty (event) {
        textModel[key] = textModel[key] + mult * (event.shiftKey ? 10 : 1);
        ensureValidPosition();
      };
    }
    function onDestroy () {
      toggleListening(false);
    }
  }
  
  function applyTransform (node, x, y, scaleX, scaleY) {
    if (scaleX === undefined) scaleX = 1;
    if (scaleY === undefined) scaleY = 1;
    node.setAttribute('transform', 'matrix('+scaleX+',0,0,'+scaleY+','+x+','+y+')');
  }
  
})(this.APP || (this.APP = {}), this);
