(function (APP, $, undefined) {
  "use strict";
  APP.page = {
    init: init,
    createLightBox: createLightBox,
  };

  function init(exportView) {
    initFonts();
    initOutput(exportView);
    // initDevice();
  }

  function initFonts() {
    var fonts = $(".font-selection .fonts-list");
    fonts.find("li").first().addClass("active");

    $("> li a", fonts).on("click", function (e) {
      if (!$(e.target).parent().hasClass("active")) {
        $(e.target).parent().parent().find(".active").removeClass("active");
        $(e.target).parent().addClass("active");
        return false;
      }
    });
  }

  function initOutput(exportView) {
    var $output = $("section.output"),
      active = false,
      sidePanel = createSidePanel($output[0]);
    $(".btn.export").on("click", onExportButtonClick);
    function onExportButtonClick(e) {
      e.preventDefault();
      e.stopPropagation();
      togglePanel();
    }
    function onDocumentClick(e) {
      var $target = $(e.target);
      if (isCloseButton($target) && sidePanel.isActive()) {
        sidePanel.hide();
      } else if ($target === "image") {
      } else if (
        isCloseButton($target) ||
        (!isPartOfPanel($target) && !isPartOfDialog($target))
      ) {
        togglePanel();
      }
    }
    function togglePanel() {
      active = !active;
      $output.toggleClass("active", active);
      $("main.wrapper").toggleClass("inactive", active);
      $output.toggle("slide", { direction: "right" }, "500");
      $(document)[active ? "on" : "off"]("click", onDocumentClick);
      if (active) exportView.resetForms();
    }
    function isPartOfPanel($node) {
      return !!$node.closest($output).length;
    }
    function isCloseButton($node) {
      return !!$node.closest(".output--properties .btn-close").length;
    }
  }

  function createSidePanel(wrapNode) {
    var currentContentName = null,
      sideWrapNode = wrapNode.querySelector(".output-content-right"),
      buttonNodeMap = collectNodeMap(wrapNode, "data-target"),
      contentNodeMap = collectNodeMap(wrapNode, "data-content");
    enableButtons(buttonNodeMap, contentNodeMap, toggleContent);
    return {
      isActive: isActive,
      hide: hide,
    };
    function isActive() {
      return !!currentContentName;
    }
    function hide() {
      if (currentContentName) toggleContent(currentContentName);
    }
    function enableButtons(buttonNodeMap, contentNodeMap, toggleContent) {
      Object.keys(buttonNodeMap).forEach(function (key) {
        if (contentNodeMap[key])
          buttonNodeMap[key].addEventListener(
            "click",
            function (event) {
              event.preventDefault();
              toggleContent(key);
            },
            false
          );
      });
    }
    function toggleContent(name) {
      var active = !!name && name !== currentContentName;
      APP.dom.toggleClass(wrapNode, "rightContentActive", active);
      APP.dom.toggleClass(sideWrapNode, "hidden", !active);
      toggleElements(currentContentName, false);
      currentContentName = active ? name : null;
      toggleElements(currentContentName, true);
    }
    function toggleElements(contentName, active) {
      var button = contentName && buttonNodeMap[contentName],
        content = contentName && contentNodeMap[contentName];
      if (button) APP.dom.toggleClass(button.parentNode, "active", !!active);
      if (content) APP.dom.toggleClass(content, "hidden", !active);
    }
    function collectNodeMap(wrapNode, attributeName) {
      return Array.prototype.slice
        .call(wrapNode.querySelectorAll("[" + attributeName + "]"))
        .reduce(function (nodeMap, node) {
          nodeMap[node.getAttribute(attributeName)] = node;
          return nodeMap;
        }, {});
    }
  }

  function createLightBox() {
    var $metaNavOutput = $(".meta-nav--ouput"),
      currentContentName,
      lightBox = APP.utils.makeEventDispatcher(
        {
          getContentName: getContentName,
        },
        "show hide"
      );
    $(".meta-nav a[rel]").on("click", onTriggerClick);
    return lightBox;
    function getContentName() {
      return currentContentName;
    }
    function onTriggerClick(e) {
      e.preventDefault();
      e.stopPropagation();
      show($(e.target).attr("rel"));
    }
    function onDocumentClick(e) {
      var $target = $(e.target);
      if (
        (!isPartOfPanel($target) && !isPartOfDialog($target)) ||
        isCloseButton($target)
      ) {
        hide();
      }
    }
    function show(contentName) {
      if (currentContentName === contentName) return;
      togglePanel(contentName);
    }
    function hide() {
      if (!currentContentName) return;
      togglePanel();
    }
    function togglePanel(contentName) {
      var previousContentName = currentContentName;
      currentContentName = contentName;
      $("html").toggleClass("no-scroll", !!contentName);
      $metaNavOutput.toggleClass("hidden", !contentName);
      if (previousContentName) {
        $metaNavOutput.find("." + previousContentName).removeClass("visible");
        $(document).off("click", onDocumentClick);
        lightBox.trigger("hide", previousContentName);
      }
      if (contentName) {
        $metaNavOutput.find("." + contentName).addClass("visible");
        $(document).on("click", onDocumentClick);
        lightBox.trigger("show", contentName);
      }
    }
    function isPartOfPanel($node) {
      return !!$node.closest($metaNavOutput).length;
    }
    function isCloseButton($node) {
      return !!$node.closest(".btn-close--meta-nav").length;
    }
  }

  function isPartOfDialog($node) {
    return !!$node.closest(".dialogBackdrop").length;
  }

  function initDevice() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      $(".image-load").find(".btn-icon.btn-photo").css("display", "none");
    }
  }
})(this.APP || (this.APP = {}), this.jQuery);
