(function(APP, document){ 'use strict';
  APP.welcome = {
    createWelcomeView: createWelcomeView,
    setDummyData: setDummyData
  };
  function createWelcomeView (router, layout) {
    var wrapNode = document.querySelector('.landingpage'),
      directOrderButton = wrapNode.querySelector('.directOrderButton'),
      startButton = wrapNode.querySelector('.startButton');
    if (directOrderButton) directOrderButton.addEventListener('click', onDirectOrderClick, false);
    if (startButton) startButton.addEventListener('click', onStartClick, false);
    function onDirectOrderClick (event) {
      event.preventDefault();
      // console.warn('no view for direct order yet');
      setAlternateDummyData(layout, true);
      router.goTo('object-size');
    }
    function onStartClick (event) {
      event.preventDefault();
      if (event.altKey) setDummyData(layout);
      router.goTo('image-load');
    }
  }
  function setDummyData (layout, includeMeasurement) {
    layout.imageUrl = './dummy/redstone.jpg';
    if (includeMeasurement) layout.measurement = {
      points: [{x:208, y:90}, {x:760, y:75}, {x:763, y:846}, {x:237, y:918}],
      width: 550,
      height: 800
    };
  }
  function setAlternateDummyData (layout, includeMeasurement) {
    layout.imageUrl = './dummy/yellowstone-95x50x14.jpg';
    if (includeMeasurement) layout.measurement = {
      points: [{x:144, y:87}, {x:357, y:84}, {x:346, y:483}, {x:152, y:451}],
      width: 500,
      height: 950
    };
  }
})(this.APP || (this.APP = {}), document);
