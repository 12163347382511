(function(APP, document, undefined){ 'use strict';
  APP.hud = {
    // createWidthsManager: createWidthsManager,
    // createHighlightManager: createHighlightManager,
    // createKerningManager: createKerningManager,
    createHud: createHud,
  };
  function createHud (canvas, viewport) {
    var hudSvg = document.querySelector('.hudSvg'),
      highlightManager = createFieldHighlightManager(canvas, viewport, hudSvg),
      kerningManager = createKerningManager(canvas, viewport, hudSvg),
      widthsManager = createWidthsManager(canvas, viewport, hudSvg);
    viewport.on(viewport.events.viewBoxChange, adjustDimensions);
    adjustDimensions();
    function adjustDimensions () {
      var dimensions = viewport.getDimensions();
      APP.dom.setAttributes(hudSvg, {
        width: dimensions.width,
        height: dimensions.height,
        viewBox: [0, 0, dimensions.width, dimensions.height].join(' '),
      });
    }
  }
  function createFieldHighlightManager (canvas, viewport, hudSvg) {
    var currentTextField,
      wrapNode = hudSvg.querySelector('.fieldMarkers'),
      polygons = [
        APP.dom.createSvgNode('polygon', {
            fill: 'none', stroke: 'white', 'stroke-width': 3
          }, wrapNode),
        APP.dom.createSvgNode('polygon', {
          fill: 'none', stroke: 'black', 'stroke-width': 1.5
        }, wrapNode),
      ];
    wrapNode.setAttribute('opacity', 0.5);
    hide();
    canvas.on('textFieldChange', onTextFieldChange);
    viewport.on('contentChange', onViewportChange);
    function onTextFieldChange (event) {
      var textField = event.data;
      if (currentTextField) currentTextField.off('rectChange', onRectChange);
      currentTextField = textField;
      adjustToField(textField);
    }
    function adjustToField (textField) {
      if (textField) {
        adjustRect();
        textField.on('rectChange', onRectChange);
        show();
      } else {
        hide();
      }
    }
    function onRectChange (event) {
      adjustRect();
    }
    function adjustRect () {
      if (!currentTextField) return;
      var viewportPoints = currentTextField.getRectViewportPoints(10),
        polygonPoints = pointsToPolygonString(viewportPoints);
      polygons.forEach(function(polygon){
        polygon.setAttribute('points', polygonPoints);
      });
    }
    function onViewportChange (event) {
      adjustRect();
    }
    function show () {
      wrapNode.removeAttribute('display');
    }
    function hide () {
      wrapNode.setAttribute('display', 'none');
    }
  }
  function createWidthsManager (canvas, viewport, hudSvg) {
    var widthsMode = false, widthDisplays = [],
      svgParentNode = hudSvg.querySelector('.widthMarkers'),
      domParentNode = document.querySelector('.widthsDisplayWrap');
    canvas.on(canvas.events.widthsModeChange, onWidthsModeChange);
    canvas.on(canvas.events.textFieldAdd, onTextFieldAdd);
    canvas.on(canvas.events.textFieldRemove, onTextFieldRemove);
    toggleActive(canvas.getWidthsMode());
    function onWidthsModeChange (event) {
      toggleActive(event.data);
    }
    function toggleActive (active) {
      var fnName = active ? 'on' : 'off';
      if (widthsMode === active) return;
      widthsMode = active;
      if (active) createWidthsDisplays();
      else destroyWidthDisplays();
    }
    function createWidthsDisplays () {
      widthDisplays = canvas.getTextFields().map(createDisplay);
    }
    function destroyWidthDisplays () {
      widthDisplays.forEach(function(widthDisplay){
        widthDisplay.destroy();
      });
      widthDisplays.length = 0;
    }
    function onTextFieldAdd (event) {
      if (!widthsMode) return;
      widthDisplays.push(createDisplay(event.data));
    }
    function onTextFieldRemove (event) {
      if (!widthsMode) return;
      var index = widthDisplays.findIndex(function(display){
        return display.belongsToField(event.data);
      });
      if (index < 0) return;
      widthDisplays[index].destroy();
      widthDisplays.splice(index, 1);
    }
    function createDisplay (field) {
      return createWidthsDisplay(field, svgParentNode, domParentNode, canvas, viewport);
    }
  }
  function createWidthsDisplay (textField, svgParentNode, domParentNode, canvas, viewport) {
    var offsetY = 0,
      // markersRenderer = createWidthMarkersRenderer(offsetY, svgParentNode, canvas),
      markersRenderer = createWidthMarkersRenderer(
        offsetY, svgParentNode, canvas, viewport),
      numberRenderer = createWidthNumberRenderer(offsetY, domParentNode, canvas, viewport);
    if (!textField.events) debugger;
    textField.on(textField.events.rectChange, onRectChange);
    adjustToRect(textField.rect);
    return {
      belongsToField: belongsToField,
      destroy: destroy,
    };
    function belongsToField (field) {
      return field === textField;
    }
    function destroy () {
      textField.off(textField.events.rectChange, onRectChange);
      markersRenderer.destroy();
      numberRenderer.destroy();
    }
    function onRectChange (event) {
      adjustToRect(event.data);
    }
    function adjustToRect (rect) {
      markersRenderer.adjustToRect(rect);
      numberRenderer.adjustToRect(rect);
    }
  }
  function createWidthMarkersRenderer (offsetY, svgParentNode, canvas, viewport) {
    var wrapNode = APP.dom.createSvgNode('g', { 'opacity': 0.5 }, svgParentNode),
      paths = [
        APP.dom.createSvgNode('path', {
            fill: 'none', stroke: 'white', 'stroke-linecap': 'square', 'stroke-width' : 3
          }, wrapNode),
        APP.dom.createSvgNode('path', {
            fill: 'none', stroke: 'black', 'stroke-linecap': 'square', 'stroke-width' : 1.5
          }, wrapNode),
      ],
      currentRect;
    viewport.on(viewport.events.contentChange, onViewportChange);
    return {
      adjustToRect: adjustToRect,
      destroy: destroy,
    };
    function onViewportChange () {
      if (currentRect) adjustToRect(currentRect);
    }
    function adjustToRect (rect) {
      currentRect = rect;
      paths.reduce(function(pathString, path){
        return path.setAttribute('d', pathString), pathString;
      }, getPathString(rect));
    }
    function getPathString (rect) {
      var scale = 1 / canvas.getTextureScale(),
        baseY = rect.bottom + offsetY * scale,
        left = rect.left,
        right = rect.right,
        height = 7 * scale,
        width = Math.min(height * 1.5, rect.width / 3);
      return [
          { x: left, y: baseY - height }, { x: left, y: baseY + height },
          { x: left, y: baseY }, { x: left + width, y: baseY },
          { x: right, y: baseY - height }, { x: right, y: baseY + height },
          { x: right, y: baseY }, { x: right - width, y: baseY },
        ].map(canvas.canvasToViewport)
        .map(function(point, i){
          return (i % 2 ? 'L' : 'M') + coords(point);
        }).join('');
      function coords (point) {
        return point.x + ',' + point.y;
      }
    }
    function destroy () {
      viewport.off(viewport.events.contentChange, onViewportChange);
      APP.dom.removeNode(wrapNode);
    }
  }
  // function createWidthMarkersRenderer (offsetY, svgParentNode, canvas) {
  //   var wrapNode = APP.dom.createSvgNode('g', { 'opacity': 0.5 }, svgParentNode),
  //     strokeNode = APP.dom.createSvgNode('path',
  //       { fill:'none', stroke:'black', 'stroke-linecap':'square' }, wrapNode),
  //     fillNode = APP.dom.createSvgNode('path',
  //       { fill:'none', stroke:'white', 'stroke-linecap':'square' }, wrapNode),
  //     currentRect;
  //   adjustToTextureScale();
  //   canvas.on('dimensionsChange', adjustToTextureScale);
  //   return {
  //     adjustToRect: adjustToRect,
  //     destroy: destroy,
  //   };
  //   function adjustToRect (rect) {
  //     var textureScale = canvas.getTextureScale(),
  //       height = 20 / textureScale,
  //       top = rect.bottom + offsetY / textureScale - height/2,
  //       d = assembleDrawCommands([
  //         ['M', rect.left, top],
  //         ['v', height],
  //         ['m', 0, -height/2],
  //         ['h', rect.width],
  //         ['m', 0, -height/2],
  //         ['v', 0, height]
  //       ]);
  //     currentRect = rect;
  //     strokeNode.setAttribute('d', d);
  //     fillNode.setAttribute('d', d);
  //   }
  //   function assembleDrawCommands (commands) {
  //     return commands.map(function(command){
  //       return command[0] + command.slice(1).join(',');
  //     }).join(' ');
  //   }
  //   function adjustToTextureScale () {
  //     var textureScale = canvas.getTextureScale();
  //     if (currentRect) adjustToRect(currentRect);
  //     strokeNode.setAttribute('stroke-width', 8 / textureScale);
  //     fillNode.setAttribute('stroke-width', 4 / textureScale);
  //   }
  //   function destroy () {
  //     canvas.off('dimensionsChange', adjustToTextureScale);
  //     APP.dom.removeNode(wrapNode);
  //   }
  // }
  function createWidthNumberRenderer (offsetY, domParentNode, canvas, viewport) {
    var x = 0, y = 0,
      anchorNode = APP.dom.createHtmlNode('div', { className: 'widthIndicator' }, domParentNode),
      contentNode = APP.dom.createHtmlNode('div', { className: 'widthContent' }, anchorNode),
      currentRect;
    viewport.on('viewBoxChange contentChange', onViewportChange);
    canvas.on('dimensionsChange', onDimensionsChange);
    return {
      adjustToRect: adjustToRect,
      destroy: destroy,
    };
    function adjustToRect (rect) {
      currentRect = rect;
      x = (rect.left + rect.right) / 2;
      y = rect.bottom + offsetY / canvas.getTextureScale();
      realign();
      refill(rect.width);
    }
    function onViewportChange () {
      realign();
    }
    function onDimensionsChange () {
      if (currentRect) adjustToRect(currentRect);
    }
    function realign () {
      var point = canvas.canvasToViewport({ x:x, y:y });
      anchorNode.style.left = point.x + 'px';
      anchorNode.style.top = point.y + 'px';
    }
    function refill (millimeters) {
      // var cm = Math.round(millimeters) / 10;
      // contentNode.innerHTML = APP.i18n.formatNumber(cm, { minimumFractionDigits: 1 }) + ' cm';
      contentNode.innerHTML = APP.i18n.formatNumber(millimeters, { maximumFractionDigits: 0 }) + ' mm';
    }
    function destroy () {
      viewport.off('viewBoxChange contentChange', onViewportChange);
      canvas.off('dimensionsChange', onDimensionsChange);
      APP.dom.removeNode(anchorNode);
    }
  }
  // ToDo: test for support of pointer-events: none and use this for legacy compatibility
  // function createHighlightManager (canvas, parentNode) {
  //   var currentTextField,
  //     wrapNode = APP.dom.createSvgNode('g', { 'fill': 'transparent', 'pointer-events': 'none', 'opacity': 0.5 }),
  //     rects = [
  //       APP.dom.createSvgNode('rect', { 'stroke': 'black' }, wrapNode),
  //       APP.dom.createSvgNode('rect', { 'stroke': 'white' }, wrapNode)
  //     ];
  //   adjustStrokeWidth();
  //   canvas.on('dimensionsChange', onDimensionsChange);
  //   canvas.on('textFieldChange', onTextFieldChange);
  //   function onDimensionsChange () {
  //     adjustStrokeWidth();
  //     if (currentTextField) adjustToRect(currentTextField.rect);
  //   }
  //   function adjustStrokeWidth () {
  //     var textureScale = canvas.getTextureScale();
  //     rects[0].setAttribute('stroke-width', 8 / textureScale);
  //     rects[1].setAttribute('stroke-width', 4 / textureScale);
  //   }
  //   function onTextFieldChange (event) {
  //     var textField = event.data;
  //     if (currentTextField) currentTextField.off('rectChange', onRectChange);
  //     currentTextField = textField;
  //     adjustToField(textField);
  //   }
  //   function adjustToField (textField) {
  //     if (textField) {
  //       adjustToRect(textField.rect);
  //       textField.on('rectChange', onRectChange);
  //       parentNode.appendChild(wrapNode);
  //     } else {
  //       APP.dom.removeNode(wrapNode);
  //     }
  //   }
  //   function onRectChange (event) {
  //     adjustToRect(event.data);
  //   }
  //   function adjustToRect (rect) {
  //     adjust(rect.left, rect.top, rect.width, rect.height);
  //   }
  //   function adjust (x, y, width, height) {
  //     var margin = 10 / canvas.getTextureScale();
  //     rects.forEach(function(rect){
  //       APP.dom.setAttributes(rect, {
  //         x: x - margin,
  //         y: y - margin,
  //         width: width + 2 * margin,
  //         height: height + 2 * margin,
  //       });
  //     });
  //   }
  // }
  function createKerningManager (canvas, viewport, hudSvg) {
    var currentTextField,
      kerningHud = createKerningHud(canvas.canvasToViewport),
      highlightBox = createKerningHighlightBox(hudSvg, canvas.canvasToViewport);
      // highlightBox = createHighlightBox(canvas, parentNode);
    canvas.on(canvas.events.textFieldChange, onTextFieldChange);
    canvas.on(canvas.events.kerningModeChange, onKerningModeChange);
    viewport.on('viewBoxChange contentChange', onViewportChange);
    function onTextFieldChange (event) {
      toggleListeners(currentTextField, false);
      currentTextField = event.data;
      if (currentTextField && currentTextField.font.getIsOrnament()) {
        currentTextField = null;
      }
      toggleListeners(currentTextField, true);
      adjust();
    }
    function onKerningModeChange () {
      adjust();
    }
    function onFieldPropertyChange () {
      adjust();
    }
    function onViewportChange () {
      adjust();
    }
    function adjust (hudOnly) {
      var char = !canvas.getKerningMode() ? null :
        currentTextField && currentTextField.currentChar;
      kerningHud.adjustToChar(char);
      if (!hudOnly) highlightBox.adjustToRect(char && char.rect);
    }
    function toggleListeners (textField, active) {
      if (!textField) return;
      textField[active ? 'on' : 'off']('rectChange charChange', onFieldPropertyChange);
    }
  }
  function createKerningHud (canvasToViewport) {
    var offset = 12,
      kerningDisplay = createKerningDisplay(canvasToViewport, offset),
      kerningButtons = createKerningButtons(canvasToViewport, offset),
      keyboardListener = createKerningKeyboardListener();
    return {
      adjustToChar: adjustToChar,
    };
    function adjustToChar (char) {
      kerningDisplay.adjustToChar(char);
      kerningButtons.adjustToChar(char);
      keyboardListener.adjustToChar(char);
    }
  }
  function createKerningDisplay (canvasToViewport, offset) {
    var wrapNode = document.querySelector('.kerningDisplayWrap'),
      displayNode = wrapNode.querySelector('.kerningDisplay');
    return {
      adjustToChar: adjustToChar,
    };
    function adjustToChar(char) {
      adjustPosition(char);
      adjustText(char);
    }
    function adjustPosition (char) {
      var rect = char && char.rect,
        point = rect && canvasToViewport({
          x: (rect.left + rect.right) / 2,
          y: rect.top
        });
      if (point) point.y -= offset;
      moveToPoint(wrapNode, point);
    }
    function adjustText (char) {
      if (char) displayNode.innerHTML = addSignPrefix(char.kerning) + '%';
    }
    function addSignPrefix (num) {
      return (
        num < 0 ? '' :
        num > 0 ? '+' :
        '±') + num;
    }
  }
  function createKerningButtons (canvasToViewport, offset) {
    var currentChar,
      wrapNode = document.querySelector('.kerningButtonWrap'),
      decreaseButton = wrapNode.querySelector('.decrease'),
      increaseButton = wrapNode.querySelector('.increase');
    decreaseButton.addEventListener('click', onDecreaseClick, false);
    increaseButton.addEventListener('click', onIncreaseClick, false);
    return {
      adjustToChar: adjustToChar,
    };
    function adjustToChar(char) {
      currentChar = char;
      adjustPosition(char);
    }
    function adjustPosition (char) {
      var rect = char && char.rect,
        point = rect && canvasToViewport({
          x: (rect.left + rect.right) / 2,
          y: rect.bottom
        });
      if (point) point.y += offset;
      moveToPoint(wrapNode, point);
    }
    function onDecreaseClick (event) {
      event.preventDefault();
      changeKerning(-1);
    }
    function onIncreaseClick (event) {
      event.preventDefault();
      changeKerning(1);
    }
    function changeKerning (increment) {
      if (currentChar) currentChar.kerning += increment;
    }
  }
  function createKerningKeyboardListener () {
    var currentChar, unlisten, fnMap = createFnMap();
    return {
      adjustToChar: adjustToChar,
    };
    function adjustToChar (char) {
      if (char !== currentChar) disable();
      currentChar = char;
      if (char) enable();
      else disable();
    }
    function enable () {
      if (!unlisten) unlisten = APP.keyboard.listen(fnMap);
    }
    function disable () {
      if (unlisten) unlisten = void unlisten();
    }
    function createFnMap () {
      var fnMap = {}, keyCodes = APP.keyboard.keyCodes;
      fnMap[keyCodes.left] = createIncrementor(true);
      fnMap[keyCodes.right] = createIncrementor();
      return fnMap;
    }
    function createIncrementor (negative) {
      var mult = negative ? -1 : 1;
      return function incrementProperty (event) {
        currentChar.kerning = currentChar.kerning + mult * (event.shiftKey ? 10 : 1);
      };
    }
  }
  function moveToPoint (node, point) {
    if (point) {
      node.style.removeProperty('display');
      node.style.left = point.x + 'px';
      node.style.top = point.y + 'px';
    } else {
      node.style.display = 'none';
    }
  }
  function createKerningHighlightBox (hudSvg, canvasToViewport) {
    var wrapNode = hudSvg.querySelector('.kerningMarkers'),
      paths = [
        APP.dom.createSvgNode('path', {
          fill: 'none', stroke: 'white', 'stroke-linecap': 'square', 'stroke-width': 3
        }, wrapNode),
        APP.dom.createSvgNode('path', {
          fill: 'none', stroke: 'black', 'stroke-linecap': 'square', 'stroke-width': 1.5
        }, wrapNode),
      ];
    wrapNode.setAttribute('opacity', 0.5);
    return {
      adjustToRect: adjustToRect,
    };
    function adjustToRect (rect) {
      if (rect) {
        show();
        paths.reduce(function(pathString, path){
          return path.setAttribute('d', pathString), pathString;
        }, getPathString(rect));
      } else {
        hide();
      }
    }
    function getPathString (rect) {
      var height = rect.height * 0.1, width = Math.min(height, rect.width / 3);
        return [
          [
            { x: rect.left, y: rect.top + height },
            { x: rect.left, y: rect.top },
            { x: rect.left + width, y: rect.top },
          ], [
            { x: rect.left + rect.width - width, y: rect.top },
            { x: rect.left + rect.width, y: rect.top },
            { x: rect.left + rect.width, y: rect.top + height },
          ], [
            { x: rect.left + rect.width, y: rect.top + rect.height - height },
            { x: rect.left + rect.width, y: rect.top + rect.height },
            { x: rect.left + rect.width - width, y: rect.top + rect.height },
          ], [
            { x: rect.left + width, y: rect.top + rect.height },
            { x: rect.left, y: rect.top + rect.height },
            { x: rect.left, y: rect.top + rect.height - height },
          ]
        ].map(function(segment){
          return segment.map(canvasToViewport);
        }).map(function(segment){
          return 'M' + coords(segment[0]) + 'L' + coords(segment[1]) + 'L' + coords(segment[2]);
        }).join('');
      function coords (point) {
        return point.x + ',' + point.y;
      }
    }
    function show () {
      wrapNode.removeAttribute('display');
    }
    function hide () {
      wrapNode.setAttribute('display', 'none');
    }
  }
  // function createHighlightBox (canvas, parentNode) {
  //   var wrapNode = APP.dom.createSvgNode('g', { 'pointer-events': 'none' }),
  //     shadowWrap = createLineWrap(canvas, wrapNode, 'black', 8, 'square', 0.5),
  //     mainWrap = createLineWrap(canvas, wrapNode, 'white', 4),
  //     comboLines = '1234'.split('').map(function(){
  //       return createMultiLine(shadowWrap, mainWrap);
  //     });
  //   return {
  //     adjustToRect: adjustToRect,
  //   };
  //   function adjustToRect (rect) {
  //     if (rect) {
  //       parentNode.appendChild(wrapNode);
  //       adjustLines(rect);
  //     } else {
  //       APP.dom.removeNode(wrapNode);
  //     }
  //   }
  //   function adjustLines (rect) {
  //     var height = rect.height * 0.1, width = Math.min(height, rect.width / 3);
  //     comboLines[0].setPoints([
  //       rect.left, rect.top + height,
  //       rect.left, rect.top,
  //       rect.left + width, rect.top
  //     ]);
  //     comboLines[1].setPoints([
  //       rect.left + rect.width - width, rect.top,
  //       rect.left + rect.width, rect.top,
  //       rect.left + rect.width, rect.top + height
  //     ]);
  //     comboLines[2].setPoints([
  //       rect.left + rect.width, rect.top + rect.height - height,
  //       rect.left + rect.width, rect.top + rect.height,
  //       rect.left + rect.width - width, rect.top + rect.height
  //     ]);
  //     comboLines[3].setPoints([
  //       rect.left + width, rect.top + rect.height,
  //       rect.left, rect.top + rect.height,
  //       rect.left, rect.top + rect.height - height
  //     ]);
  //   }
  //   function createLineWrap (canvas, parentNode, color, strokeWidth, lineCap, opacity) {
  //     var node, spec = { fill: 'none', stroke: color, 'stroke-width': strokeWidth };
  //     if (lineCap !== undefined) spec['stroke-linecap'] = lineCap;
  //     if (opacity !== undefined) spec.opacity = opacity;
  //     node = APP.dom.createSvgNode('g', spec, parentNode);
  //     adjustStrokeWidth();
  //     canvas.on('dimensionsChange', adjustStrokeWidth);
  //     return node;
  //     function adjustStrokeWidth () {
  //       node.setAttribute('stroke-width', strokeWidth / canvas.getTextureScale());
  //     }
  //   }
  //   function createMultiLine () {
  //     var lineNodes = Array.prototype.slice.call(arguments).map(function(wrapNode){
  //       return APP.dom.createSvgNode('polyline', null, wrapNode);
  //     });
  //     return {
  //       setPoints: setPoints
  //     };
  //     function setPoints (points) {
  //       lineNodes.forEach(function(lineNode){
  //         APP.dom.setAttributes(lineNode, { 'points': points.join(' ') });
  //       });
  //     }
  //   }
  // }
  function pointsToPolygonString (points) {
    return points.map(function(point){
      return point.x + ',' + point.y;
    }).join(' ');
  }
})(this.APP || (this.APP = {}), this.document);
