(function(APP, document){ 'use strict';
  APP.measurement = {
    createMeasurementView: createMeasurementView
  };
  function createMeasurementView (router, layout) {
    var wrapNode = document.querySelector('.object-size'),
      contentLoader = APP.loading.createContentLoader(wrapNode.querySelector('.contentLoader')),
      viewportController = createViewportController(wrapNode, contentLoader),
      inputController = createInputController(wrapNode, layout),
      buttonController = createButtonController(wrapNode, router);
    router.on(router.events.routeChange, onRouteChange);
    inputController.on('accept', accept);
    buttonController.on('accept', accept);
    function onRouteChange (event) {
      if (event.data !== 'object-size') return;
      APP.dom.toggleClass(wrapNode, 'with-image', !!layout.imageUrl);
      APP.dom.toggleClass(wrapNode, 'without-image', !layout.imageUrl);
      viewportController.applyLayout(layout);
      inputController.applyLayout(layout);
    }
    function accept () {
      var viewportValid = viewportController.getValid(),
        inputValid = inputController.getValid();
      if (inputValid && (!layout.imageUrl || viewportValid)) storeAndProceed();
      else highlightErrors(viewportValid, inputValid);
    }
    function storeAndProceed () {
      var measurement = {};
      measurement.width = inputController.getWidth();
      measurement.height = inputController.getHeight();
      if (layout.imageUrl) {
        measurement.points = viewportController.getPoints();
      }
      layout.measurement = measurement;
      router.next();
    }
    function highlightErrors (viewportValid, inputValid) {
      var alertShown = false;
      if (layout.imageUrl && !viewportValid) {
        viewportController.highlight();
        APP.dialogs.showAlert('measurementRect');
        alertShown = true;
      }
      if (!inputValid) {
        inputController.highlightInvalid();
        if (!alertShown) {
          APP.dialogs.showAlert(layout.imageUrl ? 'measurementRectDimensions' :
            'measurementDimensions');
        }
      }
    }
  }
  function createButtonController (wrapNode, router) {
    var buttonController = APP.utils.makeEventDispatcher({}, 'accept'),
      prevButton = wrapNode.querySelector('.btn-prev'),
      nextButton = wrapNode.querySelector('.btn-next');
    if (prevButton) prevButton.addEventListener('click', onPrevClick, false);
    if (nextButton) nextButton.addEventListener('click', onNextClick, false);
    return buttonController;
    function onPrevClick (event) {
      event.preventDefault();
      router.prev();
    }
    function onNextClick (event) {
      event.preventDefault();
      buttonController.trigger('accept');
    }
  }
  function createInputController (wrapNode) {
    var scaleFactor = 10,
      widthController = createInputElementController(
        wrapNode.querySelector('input[name="width"]'), scaleFactor, 50), // ToDo: insert actual production values
      heightController = createInputElementController(
        wrapNode.querySelector('input[name="height"]'), scaleFactor, 50),
      inputController = APP.utils.makeEventDispatcher({
        applyLayout: applyLayout,
        getWidth: widthController.getNumber,
        getHeight: heightController.getNumber,
        getValid: getValid,
        highlightInvalid: highlightInvalid,
      }, 'accept'),
      formNode = wrapNode.querySelector('form');
    if (formNode) formNode.addEventListener('submit', onSubmit, false);
    return inputController;
    function applyLayout (layout) {
      var measurement = layout.measurement;
      widthController.setNumber(measurement && measurement.width || 40 * scaleFactor);
      heightController.setNumber(measurement && measurement.height || 70 * scaleFactor);
    }
    function getValid () {
      return widthController.getValid() && heightController.getValid();
    }
    function highlightInvalid () {
      if (!widthController.getValid()) widthController.highlight();
      if (!heightController.getValid()) heightController.highlight();
    }
    function onSubmit (event) {
      event.preventDefault();
      inputController.trigger('accept');
    }
  }
  function createInputElementController (node, scaleFactor, minValue) {
    var highlightEnabled;
    initNode();
    return {
      getValid: getValid,
      getNumber: getNumber,
      setNumber: setNumber,
      highlight: highlight,
    };
    function initNode () {
      if (node.type === 'number') node.min = minValue / scaleFactor;
    }
    function getValid () {
      return getNumber() >= minValue;
    }
    function getNumber () {
      return scaleFactor * getNumericValue();
    }
    function setNumber (value) {
      node.value = value / scaleFactor || '';
    }
    function getNumericValue () {
      var value = node.value;
      return (node.type === 'number' ? Number(value) :
        parseFloat(value.trim().replace(',','.'))) || 0;
    }
    function highlight () {
      if (highlightEnabled) return;
      highlightEnabled = true;
      APP.dom.addClass(node, 'highlight');
      setTimeout(function(){
        APP.dom.removeClass(node, 'highlight');
        highlightEnabled = false;
      }, 900);
    }
  }
  function createViewportController (wrapNode, contentLoader) {
    var viewportNode = wrapNode.querySelector('.viewport'),
      hudNode = wrapNode.querySelector('.hud'),
      viewport = APP.viewport.createViewport(viewportNode),
      layout, imageUrl, img, points, dragStage, pointsAreDefault;
    return {
      applyLayout: applyLayout,
      getPoints: getPoints,
      getValid: getValid,
      highlight: highlight,
      // refresh: viewport.refresh,
    };
    function applyLayout (newLayout) {
      clear();
      layout = newLayout;
      if (!layout.imageUrl) return;
      if (imageUrl === layout.imageUrl) {
        if (img) {
          viewport.refresh();
          swapContent(layout, img);
        }
      } else {
        imageUrl = layout.imageUrl;
        contentLoader.show();
        APP.dom.loadImage(layout.dataUrl || imageUrl, function(imgNode){
          contentLoader.hide();
          if (imageUrl === layout.imageUrl) {
            swapContent(layout, imgNode);
          }
        }, function(){
          contentLoader.hide();
        });
      }
    }
    function getPoints () {
      return points.map(clonePoint);
    }
    function getValid () {
      return !pointsAreDefault;
    }
    function swapContent (layout, imgNode) {
      var imgWidth = imgNode.naturalWidth, imgHeight = imgNode.naturalHeight;
      if (imgNode !== img) {
        img = imgNode;
        viewport.setContent(img, imgWidth, imgHeight);
      }
      pointsAreDefault = !layout.measurement || !layout.measurement.points;
      points = pointsAreDefault ?
        createDefaultPoints(imgWidth, imgHeight) :
        layout.measurement.points.map(clonePoint);
      dragStage = createDragStage(hudNode, viewport, points);
      dragStage.once('move', onMove);
    }
    function onMove () {
      pointsAreDefault = false;
    }
    function clonePoint (point) {
      return { x: point.x, y: point.y };
    }
    function highlight () {
      if (dragStage) dragStage.highlight();
    }
    function clear () {
      if (dragStage) {
        dragStage.off('move', onMove);
        dragStage.destroy();
      }
      dragStage = null;
    }
  }
  function createDragStage (hudNode, viewport, points) {
    var dragStage = APP.utils.makeEventDispatcher({
        highlight: highlight,
        destroy: destroy,
      }, 'move'),
      linesDisplay = createLinesDisplay(hudNode, viewport),
      handles = points.map(function(point){
        var handle = createDragHandle(hudNode, viewport, point);
        handle.on(handle.events.move, onHandleMove);
        return handle;
      });
    toggleListeners(true);
    updateView();
    return dragStage;
    function highlight () {
      handles.forEach(function(handle){
        handle.highlight();
      });
    }
    function toggleListeners (active) {
      var fnName = active ? 'on' : 'off';
      viewport[fnName](viewport.events.viewBoxChange, updateView);
      viewport[fnName](viewport.events.contentChange, updateView);
    }
    function updateView () {
      handles.forEach(function(handle){
        handle.updateView();
      });
      updateLines();
    }
    function onHandleMove () {
      updateLines();
      dragStage.trigger(dragStage.events.move);
    }
    function updateLines () {
      linesDisplay.tracePoints(points);
    }
    function destroy () {
      toggleListeners(false);
      if (handles) handles.forEach(function(handle){ handle.destroy(); });
      handles = null;
      if (linesDisplay) linesDisplay.destroy();
      linesDisplay = null;
    }
  }
  function createDragHandle (parentNode, viewport, point) {
    var radius = 13,
      node = createNode(radius, parentNode),
      dragObserver = APP.dom.observeDrag(node, onDragStart),
      highlightEnabled,
      handle = APP.utils.makeEventDispatcher({
        updateView: updateView,
        highlight: highlight,
        destroy: destroy,
      }, 'move');
    updateView();
    return handle;
    function createNode (radius, parentNode) {
      var edge = 2 * radius + 'px',
        node = document.createElement('div');
      node.className = 'dragHandle';
      node.style.width = edge;
      node.style.height = edge;
      parentNode.appendChild(node);
      return node;
    }
    function onDragStart () {
      var initPoint = viewport.contentToViewport(point);
      return function onDrag (dragPoint, deltaX, deltaY) {
        var rect = viewport.getContentDimensions(true),
          contentPoint = viewport.viewportToContent({
            x: initPoint.x + deltaX,
            y: initPoint.y + deltaY
          });
        point.x = Math.max(0, Math.min(rect.width, contentPoint.x));
        point.y = Math.max(0, Math.min(rect.height, contentPoint.y));
        updateView();
        handle.trigger(handle.events.move, point);
      };
    }
    function updateView () {
      var viewPoint = viewport.contentToViewport(point);
      node.style.left = viewPoint.x - radius + 'px';
      node.style.top  = viewPoint.y - radius + 'px';
    }
    function highlight () {
      if (highlightEnabled) return;
      highlightEnabled = true;
      APP.dom.addClass(node, 'highlight');
      setTimeout(function(){
        APP.dom.removeClass(node, 'highlight');
        highlightEnabled = false;
      }, 900);
    }
    function destroy () {
      dragObserver.disable();
      APP.dom.removeNode(node);
    }
  }
  function createLinesDisplay (parentNode, viewport) {
    var svgNode = APP.dom.createSvgNode('svg'),
      outerPolygon = APP.dom.createSvgNode('polygon', { fill: 'none', stroke:'black', 'stroke-width':5 }, svgNode),
      innerPolygon = APP.dom.createSvgNode('polygon', { fill: 'none', stroke:'white', 'stroke-width':2.25 }, svgNode);
    svgNode.style.position = 'absolute';
    parentNode.appendChild(svgNode);
    return {
      tracePoints: tracePoints,
      destroy: destroy,
    };
    function tracePoints (contentPoints) {
      var viewportPoints = contentPoints.map(viewport.contentToViewport);
      updatePolygons(viewportPoints);
      updateSvgNode(viewportPoints);
    }
    function updatePolygons (points) {
      var svgPoints = pointsToSvgString(points);
      outerPolygon.setAttribute('points', svgPoints);
      innerPolygon.setAttribute('points', svgPoints);
    }
    function updateSvgNode (points) {
      var viewportRect = viewport.getDimensions(),
        rect = APP.geom.getBoundingRect(points),
        safety = 4,
        left = Math.max(0, Math.min(viewportRect.width, rect.left - safety)),
        right = Math.max(0, Math.min(viewportRect.width, rect.right + safety)),
        top = Math.max(0, Math.min(viewportRect.height, rect.top - safety)),
        bottom = Math.max(0, Math.min(viewportRect.height, rect.bottom + safety));
      APP.dom.panScanSvgNode(svgNode, left, top, right - left, bottom - top);
    }
    function destroy () {
      APP.dom.removeNode(svgNode);
    }
  }
  function createDefaultPoints (imgWidth, imgHeight) {
    var fillRatio = 0.7,
      heightRatio = 3/2,
      width = Math.min(imgWidth * fillRatio, imgHeight * fillRatio / heightRatio),
      height = Math.min(imgHeight * fillRatio, width * heightRatio),
      left = (imgWidth - width) / 2,
      top = (imgHeight - height) / 2;
    return APP.geom.rectAttributesToPoints(left, top, width, height);
  }
  function pointsToSvgString (points) {
    return points.map(function(point){
      return point.x + ',' + point.y;
    }).join(' ');
  }
})(this.APP || (this.APP = {}), this.document);
