(function(APP, document){ 'use strict';
  APP.fontSelection = {
    createFontSelectionView: createFontSelectionView,
  };
  function createFontSelectionView (router, layout, fonts) {
    var wrapNode = document.querySelector('.font-selection'),
      prevButton = wrapNode.querySelector('.btn-prev'),
      nextButton = wrapNode.querySelector('.btn-next');
    if (prevButton) prevButton.addEventListener('click', onPrevClick, false);
    if (nextButton) nextButton.addEventListener('click', onNextClick, false);
    function onPrevClick (event) {
      event.preventDefault();
      router.prev();
    }
    function onNextClick (event) {
      event.preventDefault();
      router.next();
    }
  }
})(this.APP || (this.APP = {}), this.document);
