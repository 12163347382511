(function (APP) {
  "use strict";
  APP.textField = {
    createTextField: createTextField,
  };
  function createTextField(canvas, coordMappingFn, spec) {
    var textModel = APP.textModel.createTextModel(spec),
      svgRenderer = APP.textRendering.createSvgTextRenderer(textModel),
      interactiveRenderer = APP.textRendering.createInteractiveTextRenderer(
        textModel,
        onPress,
        onDblclick
      ),
      keyboardListener = APP.textRendering.createTextKeyboardListener(
        textModel,
        edit,
        askAndRemove,
        ensureValidPosition
      ),
      wrapNode = (function (wrapNode) {
        wrapNode.appendChild(svgRenderer.getNode());
        wrapNode.appendChild(interactiveRenderer.getNode());
        return wrapNode;
      })(APP.dom.createSvgNode("g", { class: "textField" })),
      field = Object.create(textModel, {
        node: {
          get: function () {
            return wrapNode;
          },
        },
        visualNode: {
          get: svgRenderer.getNode,
        },
        text: {
          get: function () {
            return textModel.text;
          },
          set: function (value) {
            if (value) textModel.text = value;
            else canvas.removeTextField(field);
          },
        },
        toggleHighlight: {
          value: function (active) {
            interactiveRenderer.toggleHighlight(active);
            keyboardListener.toggleListening(active);
            if (!active) ensureValidPosition();
          },
        },
        getRectViewportPoints: {
          value: function (offsetPercent) {
            return canvasRectToViewportPoints(
              textModel.getHitRect(offsetPercent)
            );
          },
        },
        askAndRemove: {
          value: askAndRemove,
        },
        destroy: {
          value: function () {
            interactiveRenderer.destroy();
            svgRenderer.destroy();
            textModel.destroy();
            canvas.off("dimensionsChange", ensureValidPosition);
          },
        },
      });
    textModel.on("redraw alignmentChange", ensureValidPosition);
    canvas.on("dimensionsChange", ensureValidPosition);
    ensureValidPosition();
    return field;
    function canvasRectToViewportPoints(rect) {
      return APP.geom.rectToPoints(rect).map(canvas.canvasToViewport);
    }
    function onPress(event, charModel) {
      canvas.changeTextField(field);
      if (canvas.getKerningMode() && charModel) {
        textModel.currentChar = charModel;
      }
      APP.transformer.startDrag(
        field,
        event,
        coordMappingFn,
        getValidDragRect()
      );
    }
    function onDblclick(event, charModel) {
      edit();
    }
    function edit() {
      canvas.editTextField(field);
    }
    function askAndRemove() {
      var text = textModel.text.trim(),
        oneline = text.replace(/\s+/g, " "),
        dialogName = textModel.font.getIsOrnament()
          ? "removeOrnament"
          : "removeText";
      if (!text) removeField(true);
      else
        APP.dialogs
          .showConfirm(dialogName, { text: oneline })
          .then(removeField);
      function removeField(confirmed) {
        if (confirmed) canvas.removeTextField(field);
      }
    }
    function getValidDragRect() {
      var safeRect = getSafeCanvasRect(),
        textRect = textModel.rect;
      return APP.geom.createRect(
        safeRect.left - textRect.width,
        safeRect.top - textRect.height + textModel.ascent,
        safeRect.right,
        safeRect.bottom + textModel.ascent
      );
    }
    function ensureValidPosition() {
      if (!textModel.lines.length) return;
      var safeRect = getSafeCanvasRect();
      if (!intersectsSafeRect(textModel, safeRect)) {
        return (
          alignSingleAxis(textModel, safeRect) ||
          alignBothAxes(textModel, safeRect)
        );
      }
    }
    function getSafeCanvasRect() {
      var dim = canvas.getDimensions(),
        fontMargin =
          textModel.fontSize * (textModel.font.getIsOrnament() ? 0.5 : 1),
        safetyX = Math.round(Math.min(fontMargin, dim.width / 2)),
        safetyY = Math.round(Math.min(fontMargin, dim.height / 2));
      return APP.geom.createRect(
        safetyX,
        safetyY,
        dim.width - safetyX,
        dim.height - safetyY
      );
    }
  }
  function alignSingleAxis(textModel, safeRect) {
    var offsetX = getMinOffset("left", "right", "top", "bottom"),
      offsetY = getMinOffset("top", "bottom", "left", "right");
    if (
      isFinite(offsetX) &&
      (!isFinite(offsetY) || Math.abs(offsetX) > Math.abs(offsetY))
    ) {
      textModel.x += offsetX;
      return true;
    } else if (
      isFinite(offsetY) &&
      (!isFinite(offsetX) || Math.abs(offsetY >= Math.abs(offsetX)))
    ) {
      textModel.y += offsetY;
      return true;
    }
    return false;
    function getMinOffset(start, end, crossStart, crossEnd) {
      return textModel.lines
        .filter(function (line) {
          var lineRect = line.rect;
          return (
            lineRect[crossStart] <= safeRect[crossEnd] &&
            lineRect[crossEnd] >= safeRect[crossStart]
          );
        })
        .reduce(function (minOffset, line) {
          var lineRect = line.rect,
            lineOffset =
              lineRect[start] > safeRect[end]
                ? safeRect[end] - lineRect[start]
                : lineRect[end] < safeRect[start]
                ? safeRect[start] - lineRect[end]
                : 0;
          return Math.abs(lineOffset) < Math.abs(minOffset)
            ? lineOffset
            : minOffset;
        }, Infinity);
    }
  }
  function alignBothAxes(textModel, safeRect) {
    var vector = findShortestVector(
      collectLineVectors(textModel.lines, safeRect)
    );
    textModel.reposition(textModel.x + vector.x, textModel.y + vector.y);
    function collectLineVectors(lines, safeRect) {
      return lines.reduce(function (vectors, line) {
        return vectors.concat(collectVertexVectors(line.rect, safeRect));
      }, []);
    }
    function collectVertexVectors(startRect, endRect) {
      var left = endRect.left - startRect.right,
        right = endRect.right - startRect.left,
        top = endRect.top - startRect.bottom,
        bottom = endRect.bottom - startRect.top;
      return [
        { x: left, y: top },
        { x: right, y: top },
        { x: right, y: bottom },
        { x: left, y: bottom },
      ];
    }
    function findShortestVector(vectors) {
      return vectors.reduce(function (data, vector) {
        var length = vectorLength(vector);
        return !data.vector || data.length > length
          ? { vector: vector, length: length }
          : data;
      }, {}).vector;
    }
    function vectorLength(vector) {
      return Math.sqrt(vector.x * vector.x + vector.y * vector.y);
    }
  }
  function intersectsSafeRect(textModel, safeRect) {
    return (
      APP.geom.rectEncompassesRect(safeRect, textModel.rect) ||
      textModel.lines.some(function (line) {
        return APP.geom.rectsCollide(line.rect, safeRect);
      })
    );
  }
})(this.APP || (this.APP = {}));
