(function(APP){ 'use strict';
  var stack = [], listening = false;
  APP.keyboard = {
    listen: listen,
    keyCodes: {
      esc: 27,
      left: 37,
      up: 38,
      right: 39,
      down: 40,
      backspace: 8,
      del: 46,
      enter: 13,
      plus: 187,
      numPlus: 107,
      minus: 189,
      numMinus: 109,
    }
  };
  function listen (keyCodeFnMap) {
    stack.push(keyCodeFnMap);
    toggleListening(true);
    return unlisten;
    function unlisten () {
      var index = stack.indexOf(keyCodeFnMap);
      if (index >= 0) {
        stack.splice(index, 1);
        if (!stack.length) toggleListening(false);
      }
    }
  }
  function toggleListening (active) {
    if (listening === active) return;
    listening = active;
    window[active ? 'addEventListener' : 'removeEventListener']('keydown', onKeyDown, false);
  }
  function onKeyDown (event) {
    var keyCodeFnMap = stack[stack.length-1],
      fn = keyCodeFnMap[event.keyCode];
    if (!eventTargetIsInput(event) && typeof fn === 'function') {
      event.preventDefault();
      fn(event);
    }
  }
  function eventTargetIsInput (event) {
    var nodeName = event.target && event.target.nodeName.toLowerCase();
    return !!nodeName && (nodeName === 'input' || nodeName === 'textarea');
  }
})(this.APP || (this.APP = {}));
