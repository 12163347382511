(function(APP, document){ 'use strict';
  APP.export = {
    createExportView: createExportView,
    enableContactForm: enableContactForm,
  };
  function createExportView (router, layout) {
    var wrapNode = document.querySelector('.output'),
      offerForm = enableOfferForm(wrapNode, layout),
      orderForm = enableOrderForm(wrapNode, layout);
    enableLayoutLinkForm(wrapNode, layout);
    APP.pdfForm.enablePdfForm(wrapNode, layout);
    return {
      resetForms: resetForms,
    };
    function resetForms () {
      offerForm.reset();
      orderForm.reset();
    }
  }
  function enableLayoutLinkForm (wrapNode, layout) {
    var formNode = wrapNode.querySelector('.sendLinkForm form');
    formNode.addEventListener('submit', onSubmit, false);
    function onSubmit (event) {
      event.preventDefault();
      processEmail(formNode.elements);
    }
    function processEmail (elements) {
      var email = elements.email.value;
      var internalSubject = elements.internalSubject.value;
      toggleFormDisabled(formNode, true);
      sendLayoutLink({email: email, internalSubject: internalSubject}).then(function(){
        toggleFormDisabled(formNode, false);
        clearForm(formNode);
        displaySuccess(email);
      }, function(error){
        console.error(error);
        toggleFormDisabled(formNode, false);
        displayError(email, function(){
          processEmail(email);
        });
      });
    }
    function sendLayoutLink (formdata) {
      return layout.ensurePersistence().then(function(layoutId){
        return APP.api.sendLayoutLinkWithPromise(layoutId, formdata);
      });
    }
    function displaySuccess (email) {
      APP.dialogs.showAlert('layoutLinkMailSuccess', { email:email });
    }
    function displayError (email, retryFn) {
      APP.dialogs.showConfirm('layoutLinkMailError', { email:email }).then(function(confirmed){
        if (confirmed) retryFn();
      });
    }
  }
  function enableContactForm (lightBox) {
    var formNode = document.querySelector('.contact form');
    enableForm(
      formNode,
      function (data) {
        return APP.api.postContactMessageWithPromise(data);
      },
      'contactFormSuccess',
      'contactFormError'
    );
    lightBox.on('hide', function(event){
      if (event.data === 'contact') {
        toggleFormDisabled(formNode, false);
        clearForm(formNode);
      }
    });
  }
  function enableOfferForm (wrapNode, layout) {
    return enableForm(
      wrapNode.querySelector('.offerForm form'),
      function (data) {
        return persistLayoutAndSendData(layout, APP.api.sendOfferRequestWithPromise, data);
      },
      'offerFormSuccess',
      'offerFormError'
    );
  }
  function enableOrderForm (wrapNode, layout) {
    return enableForm(
      wrapNode.querySelector('.orderForm form'),
      function (data) {
        return persistLayoutAndSendData(layout, APP.api.sendOrderWithPromise, data);
      },
      'orderFormSuccess',
      'orderFormError'
    );
  }
  function persistLayoutAndSendData (layout, netFn, data) {
    return layout.ensurePersistence().then(function(layoutId){
      return netFn(layoutId, data);
    });
  }
  function enableForm (formNode, storeFn, successMsgId, errorMsgId) {
    formNode.addEventListener('submit', onSubmit, false);
    return {
      reset: reset,
    };
    function reset () {
      toggleFormDisabled(formNode, false);
      clearForm(formNode);
    }
    function onSubmit (event) {
      event.preventDefault();
      processForm(collectData());
    }
    function collectData () {
      return toArray(formNode.elements).filter(function(node){
        return node.name && node.value;
      }).reduce(function(data, node){
        data[node.name] = node.value;
        return data;
      }, {});
    }
    function processForm (data) {
      toggleFormDisabled(formNode, true);
      storeFn(data).then(function(){
        displaySuccess();
      }, function(error){
        console.error(error);
        toggleFormDisabled(formNode, false);
        displayError(function(){
          processForm(data);
        });
      });
    }
    function displaySuccess () {
      APP.dialogs.showAlert(successMsgId);
    }
    function displayError (retryFn) {
      APP.dialogs.showConfirm(errorMsgId).then(function(confirmed){
        if (confirmed) retryFn();
      });
    }
  }
  function clearForm (formNode) {
    iterateFormElements(formNode, function(element){
      element.value = '';
    });
  }
  function toggleFormDisabled (formNode, disabled) {
    iterateFormElements(formNode, function(element){
      element.disabled = disabled;
    });
  }
  function iterateFormElements (formNode, callbackFn) {
    toArray(formNode.elements).forEach(callbackFn);
  }
  function toArray (arrayLikeObject) {
    return Array.prototype.slice.call(arrayLikeObject);
  }
})(this.APP || (this.APP = {}), this.document);
