(function(APP){ 'use strict';
  APP.math = {
    getModalValue: getModalValue,
    getMedianValue: getMedianValue,
    getMaxValue: getMaxValue,
    pointsToMillimeters: pointsToMillimeters,
    getTableId: getTableId,
  };
  function getModalValue (numbers) {
    var frequencyMap = numbers.reduce(function(frequencyMap, value){
        frequencyMap[value] = (frequencyMap[value] || 0) + 1;
        return frequencyMap;
      }, {}),
      maxFrequency = getMaxValue(numbers.map(function(value){
          return frequencyMap[value];
        }));
    return maxFrequency > 1 ?
      Number(keyForValue(frequencyMap, maxFrequency)) :
      getMedianValue(numbers);
  }
  function getMedianValue (numbers) {
    return numbers.slice().sort(function(a, b){
      return a - b;
    })[Math.floor(numbers.length) / 2];
  }
  function getMaxValue (numbers) {
    return numbers.reduce(function(max, value){
      return Math.max(max, value);
    }, -Infinity);
  }
  function keyForValue (obj, value) {
    return Object.keys(obj).find(function(key){
      return obj[key] === value;
    });
  }
  function pointsToMillimeters (pt) {
    return Math.round(pt * 25.4 / 72);
  }
  function getTableId (row, col, separator) {
    return [getAlphabeticNumber(row), col + 1].join(separator || '');
  }
  function getAlphabeticNumber (num) {
    var startCode = 'A'.charCodeAt(0),
      base = 'Z'.charCodeAt(0) + 1 - startCode,
      digits = toBase(num, base).map(function(value, i, digits){
        // Warning: This quick hack only works for numbers up to 26^2 - 1
        // We'd need base 26 for the rightmost digit, and base 27 for all others
        return String.fromCharCode(startCode + value - (i < digits.length - 1 ? 1 : 0));
      });
    return digits.join('');
  }
  function toBase (num, base) {
    var digits = [], exponent = 0, value;
    while (value = Math.floor(num / Math.pow(base, exponent++))) {
      digits.push(value % base);
    }
    return digits.length ? digits.reverse() : [0];
  }
})(this.APP || (this.APP = {}));
