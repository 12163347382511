(function(APP, document, location){ 'use strict';
  callOnReady(init);
  function init () {
    if (!browserSupported()) return displayLegacyWarning();
    APP.fonts.loadFonts(function(fonts){
      var router = APP.routing.createRouter(),
        layout = APP.storage.createLayout(router),
        welcomeView = APP.welcome.createWelcomeView(router, layout),
        imageLoadView = APP.imageLoad.createImageLoadView(router, layout),
        fontSelectionView = APP.fontSelection.createFontSelectionView(router, layout, fonts),
        measurementView = APP.measurement.createMeasurementView(router, layout),
        editingView = APP.editing.createEditingView(router, layout, fonts, APP.materials.get()),
        exportView = APP.export.createExportView(router, layout),
        lightBox = APP.page.createLightBox();
      APP.export.enableContactForm(lightBox);
      APP.i18n.augmentLanguageLinks(router, layout, editingView);
      APP.page.init(exportView);
      router.setViewOrder([
        'landingpage',
        'image-load',
        // 'font-selection',
        'object-size',
        'object-config',
      ]);
      // APP.welcome.setDummyData(layout, true);
      router.goTo('landingpage');
    });
  }
  function browserSupported () {
    var s = APP.detection.skills,
      m = APP.detection.meta;
    return s.events && s.selector && s.es5 && s.inlineSvg && s.transform3d && s.historyApi && m.browser !== 'operaMini';
  }
  function displayLegacyWarning () {
    // 'You seriously need to update your browser. We recommend browsehappy.com, shall we take you there?'
    if (confirm('Es ist wirklich an der Zeit, Ihren Browser zu aktualisieren. Wir empfehlen browsehappy.com – möchten Sie dort hin navigieren?')) {
      location.href = 'https://browsehappy.com/';
    }
  }
  function callOnReady (callbackFn) {
    if ('readyState' in document) {
      if (document.readyState !== 'loading') setTimeout(callNow, 0);
      else document.addEventListener('readystatechange', onReadyStateChange, false);
    } else {
      window.addEventListener('load', onLoad, false);
    }
    function onReadyStateChange () {
      document.removeEventListener('readystatechange', onReadyStateChange, false);
      callNow();
    }
    function onLoad () {
      window.removeEventListener('load', onLoad, false);
      callNow();
    }
    function callNow () {
      if (typeof callbackFn === 'function') callbackFn();
    }
  }
})(this.APP || (this.APP = {}), this.document, this.location);
