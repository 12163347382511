(function (APP, document, Snap) { 'use strict';
  var fonts = {
    all: [],
    type: [],
    ornaments: [],
    defaultFont: null,
  };
  APP.fonts = {
    loadFonts: loadFonts,
    getFontById: getFontById,
    getFonts: getFonts,
  };
  function loadFonts (callbackFn) {
    var specs = APP.config.fonts,
      length = specs.length;
    specs.forEach(function (spec, i) {
      loadFont(spec, function (font) {
        if (font) {
          fonts.all[i] = font;
        }
        if (!--length) {
          distributeLoadedFonts();
          callbackFn(fonts);
        }
      });
    });
  }

  function distributeLoadedFonts () {
    fonts.all.forEach(function(font){
      fonts[font.getIsOrnament() ? 'ornaments' : 'type'].push(font);
    });
    fonts.defaultFont = fonts.type[0] || fonts.all[0];
  }

  function loadFont (spec, callbackFn) {
    Snap.load(spec.path, function (fragment) {
      var svgFragment = fragment.node;
      callbackFn(svgFragment.querySelector('glyph') ? createFont(svgFragment, spec) : null);
    });
  }
  
  function getFontById (id) {
    if (!fonts.defaultFont) throw 'wait for loadFonts to complete before calling getFontById';
    return fonts.all.filter(function(font){
      return font.getId() === id;
    })[0];
  }
  
  function getFonts () {
    if (!fonts.defaultFont) throw 'wait for loadFonts to complete before calling getFonts';
    return fonts;
  }

  function createFont(svgFragment, spec) {
    var glyphCache = {},
      repertoire = null,
      unitsPerEm = (function () {
        var fontFace = svgFragment.querySelector('font-face'),
          unitsPerEm = Number(fontFace && fontFace.getAttribute('units-per-em'));
        if (!unitsPerEm && window.console && console.warn) console.warn('No units-per-em found in font ' + spec.name);
        return unitsPerEm || 1000;
      })(),
      ascent = (function () {
        var fontFace = svgFragment.querySelector('font-face');
        return Number(fontFace && fontFace.getAttribute('ascent')) || spec.ascent || 0.7 * unitsPerEm;
      })(),
      capHeight = (function () {
        var fontFace = svgFragment.querySelector('font-face');
        return Number(fontFace && fontFace.getAttribute('cap-height')) || spec.capHeight || 0.65 * unitsPerEm;
      })(),
      horizAdvX = (function () {
        var font = svgFragment.querySelector('font'),
          horizAdvX = Number(font && font.getAttribute('horiz-adv-x'));
        // if (!horizAdvX && window.console && console.warn) console.warn('No horiz-adv-x found in font ' + spec.name);
        return horizAdvX || unitsPerEm / 2;
      })(),
      missingGlyphSpec = (function () {
        var width = 0.5 * unitsPerEm, height = 1 * unitsPerEm,
          visualWidth = width * 0.8, visualHeight = height * 0.7,
          visualOffsetY = (height - visualHeight) / 2;
        return {
          d: drawRect((width - visualWidth) / 2, visualOffsetY - ascent, visualWidth, visualHeight),
          horizAdvX: width,
          getKerning: function(){ return 0; }
        };
        function drawRect(left, top, width, height) {
          return ['M' + left + ',' + top, 'h' + width, 'v' + height, 'H' + left, 'Z'].join(' ');
        }
      })();
    return {
      getId:            getId,
      getName:          getName,
      getGrooved:       getGrooved,
      getUnitsPerEm:    getUnitsPerEm,
      getAscent:        getAscent,
      getCapHeight:     getCapHeight,
      getGlyphSpec:     getGlyphSpec,
      getValueRange:    getValueRange,
      getIsOrnament:    getIsOrnament,
      getRepertoire:    getRepertoire,
      availableMaterials:     availableMaterials(),
    };
    
    function getId() {
      return spec.id;
    }
    
    function getName() {
      return spec.name;
    }

    function availableMaterials() {
      return APP.materials.createMaterials(spec.materials);
    }
    
    function getGrooved() {
      return !!spec.grooved;
    }

    function getUnitsPerEm() {
      return unitsPerEm;
    }
    
    function getAscent() {
      return ascent;
    }
    
    function getCapHeight() {
      return capHeight;
    }

    function getGlyphSpec(unicode) {
      return glyphCache[unicode] || (glyphCache[unicode] = createGlyphSpec(unicode));
    }
    
    function getValueRange (fieldName, intendedString) {
      var range = spec[fieldName] || {}, min = range.min, max = range.max;
      if (fieldName === 'fontSize' && supportsSmallNumerals(intendedString)) {
        min = overrideMinFontSize(min);
      }
      return { min:min, max:max };
    }

    function getIsOrnament () {
      return !!spec.isOrnament;
    }

    function getRepertoire () {
      return repertoire || (repertoire = collectRepertoire());
    }

    function collectRepertoire () {
      var nodes = svgFragment.querySelectorAll('glyph');
      return Array.prototype.slice.call(nodes).filter(function(node){
        var d = node.getAttribute('d');
        return d && /\S+/.test(d);
      }).map(function(node){
        return node.getAttribute('unicode');
      }).filter(function(unicode){
        return unicode;
      });
    }
    
    function overrideMinFontSize (min) {
      var minFontSize = 18;
      return min < minFontSize ? min : minFontSize;
    }
    
    function supportsSmallNumerals (intendedString) {
      return !!spec.smallNumeralsAllowed && /^[-–—+*.,\d\s]*$/g.test(intendedString);
    }

    function createGlyphSpec(unicode) {
      var queryAttribute = unicode === '"' ? '\\'+unicode : unicode,
        node = svgFragment.querySelector('glyph[unicode="' + queryAttribute + '"]'),
        nodeHorizAdvX = (function (horizAdvXAttribute) {
          return horizAdvXAttribute ? Number(horizAdvXAttribute) : horizAdvX;
        })(node && node.getAttribute('horiz-adv-x')),
        kerningMap = (function () {
          var hkerns = svgFragment.querySelectorAll('hkern[u2="' + queryAttribute + '"]');
          return Array.prototype.slice.call(hkerns || []).reduce(function (map, hkern) {
            var u1 = hkern.getAttribute('u1'),
              k = Number(hkern.getAttribute('k'));
            if (u1 && isFinite(k)) map[u1] = k;
            else if (window.console && console.warn) console.warn('Invalid hkern for u2=' + unicode);
            return map;
          }, {});
        })();
      return !node ? missingGlyphSpec : {
        d: (function(d){ return d && mirrorPathY(d) || ''; })(node.getAttribute('d')),
        horizAdvX: nodeHorizAdvX,
        getKerning: getKerning,
      };
      function getKerning (predecessorUnicode) {
        return predecessorUnicode && kerningMap[predecessorUnicode] || 0;
      }
    }

    function mirrorPathY(d) {
      var path = Snap.path.toAbsolute(d);
      path.forEach(processCmd);
      return path.toString();
      function processCmd(cmd) {
        switch (cmd[0]) {
          case 'A':
            cmd[3] *= -1;
            cmd[5] = Number(!cmd[5]);
            cmd[7] *= -1;
            break;
          case 'H':
            break;
          case 'V':
            invertCoordinates(cmd);
            break;
          default:
            invertCoordinates(cmd, 2);
            break;
        }
      }

      function invertCoordinates(cmd, interval) {
        var i, len = cmd.length;
        if (!interval) interval = 1;
        i = interval;
        while (i < len) {
          cmd[i] = cmd[i] * -1;
          i += interval;
        }
      }
    }
  }
})(this.APP || (this.APP = {}), this.document, this.Snap);
