(function(APP, window){ 'use strict';
  APP.viewport = {
    createViewport: createViewport,
  };
  function createViewport (viewportNode, zoomPanelNode) {
    var viewBox = createViewBox(viewportNode),
      content = createContent(viewBox),
      scrollbars = createScrollBars(viewBox, content),
      viewport = APP.utils.makeEventDispatcher({
        setContent: setContent,
        getDimensions: viewBox.getDimensions,
        getContentDimensions: content.getDimensions,
        viewportToContent: content.viewportToContent,
        contentToViewport: content.contentToViewport,
        refresh: viewBox.refresh,
      }, 'viewBoxChange contentChange');
    viewBox.on('dimensionsChange', onViewBoxChange);
    content.on('dimensionsChange', onContentChange);
    enableWheelZoom(viewportNode, viewBox, content);
    if (zoomPanelNode) enableZoomPanel(zoomPanelNode, content);
    preventSelection(viewportNode);
    return viewport;
    function setContent (node, nativeWidth, nativeHeight) {
      content.setNode(node, nativeWidth, nativeHeight);
    }
    function onViewBoxChange (event) {
      viewport.trigger(viewport.events.viewBoxChange, event.data);
    }
    function onContentChange (event) {
      viewport.trigger(viewport.events.contentChange, event.data);
    }
    function enableWheelZoom (viewportNode, viewBox, content) {
      viewportNode.addEventListener('wheel', onWheel, false);
      function onWheel (event) {
        if (!event.deltaY) return;
        event.preventDefault();
        var fnName = event.deltaY < 0 ? 'zoomIn' : 'zoomOut';
        content[fnName](viewBox.getPointerCoordinates(event));
      }
    }
    function enableZoomPanel (zoomPanelNode, content) {
      zoomPanelNode.querySelector('.zoom-in').addEventListener('click', onZoomInClick, false);
      zoomPanelNode.querySelector('.zoom-out').addEventListener('click', onZoomOutClick, false);
      function onZoomInClick (e) {
        e.preventDefault();
        content.zoomIn();
      }
      function onZoomOutClick (e) {
        e.preventDefault();
        content.zoomOut();
      }
    }
    function preventSelection (viewportNode) {
      viewportNode.addEventListener('mousedown', onMouseDown, false);
      function onMouseDown (event) {
        event.preventDefault();
      }
    }
  }
  function createViewBox (viewportNode) {
    var viewBox = APP.utils.makeEventDispatcher({
        getViewportNode: getViewportNode,
        getPointerCoordinates: getPointerCoordinates,
        getDimensions: getDimensions,
        getViewportCenter: getViewportCenter,
        refresh: onResize,
      }, 'dimensionsChange'),
      dimensions;
    window.addEventListener('resize', onResize, false);
    return viewBox;
    function getViewportNode () {
      return viewportNode;
    }
    function getPointerCoordinates (mouseOrTouchEvent) {
      var obj = mouseOrTouchEvent.touches && mouseOrTouchEvent.touches[0] || mouseOrTouchEvent,
        rect = viewportNode.getBoundingClientRect();
      return {
        x: obj.clientX - rect.left,
        y: obj.clientY - rect.top
      };
    }
    function getDimensions (overrideCache) {
      if (!dimensions || overrideCache) {
        var rect = viewportNode.getBoundingClientRect();
        dimensions = { width: rect.width, height: rect.height };
      }
      return dimensions;
    }
    function getViewportCenter () {
      var rect = getDimensions();
      return {
        x: rect.width / 2,
        y: rect.height / 2
      };
    }
    function onResize () {
      var pre = dimensions,
        post = getDimensions(true);
      if (!pre || pre.width !== post.width || pre.height !== post.height) {
        viewBox.trigger('dimensionsChange', post);
      }
    }
  }
  function createContent (viewBox) {
    var content = APP.utils.makeEventDispatcher({
        setNode: setNode,
        getDimensions: getDimensions,
        setOffset: setOffset,
        zoomIn: zoomIn,
        zoomOut: zoomOut,
        viewportToContent: viewportToContent,
        contentToViewport: contentToViewport,
      }, 'dimensionsChange'),
      zoom = 1, zoomFactor = Math.pow(2, 1/4),
      offsetX = 0, offsetY = 0,
      contentNode, baseWith, baseHeight,
      transformProperty = APP.detection.meta.transformProperty;
    viewBox.on('dimensionsChange', onViewBoxChange);
    return content;
    function setNode (node, nativeWidth, nativeHeight) {
      if (contentNode) APP.dom.removeNode(contentNode);
      contentNode = node;
      baseWith = nativeWidth;
      baseHeight = nativeHeight;
      contentNode.style[transformProperty+'Origin'] = '0 0 0';
      zoomToFit(true);
      APP.dom.prependChild(viewBox.getViewportNode(), contentNode);
    }
    function onViewBoxChange () {
      setZoom(zoom);
    }
    function getDimensions (useNative) {
      return {
        left: offsetX,
        top: offsetY,
        width: (useNative ? 1 : zoom) * baseWith,
        height: (useNative ? 1 : zoom) * baseHeight,
      };
    }
    function setOffset (left, top) {
      var ranges = getValidOffsetRanges(),
        newOffsetX = clampValue(ranges.left.min, ranges.left.max, getValidNumber(left, offsetX)),
        newOffsetY = clampValue(ranges.top.min, ranges.top.max, getValidNumber(top, offsetY));
      if (newOffsetX !== offsetX || newOffsetY !== offsetY) {
        offsetX = newOffsetX;
        offsetY = newOffsetY;
        applyChanges();
      }
    }
    function getValidOffsetRanges () {
      var viewportRect = viewBox.getDimensions(),
        contentRect = content.getDimensions(),
        scrollRanges = getScrollRanges(viewportRect, contentRect);
      return {
        left: getOffsetRange('width'),
        top: getOffsetRange('height')
      };
      function getOffsetRange (lengthProperty) {
        var difference = viewportRect[lengthProperty] - contentRect[lengthProperty];
        return difference < 0 ?
          { min: difference, max: 0 } :
          { min: difference / 2, max: difference / 2 };
      }
    }
    function zoomIn (viewportPoint) {
      changeZoom(zoom * zoomFactor, viewportPoint);
    }
    function zoomOut (viewportPoint) {
      changeZoom(zoom / zoomFactor, viewportPoint);
    }
    function changeZoom (newZoom, viewportPoint) {
      if (viewportPoint) setZoom(newZoom, viewportToContent(viewportPoint), viewportPoint);
      else setZoom(newZoom);
    }
    function zoomToFit (overrideCache) {
      var rect = viewBox.getDimensions(overrideCache),
        scale = Math.min(rect.width/baseWith, rect.height/baseHeight);
      setZoom(scale, { x: baseWith/2, y: baseHeight/2 });
    }
    function setZoom (newZoom, point, viewportPoint) {
      var viewportRect = viewBox.getDimensions();
      point = point || viewportToContent(viewBox.getViewportCenter());
      newZoom = getValidZoom(newZoom);
      zoom = newZoom < 1 ? 1/snapToInt(1/newZoom) : snapToInt(newZoom);
      if (viewportRect.width > baseWith * zoom) point.x = baseWith / 2;
      if (viewportRect.height > baseHeight * zoom) point.y = baseHeight / 2;
      panToPoint(point, viewportPoint || viewBox.getViewportCenter());
    }
    function getValidZoom (newZoom) {
      var viewportRect = viewBox.getDimensions(),
        minZoom = Math.min(viewportRect.width / baseWith, viewportRect.height / baseHeight),
        maxZoom = Math.max(minZoom, 16);
      return clampValue(minZoom, maxZoom, newZoom);
    }
    function panToPoint (contentPoint, viewportPoint) {
      var point = contentToViewport(contentPoint);
      setOffset(offsetX + viewportPoint.x - point.x,
        offsetY + viewportPoint.y - point.y);
    }
    function viewportToContent (point) {
      return {
        x: (point.x - offsetX) / zoom,
        y: (point.y - offsetY) / zoom
      };
    }
    function contentToViewport (point) {
      return {
        x: point.x * zoom + offsetX,
        y: point.y * zoom + offsetY
      };
    }
    function applyChanges () {
      if (contentNode) {
        contentNode.style[transformProperty] = 'matrix('+[zoom,0,0,zoom,offsetX,offsetY].join(',')+')';
      }
      content.trigger(content.events.dimensionsChange, getDimensions());
    }
  }
  function createScrollBars (viewBox, content) {
    var thickness = 16, xBar = createBar(), yBar = createBar(true),
      viewportNode = viewBox.getViewportNode();
    viewportNode.appendChild(xBar.getNode());
    viewportNode.appendChild(yBar.getNode());
    viewBox.on('dimensionsChange', update);
    content.on('dimensionsChange', update);
    update();
    function update () {
      var viewportRect = viewBox.getDimensions(),
        contentRect = content.getDimensions(),
        scrollRanges = getScrollRanges(viewportRect, contentRect);
      xBar.update(viewportRect, contentRect, scrollRanges);
      yBar.update(viewportRect, contentRect, scrollRanges);
    }
    function createBar (vertical) {
      var offsetProperty = vertical ? 'top' : 'left',
        lengthProperty = vertical ? 'height' : 'width',
        oppositeProperty = !vertical ? 'height' : 'width',
        node = initNode(),
        trackLength = 0, length = 0, offset = 0;
      enableDrag(node);
      return {
        getNode: getNode,
        update: update
      };
      function getNode () {
        return node;
      }
      function initNode () {
        var node = document.createElement('div');
        node.className = 'scrollBar '+(vertical ? 'vertical' : 'horizontal');
        return node;
      }
      function update (viewportRect, contentRect, scrollRanges) {
        if (scrollRanges[lengthProperty]) align(viewportRect, contentRect, scrollRanges);
        else node.style.display = 'none';
      }
      function align (viewportRect, contentRect, scrollRanges) {
        var oppositeBarVisible = scrollRanges[oppositeProperty];
        trackLength = viewportRect[lengthProperty] - (oppositeBarVisible ? thickness : 0);
        length = Math.max(thickness, viewportRect[lengthProperty] / contentRect[lengthProperty] * trackLength);
        offset = contentRect[offsetProperty] /
          (viewportRect[lengthProperty] - contentRect[lengthProperty]) *
          (trackLength - length);
        node.style[lengthProperty] = length + 'px';
        node.style[offsetProperty] = offset + 'px';
        node.style.removeProperty('display');
      }
      function enableDrag (node) {
        var dragClass = 'dragging';
        APP.dom.observeDrag(node, function onStartDrag () {
          var initOffset = content.getDimensions()[offsetProperty];
          APP.dom.addClass(node, dragClass);
          return function onDrag (dragPoint, deltaX, deltaY) {
            var scrollRanges = getScrollRanges(viewBox.getDimensions(), content.getDimensions()),
              scale = scrollRanges[lengthProperty] / (trackLength - length),
              offset = initOffset - (vertical ? deltaY : deltaX) * scale;
            content.setOffset(vertical ? undefined : offset, vertical ? offset : undefined);
          };
        }, function onEndDrag () {
          APP.dom.removeClass(node, dragClass);
        });
      }
    }
  }
  function getScrollRanges (viewportRect, contentRect) {
    return {
      width: Math.round(Math.max(0, contentRect.width - viewportRect.width)),
      height: Math.round(Math.max(0, contentRect.height - viewportRect.height))
    };
  }
  function getValidNumber (num, fallback) {
    return typeof num === 'number' && isFinite(num) ? num : fallback;
  }
  function clampValue (min, max, value) {
    return Math.max(min, Math.min(max, value || 0));
  }
  function snapToInt (num) {
    var roundedValue = Math.round(num);
    return roundedValue === APP.geom.round(num, 6) ? roundedValue : num;
  }
})(this.APP || (this.APP = {}), this);
