(function (APP, window, document) {
  "use strict";
  APP.editing = {
    createEditingView: createEditingView,
  };
  function createEditingView(router, layout, fonts, materials) {
    var wrapNode = document.querySelector(".object-config"),
      prevButton = wrapNode.querySelector(".btn-prev"),
      exportButton = wrapNode.querySelector(".btn.export"),
      viewport = APP.viewport.createViewport(
        wrapNode.querySelector(".viewport"),
        wrapNode.querySelector(".zoom-panel")
      ),
      editBox = createEditBox(wrapNode.querySelector(".editBox")),
      ornamentBrowser = createOrnamentBrowser(
        wrapNode.querySelector(".ornamentBrowser"),
        fonts.ornaments
      ),
      canvas = APP.rendering.createCanvas(
        fonts,
        materials,
        viewport,
        editBox,
        ornamentBrowser,
        router
      ),
      tutor = APP.tutorial.createTutor(canvas),
      controls = APP.controls.enableCanvasControls(canvas, fonts, materials),
      contentLoader = APP.loading.createContentLoader(
        wrapNode.querySelector(".contentLoader")
      ),
      stage = createStage(viewport, canvas, contentLoader);
    enableHud(wrapNode.querySelector(".hud"), viewport, canvas);
    router.on(router.events.routeChange, onRouteChange);
    if (prevButton) prevButton.addEventListener("click", onPrevClick, false);
    exportButton.addEventListener("click", onExportClick, false);
    enableChangeImageButtons(wrapNode);
    window.addEventListener("beforeunload", onBeforeUnload, true);
    return {
      controls: controls,
      updateLayout: function () {
        writeToLayout(layout);
      },
    };
    function onRouteChange(event) {
      editBox.close();
      ornamentBrowser.close();
      viewport.refresh();
      if (event.data === "object-config") {
        applyLayout(layout);
      }
    }
    function onPrevClick(event) {
      event.preventDefault();
      // ToDo: consider not replacing the canvas textFields with their storage counterparts
      writeToLayout(layout);
      router.prev();
    }
    function onExportClick(event) {
      event.preventDefault();
      writeToLayout(layout);
    }
    function onBeforeUnload(event) {
      // ToDo: Better also check whether layout has been fully persisted as well
      var msg = APP.i18n.strings.unsavedChanges,
        canvasFields = canvas.getStorageData().textFields,
        layoutFields = layout.textFields,
        bothEmpty = isEmpty(canvasFields) && isEmpty(layoutFields);
      if (bothEmpty || APP.utils.jsonEquals(canvasFields, layoutFields)) return;
      event.returnValue = msg; // FF + IE
      return msg; // Chrome + Safari
      function isEmpty(arr) {
        return !arr || !arr.length;
      }
    }
    function enableChangeImageButtons(wrapNode) {
      // by now there is actually only a single button left
      var buttons = wrapNode.querySelectorAll(".btn-photo"),
        i = 0,
        len = buttons.length;
      while (i < len)
        buttons[i++].addEventListener("click", onChangeImageClick, false);
    }
    function onChangeImageClick(event) {
      event.preventDefault();
      router.goTo("image-load");
    }
    function applyLayout(layout) {
      var textureScale =
        500 / Math.min(layout.measurement.width, layout.measurement.height);
      stage.applyLayout(
        layout,
        textureScale,
        function (contentWidth, contentHeight) {
          canvas.updateGeometry(
            layout.measurement,
            contentWidth,
            contentHeight,
            textureScale
          );
          // ToDo: consider not replacing the canvas textFields with their storage counterparts
          if (layout.textFields) canvas.restoreTextFields(layout.textFields);
        }
      );
    }
    function writeToLayout(layout) {
      var storageData = canvas.getStorageData();
      layout.setTextFieldData(
        storageData.textFields,
        storageData.camLines,
        canvas.getTextFields()
      );
    }
  }
  function enableHud(hudNode, viewport, canvas) {
    var visible = true,
      removeButton = hudNode.querySelector(".remove"),
      editButton = hudNode.querySelector(".edit"),
      currentField = null;
    adjustToTextField(canvas.getCurrentTextField());
    viewport.on("viewBoxChange contentChange", onViewportChange);
    canvas.on("textFieldChange", onTextFieldChange);
    removeButton.addEventListener("click", onRemoveClick, false);
    editButton.addEventListener("click", onEditClick, false);
    function onEditClick(event) {
      event.preventDefault();
      canvas.editTextField(currentField);
    }
    function onRemoveClick(event) {
      event.preventDefault();
      currentField.askAndRemove();
    }
    function onViewportChange() {
      if (currentField) adjustToTextField(currentField);
    }
    function onTextFieldChange(event) {
      adjustToTextField(event.data);
    }
    function adjustToTextField(textField) {
      if (currentField !== textField) {
        if (currentField) currentField.off("rectChange", onFieldRectChange);
        if (textField) textField.on("rectChange", onFieldRectChange);
      }
      currentField = textField;
      if (textField) {
        adjustRect();
        show();
      } else hide();
    }
    function show() {
      if (visible) return;
      visible = true;
      toggleNodes(true);
    }
    function hide() {
      if (!visible) return;
      visible = false;
      toggleNodes(false);
    }
    function toggleNodes(active) {
      removeButton.style.display = editButton.style.display = active
        ? ""
        : "none";
    }
    function onFieldRectChange(event) {
      adjustRect();
    }
    function adjustRect() {
      if (!currentField) return;
      var points = currentField.getRectViewportPoints(10);
      moveButtonNode(removeButton, points[1]);
      moveButtonNode(editButton, points[3]);
    }
    function moveButtonNode(node, point) {
      node.style.left = point.x + "px";
      node.style.top = point.y + "px";
    }
  }
  function createEditBox(editBoxNode) {
    var textArea = editBoxNode.querySelector("textarea"),
      cancelButton = editBoxNode.querySelector(".cancel"),
      acceptButton = editBoxNode.querySelector(".accept"),
      defaultText = APP.i18n.strings.defaultText,
      // defaultText = 'Eine neue\nInschrift',
      // defaultText = 'Zwölf Boxkämpfer\njagen Eva quer\nüber den großen\nSylter Deich. ÄÖÜ\n1234567890-,+*&',
      // defaultText = 'ABCDEFGHIJKLM\nNOPQRSTUVWXYZ\nabcdefghijklm\nnopqrstuvwxyz\n0123456789\nÄÖÜäöüß\n.-*+&,;:',
      currentField;
    cancelButton.addEventListener("click", onCancelClick, false);
    acceptButton.addEventListener("click", onAcceptClick, false);
    return {
      editField: editField,
      close: close,
      defaultText: defaultText,
    };
    function editField(textField) {
      if (currentField) currentField.off("alignmentChange", onAlignmentChange);
      currentField = textField;
      editBoxNode.style.display = "block";
      textArea.value = textField.text === defaultText ? "" : textField.text;
      textArea.style.textAlign = textField.alignment;
      textArea.focus();
      currentField.on("alignmentChange", onAlignmentChange);
    }
    function close() {
      if (currentField) currentField.off("alignmentChange", onAlignmentChange);
      currentField = null;
      textArea.value = "";
      editBoxNode.style.display = "none";
    }
    function accept() {
      currentField.text = textArea.value.trim() || defaultText;
      close();
    }
    function onCancelClick(event) {
      event.preventDefault();
      close();
    }
    function onAcceptClick(event) {
      event.preventDefault();
      accept();
      close();
    }
    function onAlignmentChange(event) {
      textArea.style.textAlign = event.data;
      textArea.focus();
    }
    // function hexDump (str) {
    //   var chunks = [], len = str.length, i = 0;
    //   while (i < len) chunks.push(zeroPad(str.charCodeAt(i++).toString(16)));
    //   console.info(chunks.join(' '));
    // }
    // function zeroPad (str) {
    //   while (str.length < 2) str = '0' + str;
    //   return str;
    // }
  }
  function createOrnamentBrowser(ornamentBrowserNode, ornamentFonts) {
    var closeButton = ornamentBrowserNode.querySelector(".btn-close"),
      ornamentsList = ornamentBrowserNode.querySelector(".ornamentsList"),
      populated = false,
      visible = false,
      currentField = null,
      resolvePromise = null;
    if (closeButton) closeButton.addEventListener("click", onCloseClick, false);
    return {
      open: open,
      close: close,
    };
    function open(textField) {
      if (!populated) {
        populateList(ornamentsList, ornamentFonts);
        populated = true;
      }
      resolveUnchanged();
      currentField = textField;
      show();
      return new Promise(function (resolve) {
        resolvePromise = resolve;
      });
    }
    function close() {
      resolveUnchanged();
      hide();
    }
    function show() {
      if (visible) return;
      visible = true;
      ornamentBrowserNode.style.display = "block";
      document.body.style.overflow = "hidden";
      document.documentElement.style.overflow = "hidden";
    }
    function hide() {
      if (!visible) return;
      visible = false;
      ornamentBrowserNode.style.display = "none";
      document.body.style.removeProperty("overflow");
      document.documentElement.style.removeProperty("overflow");
    }
    function onCloseClick(event) {
      event.preventDefault();
      close();
    }
    function resolveTo(value) {
      if (resolvePromise) {
        resolvePromise(value);
        currentField = null;
        resolvePromise = null;
      }
    }
    function resolveUnchanged() {
      resolveTo(
        currentField
          ? {
              font: currentField.font,
              text: currentField.text,
            }
          : null
      );
    }
    function resolveWithGlyph(font, text) {
      if (currentField) {
        currentField.font = font;
        currentField.text = text;
      }
      resolveTo({ font: font, text: text });
    }
    function populateList(ornamentsList, ornamentFonts) {
      ornamentFonts.forEach(function (font) {
        font.getRepertoire().forEach(function (unicode) {
          var svg = addPreviewSvg(font, unicode);
          svg.addEventListener(
            "click",
            function (event) {
              event.preventDefault();
              resolveWithGlyph(font, unicode);
              hide();
            },
            false
          );
        });
      });
      function addPreviewSvg(font, unicode) {
        var width = 120,
          height = 120,
          textModel = APP.textModel.createTextModel({
            font: font,
            material: font.availableMaterials[0],
            fontSize: 100,
            text: unicode,
          }),
          rect = textModel.rect,
          scale = Math.max(rect.width / width, rect.height / height),
          fillRatio = 0.7,
          boxWidth = (scale * width) / fillRatio,
          boxHeight = (scale * height) / fillRatio,
          svg = APP.dom.createSvgNode("svg", {
            width: width,
            height: height,
            viewBox: [
              rect.left + (rect.width - boxWidth) / 2,
              rect.top + (rect.height - boxHeight) / 2,
              boxWidth,
              boxHeight,
            ].join(" "),
          }),
          textRenderer = APP.textRendering.createSvgTextRenderer(textModel);
        svg.appendChild(textRenderer.getNode());
        ornamentsList.appendChild(svg);
        return svg;
      }
    }
    function pickOne(arr) {
      return arr[Math.floor(Math.random() * arr.length)];
    }
  }
  function createStage(viewport, canvas, contentLoader) {
    var imageUrl,
      imgNode,
      divNode,
      wrapNode = document.createElement("div"),
      canvasNode = canvas.getNode();
    wrapNode.appendChild(canvasNode);
    wrapNode.addEventListener("mousedown", onMouseDownClick, false);
    wrapNode.addEventListener("click", onMouseDownClick, false);
    return {
      applyLayout: applyLayout,
    };
    function applyLayout(layout, textureScale, dimensionsCallback) {
      if (layout.imageUrl) useImage(layout, dimensionsCallback);
      else useDiv(layout, textureScale, dimensionsCallback);
    }
    function useImage(layout, dimensionsCallback) {
      APP.dom.removeNode(divNode);
      if (layout.imageUrl === imageUrl) {
        APP.dom.prependChild(wrapNode, imgNode);
        viewport.refresh();
        dimensionsCallback(imgNode.naturalWidth, imgNode.naturalHeight);
      } else {
        imageUrl = layout.imageUrl;
        contentLoader.show();
        APP.dom.loadImage(
          layout.dataUrl || imageUrl,
          function (img) {
            contentLoader.hide();
            if (imgNode) APP.dom.removeNode(imgNode);
            imgNode = img;
            img.style.position = "absolute";
            APP.dom.prependChild(wrapNode, img);
            viewport.setContent(wrapNode, img.naturalWidth, img.naturalHeight);
            dimensionsCallback(img.naturalWidth, img.naturalHeight);
          },
          function () {
            contentLoader.hide();
          }
        );
      }
    }
    function useDiv(layout, textureScale, dimensionsCallback) {
      var width = layout.measurement.width * textureScale,
        height = layout.measurement.height * textureScale;
      APP.dom.removeNode(imgNode);
      imageUrl = layout.imageUrl;
      APP.dom.prependChild(wrapNode, getDivNode(width, height));
      viewport.setContent(wrapNode, width, height);
      dimensionsCallback(width, height);
    }
    function getDivNode(width, height) {
      if (!divNode) divNode = createDivNode();
      divNode.style.width = width + "px";
      divNode.style.height = height + "px";
      return divNode;
    }
    function createDivNode() {
      var node = document.createElement("div"),
        style = node.style;
      style.position = "absolute";
      style.background = "white";
      style.border = "2px solid #808080";
      return node;
    }
    function onMouseDownClick(event) {
      if (event.button) return;
      canvas.changeTextField(null);
    }
  }
})(this.APP || (this.APP = {}), this, this.document);
