(function (APP, document) {
  "use strict";
  APP.controls = {
    enableCanvasControls: enableCanvasControls,
  };

  function enableCanvasControls(canvas, fonts, materials) {
    var currentTextField,
      query = document.querySelector.bind(document),
      controls = [];
    controls.push(
      createDropDownControl(
        query(".select-dropdown.font"),
        "font",
        createFontDropdownItems(fonts.type, materials[0]),
        null,
        fontChanged
      )
    );
    controls.push(
      createDropDownControl(
        query(".select-dropdown.material"),
        "material",
        createMaterialDropdownItems(materials),
        "availableMaterials"
      )
    );
    controls.push(
      createButtonGroupControl(
        query(".text-align"),
        "alignment",
        isOrnamentField
      )
    );
    controls.push(
      createNumericControl(query(".character-width"), "charWidth", "%")
    );
    controls.push(
      createNumericControl(query(".character-size"), "fontSize", "mm")
    );
    controls.push(
      createNumericControl(
        query(".line-spacing"),
        "leading",
        "mm",
        isOrnamentField
      )
    );
    enableKerningToggle(canvas);
    enableWidthsToggle(canvas);
    adjustToTextField(canvas.getCurrentTextField());
    canvas.on(canvas.events.textFieldChange, onTextFieldChange);
    query(".object-config .add-text").addEventListener(
      "click",
      function (event) {
        event.preventDefault();
        canvas.addDefaultTextField(true);
      },
      false
    );
    query(".object-config .add-ornament").addEventListener(
      "click",
      function (event) {
        event.preventDefault();
        canvas.addOrnament();
      },
      false
    );

    function isOrnamentField(object) {
      return !!(object && object.font && object.font.getIsOrnament());
    }

    function fontChanged(font, textField) {
      var field = textField;
      if (textField) {
        var materialAvailable = font.availableMaterials.some(function (m) {
          return m.getId() === textField.material.getId();
        });
        textField.material = materialAvailable
          ? textField.material
          : font.availableMaterials[0];
      } else {
        field = { font: font, material: font.availableMaterials[0] };
      }
      controls[1].setValue(font.availableMaterials[0]);
      adjustToTextField(field);
    }
    function onTextFieldChange(e) {
      adjustToTextField(e.data);
    }
    function adjustToTextField(textField) {
      currentTextField = textField;
      if (textField) {
        // console.log(textField.font.getName(), textField.fontSize, textField.leading);
      } else {
        // console.log('no textField selected');
      }
      controls.forEach(function (control) {
        control.setObject(currentTextField);
      });
    }
  }

  function createFontDropdownItems(typeFonts, material) {
    return typeFonts.map(function (font) {
      var name = font.getName();
      return {
        node: createFontPreviewNode(font, material),
        name: name,
        value: font,
      };
    });
  }

  function createMaterialDropdownItems(materials) {
    return materials.map(function (material) {
      var name = material.getName();
      return {
        node: createSpan(name),
        name: name,
        value: material,
      };
    });
    function createSpan(textContent) {
      var node = document.createElement("span");
      node.innerHTML = textContent;
      return node;
    }
  }

  function createFontPreviewNode(font, material) {
    var scale = 10, // workaround to stay within valid fontSize regions defined in config
      width = 184,
      height = 32,
      textModel = APP.textModel.createTextModel({
        font: font,
        material: material,
        fontSize: 0.52 * scale * height,
        x: scale * 20,
        y: scale * Math.round(height * 0.74),
        text: font.getName(),
      }),
      textRenderer = APP.textRendering.createSvgTextRenderer(textModel),
      textNode = textRenderer.getNode(),
      svg = APP.dom.createSvgNode("svg", {
        width: width,
        height: height,
        viewBox: "0 0 " + scale * width + " " + scale * height,
      });
    svg.style.width = "100%";
    svg.style.height = height + "px";
    textNode.setAttribute("class", "fontPreview");
    svg.appendChild(textNode);
    return svg;
  }

  function enableKerningToggle(canvas) {
    var node = document.querySelector(".configwrap.space .toggle");
    node.addEventListener("click", onToggleClick, false);
    canvas.on(canvas.events.kerningModeChange, onKerningModeChange);
    adjust(canvas.getKerningMode());
    function onToggleClick(event) {
      event.preventDefault();
      canvas.toggleKerningMode();
    }
    function onKerningModeChange(event) {
      adjust(event.data);
    }
    function adjust(kerningMode) {
      APP.dom.toggleClass(node, "active", kerningMode);
    }
  }

  function enableWidthsToggle(canvas) {
    var node = document.querySelector(".configwrap.widths .toggle");
    node.addEventListener("click", onToggleClick, false);
    canvas.on(canvas.events.widthsModeChange, onWidthsModeChange);
    adjust(canvas.getWidthsMode());
    function onToggleClick(event) {
      event.preventDefault();
      canvas.toggleWidthsMode();
    }
    function onWidthsModeChange(event) {
      adjust(event.data);
    }
    function adjust(widthsMode) {
      APP.dom.toggleClass(node, "active", widthsMode);
    }
  }

  function createDropDownControl(
    wrapNode,
    propertyName,
    items,
    availablePropertyName,
    customMethod
  ) {
    var currentObject,
      availableProperties = [],
      active = false,
      disabled = false,
      recentRelatedItem = null,
      selected = wrapNode.querySelector(".selected"),
      ul = wrapNode.querySelector("ul");
    enableToggle(wrapNode);
    populateList(ul);
    return {
      setObject: setObject,
      setValue: setValue,
    };
    function enableToggle(wrapNode) {
      wrapNode.addEventListener("click", onWrapClick, false);
      function onWrapClick() {
        if (!active && !disabled)
          document.addEventListener("click", onDocumentClick, false);
      }
    }
    function onDocumentClick() {
      toggleActive();
    }
    function toggleActive() {
      APP.dom.toggleClass(selected, "active", (active = !active));
      if (!active)
        document.removeEventListener("click", onDocumentClick, false);
    }
    function populateList(ul) {
      ul.innerHTML = "";
      items.forEach(function (item) {
        addListItem(ul, item);
      });
    }
    function addListItem(ul, item) {
      var li = APP.dom.createHtmlNode("li", null, ul);
      li.appendChild(item.node);
      li.addEventListener("click", onClick, false);
      function onClick() {
        applyViewValue(item.value);
        if (customMethod)
          setTimeout(function () {
            customMethod(item.value, currentObject);
          }, 100);
      }
    }
    function propertyIsAvailable(item) {
      return availableProperties.length
        ? availableProperties.some(function (a) {
            return a.getId() === item.getId();
          })
        : true;
    }
    function setValue(value) {
      APP.controls[propertyName] = value;
      var relatedItem = items.reduce(function (relatedItem, item) {
        var isRelatedItem = item.value.getId() === value.getId();
        APP.dom.toggleClass(item.node.parentNode, "active", isRelatedItem);
        APP.dom.toggleClass(
          item.node.parentNode,
          "disabled",
          !propertyIsAvailable(item.value)
        );
        return relatedItem || (isRelatedItem ? item : null);
      }, null);
      toggleDisabled(!relatedItem);
      if (relatedItem) {
        recentRelatedItem = relatedItem;
        selected.innerHTML = relatedItem.name;
      }
    }
    function applyViewValue(value) {
      if (currentObject) currentObject[propertyName] = value;
      setValue(value);
    }
    function setObject(newObject) {
      currentObject = newObject;
      if (
        availablePropertyName &&
        newObject &&
        newObject.font &&
        newObject.font[availablePropertyName]
      ) {
        availableProperties = newObject.font[availablePropertyName];
      }
      // APP.utils.reduce(currentObject, propertyName)
      if (currentObject) setValue(currentObject[propertyName]);
      else if (recentRelatedItem) setValue(recentRelatedItem.value);
      if (active) toggleActive();
    }
    function toggleDisabled(newDisabled) {
      if (newDisabled === disabled) return;
      disabled = newDisabled;
      APP.dom.toggleClass(wrapNode, "disabled", newDisabled);
    }
  }

  function createButtonGroupControl(wrapNode, propertyName, disableCallback) {
    var currentObject,
      disabled = false,
      options = Array.prototype.slice.call(
        wrapNode.querySelectorAll(".option")
      );

    options.forEach(function (option) {
      option.addEventListener("click", onClick, false);
    });

    return {
      setObject: setObject,
    };

    function setObject(newObject) {
      currentObject = newObject;
      toggleDisabled(!!disableCallback && disableCallback(newObject));
      if (!disabled && currentObject) setValue(currentObject[propertyName]);
    }

    function toggleDisabled(newDisabled) {
      if (newDisabled === disabled) return;
      disabled = newDisabled;
      APP.dom.toggleClass(wrapNode, "disabled", newDisabled);
    }

    function setValue(value) {
      options.forEach(function (option) {
        APP.dom.toggleClass(
          option,
          "active",
          value === option.getAttribute("rel")
        );
      });
    }

    function applyViewValue(value) {
      if (currentObject) currentObject[propertyName] = value;
      setValue(value);
    }

    function onClick(event) {
      if (disabled) return;
      var element = event.target.parentNode.parentNode;
      applyViewValue(element.getAttribute("rel"));
    }
  }

  function createNumericControl(wrapNode, propertyName, unit, disableCallback) {
    var currentObject,
      disabled = false,
      eventName = propertyName + "Change",
      input = wrapNode.querySelector("input");
    addDomListeners(wrapNode, input);
    return {
      setObject: setObject,
    };
    function addDomListeners(wrapNode, input) {
      var upButton = wrapNode.querySelector(".up"),
        downButton = wrapNode.querySelector(".down");
      input.addEventListener("keydown", onKeyDown, false);
      input.addEventListener("blur", onBlur, false);
      upButton.addEventListener("click", onUpClick, false);
      downButton.addEventListener("click", onDownClick, false);
    }
    function setObject(newObject) {
      toggleListener(currentObject, false);
      currentObject = newObject;
      toggleDisabled(!!disableCallback && disableCallback(newObject));
      if (!disabled && currentObject) {
        toggleListener(currentObject, true);
        setValue(currentObject[propertyName]);
      }
    }
    function toggleDisabled(newDisabled) {
      if (newDisabled === disabled) return;
      disabled = newDisabled;
      APP.dom.toggleClass(wrapNode, "disabled", newDisabled);
      input.disabled = newDisabled;
    }
    function setValue(newValue) {
      input.value = (newValue || 0) + " " + unit;
    }
    function applyViewValue(increment) {
      var inputStr = input.value.trim(),
        num = parseFloat(inputStr.replace(",", ".")),
        matches = /[A-Z,a-z]+$/.exec(inputStr),
        inputUnit = matches && matches[0];

      if (isValidNumber(increment)) num += increment;

      if (isValidNumber(num)) {
        num *= convertUnit(inputUnit, unit);
      }
      if (currentObject) {
        currentObject[propertyName] = num;
        num = currentObject[propertyName];
      }
      if (!objectHasEvent(currentObject)) setValue(num);
      return num;
      function isValidNumber(value) {
        return typeof value === "number" && isFinite(value);
      }
    }
    function onKeyDown(e) {
      var fn = {
        38: function up() {
          apply(e.shiftKey ? 10 : 1);
        },
        40: function down() {
          apply(e.shiftKey ? -10 : -1);
        },
        13: function enter() {
          apply();
        },
      }[e.keyCode];
      if (typeof fn === "function") fn();
      function apply(increment) {
        e.preventDefault();
        applyViewValue(increment);
      }
    }
    function onBlur() {
      applyViewValue();
    }
    function onUpClick() {
      if (!disabled) applyViewValue(1);
    }
    function onDownClick() {
      if (!disabled) applyViewValue(-1);
    }
    function onPropertyChange(event) {
      setValue(event.data);
    }
    function objectHasEvent(object) {
      return !!(object && object.events && object.events[eventName]);
    }
    function toggleListener(object, active) {
      if (!objectHasEvent(object)) return;
      object[active ? "on" : "off"](eventName, onPropertyChange);
    }
  }

  function convertUnit(inUnit, outUnit) {
    return (
      (inUnit &&
        outUnit &&
        {
          "m > mm": 1000,
          "dm > mm": 100,
          "cm > mm": 10,
        }[inUnit + " > " + outUnit]) ||
      1
    );
  }
})(this.APP || (this.APP = {}), this.document);
