(function(APP){ 'use strict';
  APP.net = {
    parseQueryString: parseQueryString,
    serializeQueryString: serializeQueryString,
    getJson: getJson,
    // postJson: postJson,
    requestJsonWithPromise: requestJsonWithPromise,
  };
  function parseQueryString (str) {
    return (str || '').replace(/^\?/, '').split('&').reduce(function(obj, pair){
      var chunks = pair.split('=');
      var key = decodeURIComponent(chunks[0]);
      var value = decodeURIComponent(chunks[1] || '');
      if (key) obj[key] = value;
      return obj;
    }, {});
  }
  function serializeQueryString (obj, includeQuestionMark) {
    var str = Object.keys(typeof obj === 'object' ? obj : {}).reduce(function(arr, key){
      var value = obj[key];
      if (value !== undefined) {
        if (value === null) value = '';
        arr.push(encodeURIComponent(key)+'='+encodeURIComponent(value));
      }
      return arr;
    }, []).join('&');
    return (includeQuestionMark && str ? '?' : '') + str;
  }
  function getJson (url, successFn, errorFn, abortFn) {
    return requestJson('GET', url, undefined, successFn, errorFn, abortFn);
  }
  // function postJson (url, bodyData, successFn, errorFn, abortFn) {
  //   return requestJson('POST', url, bodyData, successFn, errorFn, abortFn);
  // }
  function requestJsonWithPromise (method, url, bodyData) {
    var request,
      promise = new Promise(function(resolve, reject){
        request = requestJson(method, url, bodyData,
          function(responseData){
            resolve(responseData);
          }, function(err){
            reject(err);
          }, function(event){
            reject(event);
          });
      });
    promise.abort = request.abort;
    return promise;
  }
  function requestJson (method, url, bodyData, successFn, errorFn, abortFn) {
    var xhr = new XMLHttpRequest();
    xhr.addEventListener('load', onLoad, false);
    xhr.addEventListener('error', onError, false);
    xhr.addEventListener('abort', onAbort, false);
    xhr.open(method, url, true);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify(bodyData));
    return {
      abort: abort,
    };
    function abort () {
      if (xhr.readyState !== XMLHttpRequest.UNSENT && xhr.readyState !== XMLHttpRequest.DONE) {
        xhr.abort();
      }
    }
    function onLoad () {
      if (xhr.status < 200 || xhr.status >= 300) return callError(xhr.statusText);
      try {
        if (typeof successFn === 'function'){
          successFn(JSON.parse(xhr.responseText));
        }
      } catch (err) {
        callError(err);
      }
    }
    function onError (err) {
      callError(err);
    }
    function onAbort (event) {
      if (typeof abortFn === 'function') abortFn(event);
    }
    function callError (err) {
      if (typeof errorFn === 'function') errorFn(err);
      else APP.dialogs.showAlert('networkError');
    }
  }
  // function createJsonLoader (method, url, bodyData) {
  //   var response, done = false, success = false,
  //     loader = APP.utils.makeEventDispatcher(Object.create(Object.prototype, {
  //         done:     { get: function() { return done;     } },
  //         success:  { get: function() { return success;  } },
  //         response: { get: function() { return response; } },
  //         abort:    { value: abort }
  //       }), 'complete failed aborted'),
  //     request = requestJson(method, url, bodyData, function(data){
  //       response = data;
  //       done = true;
  //       success = true;
  //       loader.trigger('complete', response);
  //     }, function(err){
  //       done = true;
  //       loader.trigger('failed', err);
  //     }, function(event){
  //       done = true;
  //       loader.trigger('aborted', event);
  //     });
  //   function abort () {
  //     if (done) return;
  //     request.abort();
  //     done = true;
  //   }
  //   return loader;
  // }
})(this.APP || (this.APP = {}));
