(function (APP) {
  "use strict";
  var materials;

  APP.materials = {
    get: getMaterials,
    getMaterialById: getMaterialById,
    createMaterials: createMaterials,
    createMaterial: createMaterial,
  };

  function getMaterials() {
    return materials || (materials = createMaterials());
  }

  function getMaterialById(id) {
    return getMaterials().filter(function (material) {
      return material.getId() === id;
    })[0];
  }

  function createMaterials(mat) {
    var specs = mat || APP.config.materials,
      materials = [];
    specs.forEach(function (spec) {
      var material = createMaterial(spec);
      materials.push(material);
    });
    return materials;
  }

  function createMaterial(spec) {
    return {
      getId: getId,
      getName: getName,
      getTopColor: getTopColor,
      getBottomColor: getBottomColor,
      getBevelColor: getBevelColor,
      getShadowColor: getShadowColor,
      getPrintFill: getPrintFill,
      getPrintStroke: getPrintStroke,
    };
    function getId() {
      return spec.id;
    }
    function getName() {
      return APP.i18n.strings["material_" + spec.id] || spec.id;
    }
    function getTopColor() {
      return spec.topColor;
    }
    function getBottomColor() {
      return spec.bottomColor;
    }
    function getBevelColor() {
      return spec.bevelColor;
    }
    function getShadowColor() {
      return spec.shadowColor;
    }
    function getPrintFill() {
      return spec.printFill;
    }
    function getPrintStroke() {
      return spec.printStroke;
    }
  }
})(this.APP || (this.APP = {}));
