(function (APP, document) { 'use strict';
  APP.tutorial = {
    createTutor: createTutor,
  };
  function createTutor (canvas) {
    var tipManager = createTipManager(), currentTextField;
    canvas.on(canvas.events.textFieldChange, onTextFieldChange);
    function onTextFieldChange (e) {
      toggleListener(currentTextField, false);
      currentTextField = e.data;
      toggleListener(currentTextField, true);
    }
    function onPropertyChange () {
      if (canvas.getTextFields(true).length === 1) {
        tipManager.triggerTip('tip-multiple-fields');
      }
    }
    function toggleListener (field, active) {
      var events = 'fontChange materialChange alignmentChange '+
                   'fontSizeChange leadingChange charWidthChange';
      if (!field || active && field.font.getIsOrnament()) return;
      field[active ? 'once' : 'off'](events, onPropertyChange);
    }
  }
  function createTipManager () {
    var tips = {},
      seenTipNames = APP.localSettings.getItem('seenTipNames', 24*60*60*1000) || {};
    return {
      triggerTip: triggerTip
    };
    function triggerTip (tipName) {
      if (tipName in seenTipNames) return;
      var tip = tips[tipName] || (tips[tipName] = createTip(tipName, function(){
        seenTipNames[tipName] = true;
        APP.localSettings.setItem('seenTipNames', seenTipNames);
        delete tips[tipName];
      }));
      tip.show();
      window.tip = tip;
    }
  }
  function createTip (tipName, onSeen) {
    var node = document.querySelector('.tutor-tip.' + tipName),
      fader = createFader(node),
      visible = false,
      hideTimeout = null,
      seenTimeout = null,
      seen = false,
      clickCount = 0;
    return {
      show: show,
      hide: hide,
    };
    function show () {
      if (visible) return;
      visible = true;
      cancelTimeouts();
      node.addEventListener('click', onClick, false);
      hideTimeout = setTimeout(onHideTimeout, 12e3);
      if (!seen) seenTimeout = setTimeout(onSeenTimeout, 6e3);
      fader.fadeIn();
    }
    function hide () {
      if (!visible) return;
      visible = false;
      cancelTimeouts();
      node.removeEventListener('click', onClick, false);
      fader.fadeOut();
    }
    function cancelTimeouts () {
      if (hideTimeout !== null) clearTimeout(hideTimeout);
      hideTimeout = null;
      if (seenTimeout !== null) clearTimeout(seenTimeout);
      seenTimeout = null;
    }
    function onHideTimeout () {
      hideTimeout = null;
      hide();
    }
    function onSeenTimeout () {
      seenTimeout = null;
      markAsSeen();
    }
    function onClick () {
      hide();
      if (++clickCount > 1) markAsSeen();
    }
    function markAsSeen () {
      if (seen) return;
      seen = true;
      if (typeof onSeen === 'function') onSeen();
    }
  }
  function createFader (node) {
    var cancelFn, duration = 250, easing = APP.easing.easeInOutPow;
    if (!node || !node.style) throw new Error('Invalid node given to createFader');
    return {
      fadeIn: fadeIn,
      fadeOut: fadeOut,
      show: show,
      hide: hide,
    };
    function fadeIn (completeFn) {
      fadeTo(1, completeFn);
    }
    function fadeOut (completeFn) {
      fadeTo(0, completeFn);
    }
    function show () {
      setOpacity(1);
    }
    function hide () {
      setOpacity(0);
    }
    function fadeTo (endOpacity, completeFn) {
      var startOpacity = Number(node.style.opacity) || 0;
      cancelAnimation();
      adjustVisibility(startOpacity || endOpacity);
      node.style.opacity = startOpacity;
      cancelFn = APP.animations.animateValue(duration, startOpacity, endOpacity, easing,
        function(value){
          node.style.opacity = value;
        },
        function(){
          adjustVisibility(endOpacity);
          if (typeof completeFn === 'function') completeFn();
        });
    }
    function setOpacity (opacity) {
      node.style.opacity = opacity;
      adjustVisibility(opacity);
    }
    function adjustVisibility (opacity) {
      node.style.display = opacity ? 'block' : 'none';
    }
    function cancelAnimation () {
      if (typeof cancelFn === 'function') cancelFn();
      cancelFn = null;
    }
  }
})(this.APP || (this.APP = {}), this.document);
