(function(APP, navigator, document){ 'use strict';
  APP.i18n = {
    formatNumber: formatNumber,
    formatDate: formatDate,
    augmentLanguageLinks: augmentLanguageLinks,
  };
  function formatNumber (num, options) {
    return (num || 0).toLocaleString(getCurrentLocale(), options);
  }
  function formatDate (date, keepLtrMarks) { // IE and Edge insert text direction marks
    var str = (date || new Date()).toLocaleString(getCurrentLocale());
    return keepLtrMarks ? str : str.replace(/\u200e/g, '');
  }
  function getCurrentLocale () {
    return APP.i18n.lang || getBrowserLocale() || 'de';
  }
  function getBrowserLocale () {
    var nav = navigator;
    return nav.languages && nav.languages[0] || nav.language || nav.userLanguage;
  }
  function augmentLanguageLinks (router, layout, editingView) {
    var links = document.querySelectorAll('[data-lang-link]');
    Array.prototype.slice.call(links).forEach(function(link){
      link.addEventListener('click', onClick, false);
      function onClick (event) {
        event.preventDefault();
        // ToDo: consider storing data of other views as well
        if (router.getCurrentKey() === 'object-config') {
          editingView.updateLayout();
          layout.ensurePersistence().then(navigate);
        } else navigate();
      }
      function navigate () {
        var locationQuery = APP.net.parseQueryString(location.search);
        var linkQuery = APP.net.parseQueryString(link.href.replace(/^.*(\?.*)$/, '$1'));
        var merge = Object.assign({}, locationQuery, linkQuery);
        location.search = APP.net.serializeQueryString(merge, true);
      }
    });
  }
})(this.APP || (this.APP = {}), this.navigator, this.document);
