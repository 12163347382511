(function(APP, document){ 'use strict';
  var dialogQueue;
  APP.dialogs = {
    showAlert: showAlert,
    showConfirm: showConfirm,
  };
  function showAlert (name, data) {
    return getDialogQueue().enqueueDialog(name, data, false);
  }
  function showConfirm (name, data) {
    return getDialogQueue().enqueueDialog(name, data, true);
  }
  function getDialogQueue () {
    return dialogQueue || (dialogQueue = createDialogQueue());
  }
  function createDialogQueue () {
    var display = createDialogDisplay(), promise = Promise.resolve();
    return {
      enqueueDialog: enqueueDialog,
    };
    function enqueueDialog (name, data, asConfirm) {
      return promise = promise.then(function(){
        return display.showDialog(name, data, asConfirm);
      });
    }
  }
  function createDialogDisplay () {
    var wrapNode = document.querySelector('.dialogBackdrop'),
      cancelButton = wrapNode.querySelector('.cancelButton'),
      acceptButton = wrapNode.querySelector('.acceptButton');
    return {
      showDialog: showDialog,
    };
    function showDialog (name, data, asConfirm) {
      var msgNode = wrapNode.querySelector('.msg.'+name);
      if (!msgNode) {
        console.warn('dialog msg with className “'+name+'” not found');
        msgNode = wrapNode.querySelector('.msg.' +
          (asConfirm ? 'genericConfirm' : 'genericError'));
      }
      if (!msgNode) return Promise.resolve();
      cancelButton.style.display = asConfirm ? '' : 'none';
      return new Promise(function(resolve){
        var restoreMsgText = interpolateTextNode(msgNode.firstChild, data),
          stopListening = listenForKeyboard(asConfirm ? cancel : accept, accept);
        toggleActive(true);
        function onCancelClick (event) {
          event.preventDefault();
          cancel();
        }
        function onAcceptClick (event) {
          event.preventDefault();
          accept();
        }
        function cancel () {
          toggleActive(false);
          resolve(false);
        }
        function accept () {
          toggleActive(false);
          resolve(true);
        }
        function toggleActive (active) {
          var fnName = active ? 'addEventListener' : 'removeEventListener';
          acceptButton[fnName]('click', onAcceptClick, false);
          if (asConfirm) cancelButton[fnName]('click', onCancelClick, false);
          wrapNode.style.display = msgNode.style.display = active ? 'block' : '';
          if (active) APP.dom.defocus();
          else {
            restoreMsgText();
            stopListening();
          }
        }
      });
    }
  }
  function listenForKeyboard (escapeCallback, returnCallback) {
    var fnMap = {}, keyCodes = APP.keyboard.keyCodes;
    fnMap[keyCodes.esc] = escapeCallback;
    fnMap[keyCodes.enter] = returnCallback;
    return APP.keyboard.listen(fnMap);
  }
  function interpolateTextNode (textNode, data) {
    var originalString = textNode && textNode.data;
    if (!originalString) return function(){};
    textNode.data = interpolateString(textNode.data, data);
    return restore;
    function restore () {
      textNode.data = originalString;
    }
  }
  function interpolateString (str, data) {
    if (typeof data === 'object') Object.keys(data).forEach(function(key){
      str = str.replace(new RegExp('\\{{2}\\s*'+key+'\\s*\\}{2}', 'g'), data[key]);
    });
    return str.replace(/\{{2}[^\}]*\}{2}/g, '');
  }
})(this.APP || (this.APP = {}), this.document);
