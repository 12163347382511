(function (APP) {
  "use strict";
  Object.defineProperty(APP, "config", { get: getConfig });
  function getConfig() {
    return mergedConfig || (mergedConfig = mergeConfig(APP.env.brand));
  }
  function mergeConfig(brand) {
    var brandConfig = configPool[brand];
    return {
      fonts: [].concat(
        (brandConfig && brandConfig.ornaments) || [],
        (brandConfig && brandConfig.fonts) || []
      ),
      materials: [].concat([], (brandConfig && brandConfig.materials) || []),
    };
  }
  var mergedConfig,
    configPool = {
      binder: {
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
        fonts: getBinderFonts(),
        ornaments: getOrnaments(["bronze", "steel", "aluminum"]),
      },
      destag: {
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
        fonts: getDestagFonts(),
        ornaments: getOrnaments(["bronze", "steel", "aluminum"]),
      },
      wins: {
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
        fonts: getWinsFont(),
        ornaments: getOrnaments(["bronze", "steel", "aluminum"]),
      },
      koenig: {
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
        fonts: getKoenigFonts(),
        ornaments: getOrnaments(["bronze", "steel", "aluminum"]),
      },
    };

  function getWinsFont() {
    var binderFonts = getBinderFonts()
      .filter(function (font) {
        return /3907|3908|3911|3916|3923|3927/.test(font.id);
      })
      .map(function (font) {
        return Object.assign({}, font, {
          name: font.id.replace("39", "Thomas "),
          materials: getMaterialsById(["steel"]),
        });
      });

    var destagFonts = getDestagFonts();
    return binderFonts.concat(destagFonts);
  }

  function getOrnaments(materials) {
    return [
      {
        id: "Ornamente-10",
        name: "Ornamente 10",
        path: "./res/engine-fonts/Ornamente-10.svg",
        isOrnament: true,
        fontSize: {
          min: 10,
          max: 1000,
        },
        materials: getMaterialsById(materials),
      },
      {
        id: "Ornamente-40",
        name: "Ornamente 40",
        path: "./res/engine-fonts/Ornamente-40.svg",
        isOrnament: true,
        fontSize: {
          min: 40,
          max: 1000,
        },
        materials: getMaterialsById(materials),
      },
      {
        id: "Ornamente-50",
        name: "Ornamente 50",
        path: "./res/engine-fonts/Ornamente-50.svg",
        isOrnament: true,
        fontSize: {
          min: 50,
          max: 1000,
        },
        materials: getMaterialsById(materials),
      },
      {
        id: "Ornamente-80",
        name: "Ornamente 80",
        path: "./res/engine-fonts/Ornamente-80.svg",
        isOrnament: true,
        fontSize: {
          min: 80,
          max: 1000,
        },
        materials: getMaterialsById(materials),
      },
    ];
  }

  function getKoenigFonts() {
    return [
      {
        id: "Koenig",
        name: "König",
        path: "./res/engine-fonts/koenig.svg",
        smallNumeralsAllowed: true,
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "Saturn",
        name: "Saturn",
        path: "./res/engine-fonts/koenigSaturn.svg",
        smallNumeralsAllowed: true,
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "Helena",
        name: "Helena",
        path: "./res/engine-fonts/koenigHelena.svg",
        grooved: true,
        smallNumeralsAllowed: true,
        materials: getMaterialsById(["bronze", "aluminum"]),
      },
      {
        id: "Venus",
        name: "Venus",
        path: "./res/engine-fonts/koenigVenus.svg",
        smallNumeralsAllowed: true,
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "Cosima",
        name: "Cosima",
        path: "./res/engine-fonts/koenigCosima.svg",
        smallNumeralsAllowed: true,
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "Genus",
        name: "Genus",
        path: "./res/engine-fonts/koenigGenus.svg",
        smallNumeralsAllowed: true,
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "Selma",
        name: "Selma",
        path: "./res/engine-fonts/koenigSelma.svg",
        smallNumeralsAllowed: true,
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
    ];
  }

  function getKoenigOrnaments(materials) {
    return [
      {
        id: "Ornamente-10",
        name: "Ornamente 10",
        path: "./res/engine-fonts/Ornamente-10.svg",
        isOrnament: true,
        fontSize: {
          min: 10,
          max: 1000,
        },
        materials: getMaterialsById(materials),
      },
    ];
  }

  function getBinderFonts() {
    return [
      {
        id: "3907",
        name: "Binder 3907",
        path: "./res/engine-fonts/binder3907.svg",
        smallNumeralsAllowed: true,
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "3908",
        name: "Binder 3908",
        path: "./res/engine-fonts/binder3908.svg",
        smallNumeralsAllowed: true,
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "3909",
        name: "Binder 3909",
        path: "./res/engine-fonts/binder3909.svg",
        smallNumeralsAllowed: true,
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "3910",
        name: "Binder 3910",
        path: "./res/engine-fonts/binder3910.svg",
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "3911",
        name: "Binder 3911",
        path: "./res/engine-fonts/binder3911.svg",
        smallNumeralsAllowed: true,
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "3912",
        name: "Binder 3912",
        path: "./res/engine-fonts/binder3912.svg",
        grooved: true,
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "3916",
        name: "Binder 3916",
        path: "./res/engine-fonts/binder3916.svg",
        grooved: true,
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "3917",
        name: "Binder 3917",
        path: "./res/engine-fonts/binder3917.svg",
        smallNumeralsAllowed: true,
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "3918",
        name: "Binder 3918",
        path: "./res/engine-fonts/binder3918.svg",
        grooved: true,
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "3922",
        name: "Binder 3922",
        path: "./res/engine-fonts/binder3922.svg",
        fontSize: {
          min: 18,
        },
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "3923",
        name: "Binder 3923",
        path: "./res/engine-fonts/binder3923.svg",
        smallNumeralsAllowed: true,
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "3924",
        name: "Binder 3924",
        path: "./res/engine-fonts/binder3924.svg",
        grooved: true,
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "3925",
        name: "Binder 3925",
        path: "./res/engine-fonts/binder3925.svg",
        smallNumeralsAllowed: true,
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "3926",
        name: "Binder 3926",
        path: "./res/engine-fonts/binder3926.svg",
        smallNumeralsAllowed: true,
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "3927",
        name: "Binder 3927",
        path: "./res/engine-fonts/binder3927.svg",
        smallNumeralsAllowed: true,
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "3929",
        name: "Binder 3929",
        path: "./res/engine-fonts/binder3929.svg",
        fontSize: {
          min: 25,
        },
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "3935",
        name: "Binder 3935",
        path: "./res/engine-fonts/binder3935.svg",
        fontSize: {
          min: 18,
        },
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      // {
      //   id:            'stub-ornaments-fa',
      //   name:          'Stub Ornaments Font Awesome',
      //   path:          './res/engine-fonts/stub-ornaments-fa.svg',
      //   isOrnament:    true,
      // },
      // {
      //   id:            'stub-ornaments-ion',
      //   name:          'Stub Ornaments Ionicons',
      //   path:          './res/engine-fonts/stub-ornaments-ion.svg',
      //   isOrnament:    true,
      // },
      // {
      //   id:            'ambrosia',
      //   name:          'Ambrosia',
      //   path:          './res/engine-fonts/ambrosia.svg',
      // },
      // {
      //   id:            'president',
      //   name:          'President',
      //   path:          './res/engine-fonts/president.svg',
      //   fontSize:  {
      //     min:     30,
      //     max:     200
      //   },
      //   leading:   {
      //     min:     -10,
      //   },
      //   charWidth: {
      //     max:     120
      //   }
      // }
    ];
  }

  function getDestagFonts() {
    return [
      {
        id: "DG01",
        name: "Wunder 01",
        path: "./res/engine-fonts/DG01.svg",
        smallNumeralsAllowed: true,
        fontSize: {
          max: 120,
        },
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "DG02",
        name: "Wunder 02",
        path: "./res/engine-fonts/DG02.svg",
        smallNumeralsAllowed: true,
        fontSize: {
          max: 120,
        },
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "DG03",
        name: "Wunder 03",
        path: "./res/engine-fonts/DG03.svg",
        smallNumeralsAllowed: true,
        fontSize: {
          max: 120,
        },
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "DG04",
        name: "Wunder 04",
        path: "./res/engine-fonts/DG04.svg",
        smallNumeralsAllowed: true,
        fontSize: {
          max: 120,
        },
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "DG05",
        name: "Wunder 05",
        path: "./res/engine-fonts/DG05.svg",
        smallNumeralsAllowed: true,
        fontSize: {
          max: 120,
        },
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "DG06",
        name: "Wunder 06",
        path: "./res/engine-fonts/DG06.svg",
        smallNumeralsAllowed: true,
        fontSize: {
          max: 120,
        },
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "DG07",
        name: "Wunder 07",
        path: "./res/engine-fonts/DG07.svg",
        smallNumeralsAllowed: true,
        fontSize: {
          max: 120,
        },
        grooved: true,
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
      {
        id: "DG08",
        name: "Wunder 08",
        path: "./res/engine-fonts/DG08.svg",
        smallNumeralsAllowed: true,
        fontSize: {
          max: 120,
        },
        materials: getMaterialsById(["bronze", "steel", "aluminum"]),
      },
    ];
  }

  function getMaterialsById(ids) {
    return [
      {
        id: "bronze",
        topColor: "hsl(33, 67%, 80%)",
        bottomColor: "hsl(30, 63%, 62%)",
        bevelColor: "hsl(27, 50%, 15%)",
        shadowColor: "hsl(30, 100%, 7%)",
        printFill: ["0.90", "0.72", "0.52"],
        printStroke: ["0.24", "0.17", "0.08"],
      },
      {
        id: "steel",
        topColor: "hsl(0, 0%, 87%)",
        bottomColor: "hsl(0, 0%, 75%)",
        bevelColor: "hsl(0, 0%, 50%)",
        shadowColor: "hsl(0, 0%, 7%)",
        printFill: ["0.81", "0.81", "0.81"],
        printStroke: ["0.40", "0.40", "0.40"],
      },
      {
        id: "aluminum",
        topColor: "hsl(0, 0%, 93%)",
        bottomColor: "hsl(0, 0%, 73%)",
        bevelColor: "hsl(0, 0%, 5%)",
        shadowColor: "hsl(0, 0%, 0%)",
        printFill: ["0.83", "0.83", "0.83"],
        printStroke: ["0.15", "0.15", "0.15"],
      },
    ].filter(function (material) {
      return ids.indexOf(material.id) >= 0;
    });
  }
})(this.APP || (this.APP = {}));
