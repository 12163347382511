(function(APP, window, document, navigator){ 'use strict';
  APP.detection = collectBrowserInfo();
  flagDom(APP.detection.skills, APP.detection.meta);
  function flagDom (skills, meta) {
    var flags = [],
      node = document.documentElement || document.body && document.body.parentNode || document.body;
    if (meta.browser) flags.push('browser-'+meta.browser);
    if (node && flags.length) {
      node.className = (node.className ? node.className+' ' : '') + flags.join(' ');
    }
    // flags.push(meta.ieVersion ? 'ie'+meta.ieVersion : 'noIE');
    // Object.keys(skills).forEach(function(skill){
    //   flags.push(skills[skill] ? [skill] : camelCase('no', skill));
    // });
    // function camelCase () {
    //   return Array.prototype.slice.call(arguments).map(function(str, i){
    //     return i ? str.substr(0, 1).toUpperCase() + str.substr(1) : str;
    //   }).join('');
    // }
  }
  function collectBrowserInfo () {
    var node = document.createElement('div'),
      style = node.style,
      ieVersion = getIeVersion(),
      transformProperty = getValidStyleProperty('transform'),
      transformMode = getTransformMode(transformProperty);
    return {
      skills: {
        events: hasEvents(),
        selector: hasSelector(),
        es5: hasEs5(),
        // opacity: hasOpacity(style),
        // backgroundSize: hasBackgroundSize(style),
        // svg: hasSvg(),
        inlineSvg: hasInlineSvg(),
        transform3d: transformMode === '3d',
        historyApi: hasHistoryApi(),
      },
      meta: {
        transformProperty: transformProperty,
        transformMode: transformMode,
        browser: getBrowser(),
        platform: getPlatform(),
        ieVersion: ieVersion,
      }
    };
    function hasEvents () {
      return 'addEventListener' in document;
    }
    function hasSelector () {
      return 'querySelector' in document && 'querySelectorAll' in document;
    }
    function hasEs5 () {
      return !!Function.prototype.bind;
    }
    function hasOpacity (s) {
      return 'opacity' in s;
    }
    function hasBackgroundSize (s) {
      return 'backgroundSize' in s;
    }
    function hasSvg () {
      return !!(document.implementation && document.implementation.hasFeature &&
        document.implementation.hasFeature('http://www.w3.org/TR/SVG11/feature#Image', '1.1'));
    }
    function hasInlineSvg () {
      var div;
      if (typeof SVGRect === 'undefined') return false;
      div = document.createElement('div');
      div.innerHTML = '<svg/>';
      return (div.firstChild && div.firstChild.namespaceURI) === 'http://www.w3.org/2000/svg';
    }
    function hasHistoryApi () {
      return !!window.history && 'pushState' in window.history;
    }
    function getIeVersion () {
      // 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.1; Trident/6.0)';
      // 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
      var matches = /(?:MSIE (\d+))|(?:Trident\/.*; rv:(\d+))/i.exec(navigator.userAgent);
      return matches ? parseInt(matches[1] || matches[2], 10) : null;
    }
    function getTransformMode (transformProperty) {
      var node, style;
      if (transformProperty) {
        node = document.createElement('div');
        style = node.style;
        style[transformProperty] = 'translateZ(0)';
        return style[transformProperty] ? '3d' : '2d';
      }
      return null;
    }
    function getBrowser () {
      var ua = navigator.userAgent;
      return getIeVersion() ? 'ie' :
        /\bEdge\b/.test(ua) ? 'edge' :
        /\bChrome\b/.test(ua) ? 'chrome' :
        /\bSafari\b/.test(ua) ? 'safari' :
        /\bFirefox\b/.test(ua) ? 'firefox' :
        /\bOpera Mini\b/i.test(ua) ? 'operaMini' :
        null; 
    }
    function getPlatform () {
      var ua = navigator.userAgent;
      return /\bMac/.test(ua) ? 'mac' :
        /\bWindows\b/.test(ua) ? 'windows' :
        null;
    }
  }
  function getValidStyleProperty (prop, style) {
    var vendors, i, len, camelProp, prefixedProp;
    style = style || document.createElement('div').style;
    if (prop in style) return prop;
    camelProp = prop.charAt(0).toUpperCase() + prop.substr(1);
    vendors = ['O','ms','Ms','Moz','Webkit'];
    for (i=0, len=vendors.length; i<len; i++) {
      prefixedProp = vendors[i] + camelProp;
      if (prefixedProp in style) return prefixedProp;
    }
    return null;
  }
})(this.APP || (this.APP = {}), this, this.document, this.navigator);
