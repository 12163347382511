(function(APP, window, document, location, history){ 'use strict';
  APP.routing = {
    createRouter: createRouter,
  };
  function createRouter () {
    var currentKey, orderedKeys,
      historian = createHistorian(),
      router = APP.utils.makeEventDispatcher({
        setViewOrder: setViewOrder,
        prev: prev,
        next: next,
        goTo: goTo,
        getCurrentKey: getCurrentKey,
        getLayoutId: historian.getLayoutId,
        setLayoutId: historian.setLayoutId,
      }, 'routeChange layoutIdChange');
    historian.on('layoutIdChange', function(event){
      router.trigger(event.type, event.data);
    });
    return router;
    function setViewOrder (keys) {
      orderedKeys = keys;
    }
    function prev () {
      gotoNeighbor(true);
    }
    function next () {
      gotoNeighbor();
    }
    function gotoNeighbor (prev) {
      if (orderedKeys && orderedKeys.length) {
        var index = orderedKeys.indexOf(currentKey);
        if (index >= 0) {
          var newIndex = Math.max(0, Math.min(orderedKeys.length - 1, index + (prev ? -1 : 1)));
          if (newIndex !== index) router.goTo(orderedKeys[newIndex]);
        } else console.warn('current view is missing in ordered list');
      } else console.warn('call setViewOrder before prev or next');
    }
    function goTo (key) {
      if (currentKey) toggleNodeByClassName(currentKey, false);
      currentKey = key;
      toggleNodeByClassName(key, true);
      APP.dom.toggleClass(document.querySelector('.app'), 'container', key !== 'object-config');
      window.scrollTo(0,0);
      router.trigger(router.events.routeChange, key);
    }
    function getCurrentKey () {
      return currentKey;
    }
    function toggleNodeByClassName (key, active) {
      var node = document.querySelector('.single-view.'+key);
      if (!node) return false;
      APP.dom.toggleClass(node, 'current', active);
      return true;
    }
  }
  function createHistorian () {
    var historian = APP.utils.makeEventDispatcher({
        getLayoutId: getLayoutId,
        setLayoutId: setLayoutId,
      }, 'layoutIdChange');
    initState();
    window.addEventListener('popstate', onPopState, false);
    return historian;
    function initState () {
      var layoutId = history.state && history.state.layoutId || parseLayoutId();
      addState({ layoutId: layoutId }, true);
    }
    function parseLayoutId () {
      var matches = /\/layout\/(\w+)/.exec(location.pathname);
      return matches && matches[1] || null;
    }
    function getLayoutId () {
      return history.state.layoutId;
    }
    function setLayoutId (layoutId) {
      if (layoutId === history.state.layoutId) return;
      addState({ layoutId: layoutId });
      historian.trigger('layoutIdChange', layoutId);
    }
    function addState (data, replace) {
      var state = Object.assign(history.state || {}, data),
        layoutId = state.layoutId,
        methodName = replace ? 'replaceState' : 'pushState',
        title = 'Atelier Binder LithoScribe' + (layoutId ? ' '+layoutId : ''),
        url = (layoutId ? '/layout/'+layoutId : '/') + location.search + location.hash;
      history[methodName](state, title, url);
    }
    function onPopState (event) {
      historian.trigger('layoutIdChange', event.state && event.state.layoutId);
    }
  }
})(this.APP || (this.APP = {}), this, this.document, this.location, this.history);
