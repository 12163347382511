(function(APP){ 'use strict';
  APP.animations = {
    animateProperties: animateProperties,
    animateValue: animateValue,
    animateRange: animateRange,
    animateRatio: animateRatio,
    interpolateSpec: interpolateSpec,
    interpolateValue: interpolateValue,
  };
  function animateProperties (duration, startSpec, endSpec, easingFn, stepFn, completeFn) {
    return animateRatio (duration, easingFn, isFunction(stepFn) && function(ratio){
      stepFn(interpolateSpec(startSpec, endSpec, ratio));
    }, isFunction(completeFn) && function(){
      completeFn(endSpec);
    });
  }
  function animateValue (duration, from, to, easingFn, stepFn, completeFn) {
    return animateRange(duration, to - from, easingFn, isFunction(stepFn) && function(range){
      stepFn(from + range);
    }, completeFn);
  }
  function animateRange (duration, range, easingFn, stepFn, completeFn) {
    return animateRatio(duration, easingFn, isFunction(stepFn) && function(ratio){
      stepFn(range * ratio);
    }, completeFn);
  }
  function animateRatio (duration, easingFn, stepFn, completeFn) {
    var startTime, cancelled = false;
    requestAnimationFrame(animate);
    return cancel;
    function cancel () {
      cancelled = true;
    }
    function animate (now) {
      if (cancelled) return;
      if (!startTime) startTime = now;
      var ratio = Math.max(0, Math.min(1, (now - startTime) / duration));
      if (isFunction(easingFn)) ratio = easingFn(ratio);
      if (isFunction(stepFn)) stepFn(ratio);
      if (ratio < 1) requestAnimationFrame(animate);
      else if (isFunction(completeFn)) completeFn(duration);
    }
  }
  function interpolateSpec (startSpec, endSpec, ratio) {
    return Object.keys(startSpec).reduce(function(spec, key){
      spec[key] = interpolateValue(startSpec[key], endSpec[key], ratio);
      return spec;
    }, {});
  }
  function interpolateValue (numA, numB, ratio) {
    return numA + (numB - numA) * ratio;
  }
  function isFunction (value) {
    return typeof value === 'function';
  }
})(window.APP || (window.APP = {}));


(function(APP){ 'use strict';
  APP.easing = {
    easeInOutSine: easeInOutSine,
    easeInOutPow: easeInOutPow,
  };
  function easeInOutPow (ratio, power) {
    if (typeof power !== 'number' || power <= 0) power = 2;
    return ratio < 0.5 ? Math.pow(2 * ratio, power) / 2 :
      1 - Math.pow(2 - 2 * ratio, power) / 2;
  }
  function easeInOutSine (ratio) {
    return (Math.sin(Math.PI * ratio - Math.PI / 2) + 1) / 2;
  }
})(window.APP || (window.APP = {}));
