(function(APP, jsPDF, Snap, location, navigator){ 'use strict';
  APP.pdf = {
    createCroppedPdf: createCroppedPdf,
    createCanvasSizePdf: createCanvasSizePdf,
    createPageSizePdf: createPageSizePdf,
    createTiledPdf: createTiledPdf,
  };
  function createCroppedPdf (textModels, options) {
    return createPdf(textModels, null, null, null, options);
  }
  function createCanvasSizePdf (textModels, canvasDimensions, options) {
    var rect = APP.geom.createRect(0, 0, canvasDimensions.width, canvasDimensions.height);
    return createPdf(textModels, rect, rect, rect, options);
  }
  function createPageSizePdf (textModels, options) {
    if (!options) options = {};
    var pageDimensions = { width: options.width || 210, height: options.height || 297 },
      sourceRect = getTextBoundingRect(textModels),
      targetRect = APP.geom.createRect(
        getMargin('marginLeft'),
        getMargin('marginTop'),
        pageDimensions.width - getMargin('marginRight'),
        pageDimensions.height - getMargin('marginBottom')
      );
    return createPdf(textModels, sourceRect, targetRect, pageDimensions, options);
    function getMargin (name) {
      var margin = options[name];
      return typeof margin === 'number' ? margin : 10;
    }
  }
  function createTiledPdf (textModels, options) {
    var pageLayout = determineOptimalPageLayout(textModels, options),
      doc = initPdf(pageLayout.pageRect, options);
    pageLayout.pages.reduce(function(firstPageDrawn, page){
      if (options.skipEmptyPages && !page.hasContent) return firstPageDrawn;
      if (firstPageDrawn) doc.addPage();
      saveGraphicsState(doc);
      beginPageClip(doc, pageLayout.clipRect);
      drawTextModels(doc, textModels, page.clipRect, pageLayout.clipRect, options);
      restoreGraphicsState(doc);
      if (options.addDimensions) {
        drawTextDimensions(doc, textModels, page.clipRect, pageLayout.clipRect, options.drawMode);
      }
      drawMetaText(doc, pageLayout.clipRect, options, APP.math.getTableId(page.row, page.col));
      if (options.addCropMarks) drawCropMarks(doc, pageLayout.cropRect);
      return true;
    }, false);
    return doc;
  }
  function beginPageClip (doc, clipRect) {
    doc.rect(clipRect.left, clipRect.top, clipRect.width, clipRect.height, null);
    doc.clip_fixed();
  }
  function saveGraphicsState (doc) {
    doc.internal.write('q');
  }
  function restoreGraphicsState (doc) {
    doc.internal.write('Q');
  }
  function determineOptimalPageLayout (textModels, options) {
    var sourceRect = getTextBoundingRect(textModels),
      pageLayout = calculatePageLayout(textModels, sourceRect, options), altLayout;
    if (options.orientation === 'autoRotate') {
      altLayout = calculatePageLayout(textModels, sourceRect, options, true);
      if (options.skipEmptyPages && altLayout.numFilledPages < pageLayout.numFilledPages ||
          !options.skipEmptyPages && altLayout.numPages < pageLayout.numPages) {
        return altLayout;
      }
    }
    return pageLayout;
  }
  function calculatePageLayout (textModels, sourceRect, options, rotateRight) {
    var page = calculatePageRects(options, rotateRight),
      grid = calculateGrid(textModels, sourceRect, page.cropRect, options.overlap);
    return Object.assign({}, page, grid);
  }
  function calculatePageRects (options, rotateRight) {
    var width = rotateRight ? options.height : options.width,
      height = rotateRight ? options.width : options.height,
      marginLeft = rotateRight ? options.marginBottom : options.marginLeft,
      marginRight = rotateRight ? options.marginTop : options.marginRight,
      marginTop = rotateRight ? options.marginLeft : options.marginTop,
      marginBottom = rotateRight ? options.marginRight : options.marginBottom,
      overlap = options.overlap,
      pageRect = APP.geom.createRect(0, 0, width, height),
      clipRect = APP.geom.createRect(marginLeft, marginTop,
        width - marginRight, height - marginBottom),
      cropRect = APP.geom.createRect(marginLeft + overlap, marginTop + overlap,
        width - marginRight - overlap, height - marginBottom - overlap);
    return { pageRect:pageRect, clipRect:clipRect, cropRect:cropRect };
  }
  function calculateGrid (textModels, sourceRect, cropRect, overlap) {
    var cols = Math.ceil(sourceRect.width / cropRect.width),
      rows = Math.ceil(sourceRect.height / cropRect.height),
      numPages = cols * rows,
      pages = collectPages(numPages),
      numFilledPages = getNumFilledPages(pages);
    return { cols:cols, rows:rows, numPages:numPages, pages:pages, numFilledPages:numFilledPages };
    function collectPages (numPages) {
      var pages = [], i = 0;
      while (i < numPages) pages[i] = createPageSpec(i++);
      return pages;
    }
    function createPageSpec (index) {
      var col = index % cols,
        row = Math.floor(index / cols),
        left = sourceRect.left + col * cropRect.width,
        top = sourceRect.top + row * cropRect.height,
        clipRect = APP.geom.createRect(left - overlap, top - overlap,
          left + cropRect.width + overlap, top + cropRect.height + overlap),
        rectsCollide = APP.geom.rectsCollide,
        hasContent = textModels.some(function(textModel){
          var collides = rectsCollide(clipRect, textModel.rect);
          return collides && textModel.lines.some(function(line){
            var collides = rectsCollide(clipRect, line.rect);
            return collides && !!line.chars.length && line.chars.some(function(char){
              // ToDo: Optimize by getting the printing height without leading from char (and line)
              return !!char.glyphPath.length && rectsCollide(clipRect, char.rect);
            });
          });
        });
      return { col:col, row:row, clipRect:clipRect, hasContent:hasContent };
    }
    function getNumFilledPages (pages) {
      return pages.reduce(function(numFilledPages, page){
        return numFilledPages + Number(page.hasContent);
      }, 0);
    }
  }
  function createPdf (textModels, sourceRect, targetRect, pageDimensions, options) {
    if (!options) options = {};
    var allowRotation = !options.orientation || options.orientation === 'autoRotate';
    if (!sourceRect) sourceRect = getTextBoundingRect(textModels);
    if (!targetRect) targetRect = APP.geom.createRect(0, 0, sourceRect.width, sourceRect.height);
    if (!pageDimensions) pageDimensions = { width: targetRect.width, height: targetRect.height };
    if (allowRotation && fitsLargerWhenRotated(sourceRect, targetRect)) {
      targetRect = rotateClockwise(targetRect, pageDimensions);
      pageDimensions = flipOrientation(pageDimensions);
    }
    var doc = initPdf(pageDimensions, options);
    drawTextModels(doc, textModels, sourceRect, targetRect, options);
    if (options.addDimensions) {
      drawTextDimensions(doc, textModels, sourceRect, targetRect, options.drawMode);
    }
    drawMetaText(doc, targetRect, options);
    return doc;
  }
  function drawMetaText (doc, rect, options, pageNum) {
    var fontSize = Math.min(16, Math.round(rect.width / 25)),
      left = rect.left + Math.max(1, APP.math.pointsToMillimeters(fontSize * 0.4)),
      top = rect.top + 1 + Math.max(1, APP.math.pointsToMillimeters(fontSize * 0.8)), 
      bottom = rect.bottom - Math.max(1, APP.math.pointsToMillimeters(fontSize * 0.4));
    saveGraphicsState(doc);
    doc.setTextColor(127);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(fontSize);
    if (options.addDate) doc.text(APP.i18n.formatDate(), left, top);
    if (options.addLayoutLink) doc.text(location.href, left, bottom);
    if (options.addPageNumbering && pageNum) {
      doc.text(pageNum, rect.right - fontSize * pageNum.length * 0.3, bottom);
    }
    restoreGraphicsState(doc);
  }
  function rotateClockwise (targetRect, pageDimensions) {
    var rect = flipOrientation(targetRect);
    rect.left = pageDimensions.height - targetRect.bottom;
    rect.top = targetRect.left;
    rect.right = rect.left + rect.width;
    rect.bottom = rect.top + rect.height;
    return rect;
  }
  function fitsLargerWhenRotated (sourceRect, targetRect) {
    return getContainScale(sourceRect, flipOrientation(targetRect)) >
      getContainScale(sourceRect, targetRect);
  }
  function flipOrientation (dimensions) {
    return { width: dimensions.height, height: dimensions.width };
  }
  function getContainScale (sourceRect, targetRect) {
    return Math.min(targetRect.width / sourceRect.width, targetRect.height / sourceRect.height);
  }
  function initPdf (dimensions, options) {
    return addDisplayMode(createDoc(dimensions), options);
    function createDoc (dimensions) {
      return new jsPDF({
        orientation: dimensions.width > dimensions.height ? 'landscape' : 'portrait',
        unit: 'mm',
        format: [dimensions.width, dimensions.height],
      });
    }
    function addDisplayMode (doc, options) {
      var isMultiPage = options.mode === 'tiled',
        zoom = 'fullpage',
        layout = isMultiPage ? 'twoleft' : 'single', // or maybe 'continuous'
        pmode = isMultiPage ? 'UseThumbs' : 'UseNone'; // or leave it to viewer with undefined
      doc.setDisplayMode(zoom, layout, pmode);
      return doc;
    }
  }
  function drawCropMarks (doc, cropRect) {
    var size = 3, points = [[0,-size], [0,0], [size,0]];
    saveGraphicsState(doc);
    doc.setLineCap('square');
    drawMarks('1', 0.6);
    drawMarks('0', 0.2);
    restoreGraphicsState(doc);
    function drawMarks (color, lineWidth) {
      doc.setDrawColor(color);
      doc.setLineWidth(lineWidth);
      doc.lines(points, cropRect.left, cropRect.top + size, [1,1]);
      doc.lines(points, cropRect.right, cropRect.top + size, [-1,1]);
      doc.lines(points, cropRect.right, cropRect.bottom - size, [-1,-1]);
      doc.lines(points, cropRect.left, cropRect.bottom - size, [1,-1]);
    }
  }
  function drawTextDimensions (doc, textModels, sourceRect, targetRect, drawMode) {
    var coordinateSystem = getTextCoordinateSystem(sourceRect, targetRect),
      globalScale = coordinateSystem.globalScale,
      rootX = coordinateSystem.rootX,
      rootY = coordinateSystem.rootY,
      rectsCollide = APP.geom.rectsCollide,
      pointInRect = APP.geom.pointInRect,
      markerLength = Math.min(targetRect.width, targetRect.height) * 0.02,
      fontSize = Math.round(markerLength * 2.75);
    saveGraphicsState(doc);
    setDrawOptions(doc);
    textModels.forEach(function(text){
      if (!rectsCollide(sourceRect, text.rect)) return;
      var isOrnament = text.font.getIsOrnament();
      text.lines.forEach(function(line){
        processLine(line, isOrnament);
      });
    });
    restoreGraphicsState(doc);
    function setDrawOptions (doc) {
      var color = drawMode === 'texture' ? 0 : Math.round(0.5 * 255);
      doc.setLineWidth(0.2);
      doc.setLineCap('butt');
      doc.setDrawColor(color);
      doc.setTextColor(color);
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(fontSize);
    }
    function processLine (line, isOrnament) {
      var rect = line.rect,
        capsRect = isOrnament ? rect : line.capsRect;
      drawIfVisible({ x: rect.right, y: rect.bottom }, drawRightMarker);
      drawIfVisible({ x: capsRect.left, y: capsRect.top }, drawTopMarker);
      if (capsRect === rect) {
        drawIfVisible({ x: rect.left, y: rect.bottom }, drawCrossMarker);
      } else {
        drawIfVisible({ x: rect.left, y: rect.bottom }, drawLeftMarker);
        drawIfVisible({ x: capsRect.left, y: capsRect.bottom }, drawBottomMarker);
      }
      drawIfVisible({ x: rect.left + rect.width / 2, y: rect.bottom }, function(x, y){
        drawDimensionLabel(x, y, rect.width);
      });
      drawIfVisible({ x: capsRect.left, y: capsRect.top + capsRect.height / 2 }, function(x, y){
        drawDimensionLabel(x, y, capsRect.height);
      });
    }
    function drawIfVisible (point, drawFn) {
      if (pointInRect(point, sourceRect)) {
        drawFn(rootX + point.x * globalScale, rootY + point.y * globalScale);
      }
    }
    function drawDimensionLabel (x, y, millimeters) {
      drawCenteredText(x, y, APP.i18n.formatNumber(millimeters, { maximumFractionDigits: 0 }));
    }
    function drawCenteredText (x, y, text) {
      var estimatedWidth = text.length * fontSize * 0.2;
      doc.text(text, x - estimatedWidth / 2, y + fontSize * 0.12);
    }
    function drawLeftMarker (x, y) {
      doc.lines([[0, markerLength]], x, y - markerLength / 2);
      doc.lines([[markerLength / 2, 0]], x, y);
    }
    function drawRightMarker (x, y) {
      doc.lines([[0, markerLength]], x, y - markerLength / 2);
      doc.lines([[-markerLength / 2, 0]], x, y);
    }
    function drawTopMarker (x, y) {
      doc.lines([[markerLength, 0]], x - markerLength / 2, y);
      doc.lines([[0, markerLength / 2]], x, y);
    }
    function drawBottomMarker (x, y) {
      doc.lines([[markerLength, 0]], x - markerLength / 2, y);
      doc.lines([[0, -markerLength / 2]], x, y);
    }
    function drawCrossMarker (x, y) {
      doc.lines([[markerLength, 0]], x - markerLength / 2, y);
      doc.lines([[0, markerLength]], x, y - markerLength / 2);
    }
  }
  function getTextCoordinateSystem (sourceRect, targetRect) {
    var globalScale = getContainScale(sourceRect, targetRect),
      rootX = targetRect.left - sourceRect.left * globalScale +
        (targetRect.width - sourceRect.width * globalScale) / 2,
      rootY = targetRect.top - sourceRect.top * globalScale + 
        (targetRect.height - sourceRect.height * globalScale) / 2;
    return {
      globalScale: globalScale,
      rootX: rootX,
      rootY: rootY,
    };
  }
  function drawTextModels (doc, textModels, sourceRect, targetRect, options) {
    var coordinateSystem = getTextCoordinateSystem(sourceRect, targetRect),
      globalScale = coordinateSystem.globalScale,
      rootX = coordinateSystem.rootX,
      rootY = coordinateSystem.rootY,
      rectsCollide = APP.geom.rectsCollide;
    textModels.forEach(function(text){
      if (!rectsCollide(sourceRect, text.rect)) return;
      saveGraphicsState(doc);
      setTextDrawOptions(doc, options, text.fontSize * globalScale, text.material);
      text.lines.forEach(function(line){
        if (!rectsCollide(sourceRect, line.rect)) return;
        var scaleX = globalScale * text.fontScaleX, scaleY = globalScale * text.fontScaleY;
        line.chars.forEach(function(char){
          var x, y, glyphPath = char.glyphPath;
          if (!glyphPath.length || !rectsCollide(sourceRect, char.rect)) return;
          x = rootX + globalScale * (text.x + line.x + char.x + char.kerningOffset);
          y = rootY + globalScale * (text.y + line.y + char.y);
          drawChar(doc, glyphPath, x, y, scaleX, scaleY, options.drawMode);
        });
      });
      restoreGraphicsState(doc);
    });
  }
  function setTextDrawOptions (doc, options, fontSize, material) {
    if (options.drawMode === 'texture') {
      doc.setFillColor.apply(doc, material.getPrintFill())
      doc.setDrawColor.apply(doc, material.getPrintStroke());
      doc.setLineWidth(fontSize * 0.0125);
      doc.setLineCap('round');
      doc.setLineJoin('round');
    } else if (options.drawMode === 'stroke') {
      doc.setDrawColor('0');
      doc.setLineWidth(0.2);
    } else {
      doc.setFillColor('0');
    }
  }
  function drawChar (doc, glyphPath, x, y, scaleX, scaleY, drawMode) {
    // Conversion to absolute is actually already done during glyphSpec creation
    // but with this redundancy we gain more stability trading a few cpu cycles.
    var components = splitPathIntoComponents(Snap.path.toAbsolute(glyphPath)),
      lastIndex = components.length - 1,
      drawStyle = {
        texture: 'FD',
        stroke: 'S',
        fill: 'f*',
      }[drawMode] || 'f*';
    components.forEach(function(component, i){
      var style = i === lastIndex ? drawStyle : null,
        closed = component[component.length-1][0].toUpperCase() === 'Z',
        props = pathComponentToLinesProps(component),
        baseX = x + props.x * scaleX,
        baseY = y + props.y * scaleY;
      doc.lines(props.lines, baseX, baseY, [scaleX, scaleY], style, closed);
    });
  }
  function splitPathIntoComponents (path) {
    var components = [], currentPath;
    if (typeof path === 'string') path = Snap.parsePathString(path);
    path.forEach(function(segment, i){
      var operation = segment[0],
        isMoveTo = operation === 'M' || operation === 'm',
        isFirst = !i;
      if (isMoveTo || isFirst) {
        currentPath = [];
        components.push(currentPath);
      }
      if (isFirst && !isMoveTo) currentPath.push(['M', 0, 0]);
      currentPath.push(segment);
    });
    return components;
  }
  function pathComponentToLinesProps (component) {
    var moveTo = component[0],
      path = Snap.path.toRelative(Snap.path.toCubic(component));
    return {
      x: moveTo[1],
      y: moveTo[2],
      lines: path.slice(1).map(function(segment){
        return segment.slice(1);
      })
    };
  }
  function getTextBoundingRect (textModels) {
    // Code smell: The exact value of the safety margin is based on the font size,
    // whereas the size of the dimensions markers and labels is based on the page size.
    // So there’s still a potential for clipping, but it seems good enough for now.
    return APP.geom.getRectsBoundingRect(textModels.map(function(textModel){
      var rect = textModel.rect,
        safety = textModel.fontSize * 0.2;
      return APP.geom.createRect(rect.left - safety, rect.top - safety,
        rect.right + safety, rect.bottom + safety);
    }));
  }
  // createDummyPdf();
  // function createDummyPdf () {
  //   var dimensions = { width: 200, height: 300 },
  //     doc = new jsPDF({
  //       orientation: dimensions.width > dimensions.height ? 'landscape' : 'portrait',
  //       unit: 'mm',
  //       format: [dimensions.width, dimensions.height],
  //     });
  //   addDummyShapes(doc);
  //   doc.output('dataurlnewwindow');
  // }
  // function addDummyShapes (doc) {
  //   var scale = [1, 1], style = 'f*', closed = true,
  //     x = 50, y = 100, lines = [
  //       [50, -50],
  //       [50, 50],
  //       [0, 50, -100, 50, -100, 0]
  //     ],
  //     x2 = 75, y2 = 100, lines2 = [
  //       [25, 25],
  //       [25, -25]
  //     ];
  //   doc.lines(lines, x, y, scale, null, closed);
  //   doc.lines(lines2, x2, y2, scale, null, closed);
  //   doc.lines(lines, 87.5, y, [0.25, 0.75], style, closed);
  // }
})(this.APP || (this.APP = {}), this.jsPDF, this.Snap, this.location, this.navigator);
