(function(APP){ 'use strict';
  APP.textModel = {
    createTextModel: createTextModel,
    textModelToStorageObject: textModelToStorageObject,
    textModelToCamLines: textModelToCamLines,
  };
  function createTextModel (spec) {
    var font = validateTextModelSpec(spec).font,
      material = spec.material,
      x = 0,
      y = 0,
      fontSize = getValidFontSize(spec.fontSize || 50, spec.text),
      charWidth = spec.charWidth || 100,
      leading = getValidLeading(spec.leading || 0),
      alignment = spec.alignment || 'left',
      textString = '',
      textLines = [],
      ascent = 0,
      fontScaleX = 1,
      fontScaleY = 1,
      fieldWidth = 0,
      fieldHeight = 0,
      currentChar = null,
      textModel = APP.utils.makeEventDispatcher(Object.create(Object.prototype, {
        x: {
          get: function () { return x; },
          set: function (value) { reposition(value, y); }
        },
        y: {
          get: function () { return y; },
          set: function (value) { reposition(x, value); }
        },
        text: {
          get: function () { return textString; },
          set: function (value) {
            if (value !== textString) repopulate(value || '');
          }
        },
        lines: {
          get: function () { return textLines; }
        },
        fontSize: {
          get: function () { return fontSize; },
          set: function (value) {
            var newValue = getValidFontSize(value);
            if (fontSize === newValue) return;
            fontSize = newValue;
            textModel.leading = leading;
            redraw();
            textModel.trigger(textModel.events.fontSizeChange, fontSize);
          }
        },
        fontScaleX: {
          get: function () { return fontScaleX; }
        },
        fontScaleY: {
          get: function () { return fontScaleY; }
        },
        leading: {
          get: function () { return leading; },
          set: function (value) {
            var newValue = getValidLeading(value);
            // var newValue = getValidatedValue('leading', 0, 500, value);
            if (leading === newValue) return;
            leading = newValue;
            textModel.trigger(textModel.events.leadingChange, leading);
            redraw();
          }
        },
        charWidth: {
          get: function () { return charWidth; },
          set: function (value) {
            var newValue = getValidatedValue('charWidth', 80, 110, value);
            if (charWidth === newValue) return;
            charWidth = newValue;
            redraw();
            textModel.trigger(textModel.events.charWidthChange, charWidth);
          }
        },
        font: {
          get: function () { return font; },
          set: function (value) {
            if (value === font) return;
            font = value;
            // ToDo: emit changes to related properties to controls as well
            textModel.fontSize = fontSize;
            textModel.leading = leading;
            textModel.charWidth = charWidth;
            redraw();
            textModel.trigger(textModel.events.fontChange, font);
          }
        },
        material: {
          get: function () { return material; },
          set: function (value) {
            if (value === material) return;
            material = value;
            textModel.trigger(textModel.events.materialChange, material);
          }
        },
        alignment: {
          get: function () { return alignment; },
          set: function (value) {
            if (value === alignment) return;
            alignment = value;
            redraw();
            textModel.trigger('alignmentChange', alignment);
          }
        },
        currentChar: {
          get: function () { return currentChar; },
          set: function (value) {
            if (!value || value === currentChar) return;
            currentChar = value;
            textModel.trigger(textModel.events.charChange, currentChar);
          }
        },
        ascent: {
          get: function () { return ascent; }
        },
        rect: { get: getRect },
        getHitRect: {
          value: function (offsetPercent) {
            var rect = getRect();
            return !offsetPercent ? rect :
              APP.geom.expandRect(rect, fontSize / 100 * offsetPercent);
          }
        },
        reposition: { value: reposition },
        destroy: { value: destroy }
      }), ['fontChange', 'materialChange', 'alignmentChange',
          'fontSizeChange', 'leadingChange', 'charWidthChange',
          'charChange', 'textChange', 'rectChange',
          'repopulate', 'redraw', 'destroy'].join(' '));
    if (spec.text) repopulate(spec.text, spec.charsData);
    reposition(spec.x || 0, spec.y || 0);
    return textModel;
    function getValidFontSize (value, intendedString) {
      return getValidatedValue('fontSize', 20, 500, value, intendedString);
    }
    function getValidLeading (value) {
      var actualFontSize = fontSize / font.getCapHeight() * font.getUnitsPerEm();
      return Math.round(Math.max(-actualFontSize, Math.min(3 * actualFontSize, value)));
    }
    function getValidatedValue (fieldName, defaultMin, defaultMax, value, intendedString) {
      var range = font.getValueRange(fieldName,
          intendedString === undefined ? textString : intendedString),
        min = typeof range.min === 'number' ? range.min : defaultMin,
        max = typeof range.max === 'number' ? range.max : defaultMax;
      return Math.max(min, Math.min(max, value || 0));
    }
    function repopulate (newString, charsData) {
      var charIndex = 0;
      textString = newString;
      textLines = textString.split('\n').map(function(lineString){
        var line = createLine(textModel, lineString, charIndex, charsData, redraw);
        charIndex += lineString.length + 1;
        return line;
      });
      currentChar = textLines[0] && textLines[0].chars[0];
      textModel.trigger('repopulate');
      textModel.fontSize = textModel.fontSize;
      redraw();
    }
    function redraw () {
      var unitsPerEm = font.getUnitsPerEm(),
        actualFontSize = fontSize / font.getCapHeight() * unitsPerEm,
        actualLineHeight = actualFontSize + leading;
      fontScaleY = actualFontSize / unitsPerEm;
      fontScaleX = fontScaleY * charWidth / 100;
      ascent = fontScaleY * font.getAscent();
      fieldHeight = actualFontSize + (textLines.length - 1) * actualLineHeight;
      fieldWidth = redrawLines(actualFontSize, actualLineHeight, unitsPerEm);
      alignTextLines(textLines, actualLineHeight, fieldWidth, alignment);
      textModel.trigger('redraw');
      triggerRectChange();
    }
    function redrawLines (actualFontSize, actualLineHeight, unitsPerEm) {
      return textLines.reduce(function(maxWidth, line){
        line.height = actualFontSize;
        line.width = redrawChars(line, unitsPerEm);
        return Math.max(maxWidth, line.width);
      }, 0);
    }
    function redrawChars (line, unitsPerEm) {
      return line.chars.reduce(function(runX, char, i, chars){
        var glyphSpec = font.getGlyphSpec(char.unicode),
          horizAdvX = fontScaleX * glyphSpec.horizAdvX,
          preKerning = fontScaleX * (glyphSpec.getKerning(i ? chars[i-1].unicode : null) +
            unitsPerEm * char.kerning / 100),
          postKerning = fontScaleX * (!chars[i+1] ? 0 :
            font.getGlyphSpec(chars[i+1].unicode).getKerning(char.unicode)),
          rectWidth = horizAdvX + (preKerning + postKerning) / 2;
        char.x = runX;
        char.width = rectWidth;
        char.height = line.height;
        char.kerningOffset = preKerning/2;
        char.glyphPath = glyphSpec.d;
        return runX + rectWidth;
      }, 0);
    }
    function alignTextLines (textLines, actualLineHeight, fieldWidth, alignment) {
      textLines.forEach(function(line, i){
        var offsetX = alignment === 'left' ? 0 :
          (fieldWidth - line.width) / (alignment === 'center' ? 2 : 1);
        setLineOffsetX(line, offsetX, i * actualLineHeight);
      });
      function setLineOffsetX (line, offsetX, offsetY) {
        line.x = offsetX;
        line.y = offsetY;
      }
    }
    function reposition (newX, newY) {
      if (newX === x && newY === y) return;
      x = newX || 0;
      y = newY || 0;
      triggerRectChange();
    }
    function triggerRectChange () {
      textModel.trigger('rectChange', getRect());
    }
    function getRect () {
      var top = y - ascent;
      return {
        left: x, top: top,
        width: fieldWidth, height: fieldHeight,
        right: x + fieldWidth, bottom: top + fieldHeight
      };
    }
    function destroy () {
      textString = '';
      textLines.length = 0;
      textModel.trigger(textModel.events.destroy);
    }
  }
  
  function createLine (textModel, lineString, baseCharIndex, charsData, redraw) {
    var x = 0, y = 0, width = 0, height = 0, chars,
      line = Object.create(Object.prototype, {
        x: {
          get: function () { return x; },
          set: function (value) { x = value; }
        },
        y: {
          get: function () { return y; },
          set: function (value) { y = value; }
        },
        width: {
          get: function () { return width; },
          set: function (value) { width = value; }
        },
        height: {
          get: function () { return height; },
          set: function (value) { height = value; }
        },
        rect: {
          get: function () {
            var ascent = textModel.ascent,
              left = textModel.x + x,
              top = textModel.y + y - ascent;
            return {
              left: left, top: top,
              width: width, height: height,
              right: left + width, bottom: top + height,
            };
          }
        },
        capsRect: {
          get: function () {
            var left = textModel.x + x,
              right = left + width,
              bottom = textModel.y + y,
              top = bottom - textModel.fontSize;
            return APP.geom.createRect(left, top, right, bottom);
          }
        },
        chars: {
          get: function () { return chars; }
        }
      });
    chars = lineString.split('').map(function(charString, charIndex){
      var charData = charsData && charsData[baseCharIndex + charIndex];
      return createChar(textModel, line, charString, charData, redraw);
    });
    return line;
  }
  
  function createChar (textModel, line, charString, charData, redraw) {
    var x = 0, y = 0, width = 0, height = 0, glyphPath = '',
      kerning = charData && charData.kerning || 0,
      kerningOffset = 0;
    return Object.create(Object.prototype, {
      x: {
        get: function () { return x; },
        set: function (value) { x = value; }
      },
      y: {
        get: function () { return y; },
        set: function (value) { y = value; }
      },
      width: {
        get: function () { return width; },
        set: function (value) { width = value; }
      },
      height: {
        get: function () { return height; },
        set: function (value) { height = value; }
      },
      unicode: {
        get: function () { return charString; }
      },
      glyphPath: {
        get: function () { return glyphPath; },
        set: function (value) { glyphPath = value; }
      },
      kerning: {
        get: function () { return kerning; },
        set: function (value) {
          var newValue = Math.max(-50, Math.min(50, value || 0));
          if (newValue === kerning) return;
          kerning = newValue;
          redraw();
        }
      },
      kerningOffset: {
        get: function () { return kerningOffset; },
        set: function (value) { kerningOffset = value; }
      },
      rect: { enumerable: true,
        get: function () {
          var left = x + line.x + textModel.x,
            top = y + line.y + textModel.y - textModel.ascent;
          return {
            left: left, top: top,
            width: width, height: height,
            right: left + width, bottom: top + height,
          };
        }
      }
    });
  }
  
  function textModelToStorageObject (textModel) {
    return {
      x: textModel.x,
      y: textModel.y,
      alignment: textModel.alignment,
      charWidth: textModel.charWidth,
      fontId: textModel.font.getId(),
      fontSize: textModel.fontSize,
      leading: textModel.leading,
      materialId: textModel.material.getId(),
      text: textModel.text,
      charsData: collectCharsData(textModel.lines),
    };
  }
  
  function textModelToCamLines (textModel) {
    return textModel.lines.filter(function(line){
      return line.chars.length;
    }).map(function(line){
      return APP.utils.reducePrecision({
        material: textModel.material.getId(),
        fontFamily: textModel.font.getId(),
        capHeight: textModel.fontSize,
        scaleX: textModel.charWidth / 100,
        y: line.chars[0].rect.top + textModel.ascent,
        chars: line.chars.map(function(char){
          return {
            unicode: char.unicode,
            x: char.rect.left + char.kerningOffset
          };
        })
      }, 3);
    });
  }
  
  function collectCharsData (lines) {
    var i = -2;
    return lines.reduce(function(charsData, line){
      return line.chars.reduce(function(charsData, char, charIndex){
        var data = {};
        if (char.kerning) data.kerning = char.kerning;
        i += charIndex ? 1 : 2;
        if (Object.keys(data).length) charsData[i] = data;
        return charsData;
      }, charsData);
    }, {});
  }
  
  function validateTextModelSpec (spec) {
    if (typeof spec !== 'object') throwError('');
    if (typeof spec.font !== 'object') throwError('.font');
    if (typeof spec.material !== 'object') throwError('.material');
    return spec;
    function throwError (suffix) {
      throw 'invalid parameter value for textModel spec'+suffix;
    }
  }
})(this.APP || (this.APP = {}));
