(function(APP, document){ 'use strict';
  APP.imageLoad = {
    createImageLoadView: createImageLoadView,
  };
  function createImageLoadView (router, layout) {
    var wrapNode = document.querySelector('.image-load'),
      prevButton = wrapNode.querySelector('.btn-prev'),
      nextButton = wrapNode.querySelector('.btn-next:not(.btn-without-template)'),
      skipButton = wrapNode.querySelector('.btn-without-template'),
      imageArea = wrapNode.querySelector('.image-area'),
      fileInputs = toArray(wrapNode.querySelectorAll('input[type="file"]')),
      dropZone,
      currentFile;
    if (prevButton) prevButton.addEventListener('click', onPrevClick, false);
    if (nextButton) nextButton.addEventListener('click', onNextClick, false);
    if (skipButton) skipButton.addEventListener('click', onSkipClick, false);
    fileInputs.forEach(function(fileInput){
      fileInput.addEventListener('change', onInputChange, false);
    });
    router.on('routeChange', onRouteChange);
    adjustPrimaryButton();
    function onRouteChange (event) {
      var isCurrentRoute = event.data === 'image-load';
      toggleDropZone(isCurrentRoute);
      if (isCurrentRoute) showUrl(layout.imageUrl);
    }
    function onPrevClick (event) {
      event.preventDefault();
      router.prev();
    }
    function onNextClick (event) {
      event.preventDefault();
      if (layout.imageUrl) proceed();
      else APP.dialogs.showAlert('noImageSelected');
    }
    function onSkipClick (event) {
      event.preventDefault();
      layout.imageUrl = null;
      showImage(null);
      proceed();
    }
    function proceed () {
      currentFile = null;
      router.next();
    }
    function toggleDropZone (active) {
      if (active && !dropZone) {
        dropZone = createDropZone(document, document.querySelector('.drop-zone'), handleFiles)
      } else if (!active && dropZone) {
        dropZone.destroy();
        dropZone = null;
      }
    }
    function createDropZone (node, indicatorNode, callbackFn) {
      var indicator = createIndicator(indicatorNode);
      if (!node) node = document;
      toggleListeners(node, true);
      return {
        destroy: destroy
      };
      function destroy () {
        indicator.destroy();
        indicator = null;
        if (!node) return;
        toggleListeners(node, false);
        node = null;
      }
      function toggleListeners (node, active) {
        var toggleFn = (active ? node.addEventListener : node.removeEventListener).bind(node);
        toggleFn('dragover', onDragOver, false);
        toggleFn('drop', onDrop, false);
        toggleFn('dragend', onDragEnd, false);
      }
      function onDragOver (e) {
        e.preventDefault();
        indicator.show();
      }
      function onDrop (e) {
        e.preventDefault();
        var dataTransfer = e.dataTransfer;
        indicator.hide();
        callbackFn(dataTransfer.items ?
          processItems(dataTransfer.items) :
          processFiles(dataTransfer.files));
        function processItems (dataItemList) {
          var files = [], len = dataItemList.length, i = 0, item;
          while (i < len) {
            item = dataItemList[i++];
            if (item.kind === 'file') files.push(item.getAsFile());
          }
          return files;
        }
        function processFiles (fileList) {
          var files = [];
          for (var i = 0, len = fileList.length; i < len; ++i) {
            files.push(fileList[i]);
          }
          return files;
        }
      }
      function onDragEnd (e) {
        e.preventDefault();
      }
    }
    function createIndicator (indicatorNode) {
      var timeout = null,
        setTimeout = window.setTimeout,
        clearTimeout = window.clearTimeout;
      return {
        show: show,
        hide: hide,
        destroy: destroy
      };
      function show () {
        indicatorNode.style.display = 'block';
        cancelTimeout();
        timeout = setTimeout(onTimeout, 200);
      }
      function hide () {
        cancelTimeout();
        indicatorNode.style.display = 'none';
      }
      function cancelTimeout () {
        if (timeout) clearTimeout(timeout);
        timeout = null;
      }
      function onTimeout () {
        timeout = null;
        hide();
      }
      function destroy () {
        cancelTimeout();
        hide();
      }
    }
    function onInputChange (event) {
      var files = event.target.files;
      if (files.length) handleFile(event.target.files[0]);
    }
    function clearInputs () {
      fileInputs.forEach(function(fileInput){
        fileInput.value = '';
      });
    }
    function handleFiles (files) {
      if (files && files.length) handleFile(files[0]);
    }
    function handleFile (file) {
      currentFile = file;
      readImage(file, function(imgData){
        // console.log(file.name, file.type, imgData.imgNode.naturalWidth, imgData.imgNode.naturalHeight, file.size, imgData.dataUrl.length);
        if (currentFile !== file) return;
        showImage(imgData.imgNode);
        layout.imageUrl = imgData.dataUrl;
        clearInputs();
      }, function(errorMsgName, data){
        if (currentFile !== file) return;
        APP.dialogs.showAlert(errorMsgName, data);
        clearInputs();
      });
    }
    function showUrl (url) {
      if (url) {
        var currentImg = imageArea.querySelector('img');
        if (!currentImg || currentImg.src !== getQualifiedUrl(url)) {
          var img = document.createElement('img');
          img.src = url;
          showImage(img);
        }
      } else {
        showImage(null);
      }
    }
    function showImage (imgNode) {
      imageArea.innerHTML = '';
      if (imgNode) imageArea.appendChild(imgNode);
      adjustPrimaryButton(!!imgNode);
    }
    function adjustPrimaryButton (imagePresent) {
      var className = 'btn-primary', toggleClass = APP.dom.toggleClass;
      toggleClass(nextButton, className, imagePresent);
      toggleClass(skipButton, className, !imagePresent);
    }
    function readImage (file, successFn, errorFn) {
      var maxFileSize = 8e6;
      if (!file) return errorFn('noFileRecognized');
      if (!looksLikeImage(file)) return errorFn('fileTypeNotSupported');
      if (!file.size) return errorFn('fileIsEmpty');
      if (file.size > maxFileSize) return errorFn('fileSizeExceeded', { mb: Math.round(maxFileSize/1e6) });
      readAsDataURL(file, function(dataUrl){
        dataUrlToImage(dataUrl, function(imgNode){
          successFn({ dataUrl: dataUrl, imgNode: imgNode });
        }, function(err){
          console.error(err);
          errorFn('imageParseError');
        });
      }, function(err){
        console.error(err);
        errorFn('fileReadError', { fileName: file.name });
      });
    }
    function readAsDataURL (file, successFn, errorFn) {
      var fileReader = new FileReader();
      fileReader.onload = onLoad;
      fileReader.onerror = onError;
      fileReader.readAsDataURL(file);
      function onLoad (event) {
        var dataUrl = event.target.result;
        if (typeof successFn === 'function') successFn(dataUrl);
      }
      function onError (err) {
        if (typeof errorFn === 'function') errorFn(err);
      }
    }
    function dataUrlToImage (dataUrl, successFn, errorFn) {
      var img = document.createElement('img');
      toggleListeners(true);
      img.src = dataUrl;
      function onLoad () {
        toggleListeners(false);
        if (typeof successFn === 'function') successFn(img);
      }
      function onError (err) {
        toggleListeners(false);
        if (typeof errorFn === 'function') errorFn(err);
      }
      function toggleListeners (active) {
        var fnName = active ? 'addEventListener' : 'removeEventListener';
        img[fnName]('load', onLoad, false);
        img[fnName]('error', onError, false);
      }
    }
    function looksLikeImage (file) {
      return 'image/jpeg image/png image/gif'.split(' ').indexOf(file.type) >= 0 ||
        'jpg jpeg jpe jif jfif jfi png gif'.split(' ').indexOf(extractSuffix(file.name)) >= 0;
    }
    function extractSuffix (fileName) {
      var matches = /\.([^.]+)$/.exec(fileName);
      return matches && matches[1].toLowerCase();
    }
    function getQualifiedUrl (potentiallyRelativeUrl) {
      var a = document.createElement('a');
      a.href = potentiallyRelativeUrl;
      return a.href;
    }
    function toArray (arrayLikeObject) {
      return Array.prototype.slice.call(arrayLikeObject);
    }
  }
})(this.APP || (this.APP = {}), this.document);
