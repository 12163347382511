(function(APP, window, document){ 'use strict';
  APP.loading = {
    createBlockLoader: createBlockLoader,
    createContentLoader: createContentLoader,
  };
  function createBlockLoader () {
    return createFader(document.querySelector('.blockLoader'));
  }
  function createContentLoader (node) {
    if (!node) return console.warn('No html node given to createContentLoader');
    var visible = window.getComputedStyle(node).display !== 'none';
    return {
      show: show,
      hide: hide,
    };
    function show () {
      if (visible) return;
      visible = true;
      node.style.display = 'block';
    }
    function hide () {
      if (!visible) return;
      visible = false;
      node.style.display = 'none';
    }
  }
  function createFader (node, duration) {
    var style = node.style,
      visible = window.getComputedStyle(node).display !== 'none',
      timeout;
    if (!duration) duration = 300;
    node.style.transition = duration + 'ms';
    node.style.opacity = visible ? 1 : 0;
    return {
      show: show,
      hide: hide,
    };
    function show () {
      if (visible) return;
      visible = true;
      cancelTimeout();
      style.display = 'block';
      style.pointerEvents = '';
      timeout = setTimeout(function(){
        timeout = null;
        if (visible) style.opacity = 1;
      }, 0);
    }
    function hide () {
      if (!visible) return;
      visible = false;
      cancelTimeout();
      style.opacity = 0;
      style.pointerEvents = 'none';
      timeout = setTimeout(function(){
        timeout = null;
        if (!visible) style.display = 'none';
      }, duration);
    }
    function cancelTimeout () {
      if (!timeout) return;
      clearTimeout(timeout);
      timeout = null;
    }
  }
})(this.APP || (this.APP = {}), this, this.document);
