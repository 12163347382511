(function(APP){ 'use strict';
  var apiUrl = './api/v1';
  APP.api = {
    getLayoutIdWithPromise: getLayoutIdWithPromise,
    getLayoutData: getLayoutData,
    putLayoutWithPromise: putLayoutWithPromise,
    sendLayoutLinkWithPromise: sendLayoutLinkWithPromise,
    sendOfferRequestWithPromise: sendOfferRequestWithPromise,
    sendOrderWithPromise: sendOrderWithPromise,
    postImageWithPromise: postImageWithPromise,
    postContactMessageWithPromise: postContactMessageWithPromise,
  };
  function getLayoutIdWithPromise () {
    return APP.net.requestJsonWithPromise('GET', apiUrl+'/init');
  }
  function getLayoutData (layoutId, successFn, errorFn, abortFn) {
    return APP.net.getJson(apiUrl+'/configs/'+layoutId, successFn, errorFn, abortFn);
  }
  function putLayoutWithPromise (layoutId, data, camData) {
    var bodyData = { data: data, camData: camData };
    return requestWithIdValidation('PUT', apiUrl+'/configs/'+layoutId, bodyData);
  }
  function sendLayoutLinkWithPromise (layoutId, formdata) {
    var bodyData = { configId: layoutId, customer: formdata };
    return requestWithIdValidation('POST', apiUrl+'/linkemail', bodyData);
  }
  function sendOfferRequestWithPromise (layoutId, customerData) {
    var bodyData = { configId: layoutId, customer: customerData };
    return requestWithIdValidation('POST', apiUrl+'/offerrequests', bodyData);
  }
  function sendOrderWithPromise (layoutId, customerData) {
    var bodyData = { configId: layoutId, customer: customerData };
    return requestWithIdValidation('POST', apiUrl+'/orders', bodyData);
  }
  function postImageWithPromise (layoutId, base64DataUrl) {
    var bodyData = { configId: layoutId, image: base64DataUrl };
    return APP.net.requestJsonWithPromise('POST', apiUrl+'/images/full', bodyData);
  }
  function postContactMessageWithPromise (customerData) {
    var bodyData = { customer: customerData };
    return requestJsonAugmented('POST', apiUrl+'/contact', bodyData);
  }
  function requestWithIdValidation (method, url, bodyData) {
    var originalPromise = requestJsonAugmented(method, url, bodyData),
      derivedPromise = originalPromise.then(validateLayoutId);
    derivedPromise.abort = originalPromise.abort;
    return derivedPromise;
  }
  function validateLayoutId (layoutId) {
    if (typeof layoutId === 'string' && /^[0-9a-f]{32}$/.test(layoutId)) return layoutId;
    else throw 'invalid layoutId: '+JSON.stringify(layoutId);
  }
  function requestJsonAugmented (method, url, bodyData) {
    return APP.net.requestJsonWithPromise(method, url, addLangParam(bodyData));
  }
  function addLangParam (bodyData) {
    if (isPlainObject(bodyData)) return Object.assign({}, bodyData, { lang: APP.i18n.lang });
    else console.warn('Couldn’t add lang parameter to body data', bodyData);
    return bodyData;
  }
  function isPlainObject (value) {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
  }
})(this.APP || (this.APP = {}));
