(function (APP, document, undefined) {
  "use strict";
  APP.rendering = {
    createCanvas: createCanvas,
  };
  function createCanvas(
    fonts,
    materials,
    viewport,
    editBox,
    ornamentBrowser,
    router
  ) {
    var canvas = APP.utils.makeEventDispatcher(
        {
          getNode: getNode,
          updateGeometry: updateGeometry,
          getDimensions: getDimensions,
          getCurrentTextField: getCurrentTextField,
          getTextFields: getTextFields,
          getStorageData: getStorageData,
          changeTextField: changeTextField,
          addDefaultTextField: addDefaultTextField,
          editTextField: editTextField,
          removeTextField: removeTextField,
          addOrnament: addOrnament,
          getKerningMode: getKerningMode,
          toggleKerningMode: toggleKerningMode,
          getWidthsMode: getWidthsMode,
          toggleWidthsMode: toggleWidthsMode,
          getTextureScale: getTextureScale,
          canvasToViewport: canvasToViewport,
          restoreTextFields: restoreTextFields,
        },
        "textFieldAdd textFieldRemove textFieldChange kerningModeChange widthsModeChange dimensionsChange"
      ),
      wrapNode = createWrapNode(),
      svgNode = APP.dom.createSvgNode(
        "svg",
        { xmlns: "http://www.w3.org/2000/svg" },
        wrapNode
      ),
      fieldsWrap = APP.dom.createSvgNode("g", null, svgNode),
      // widthsWrap = APP.dom.createSvgNode('g', null, svgNode),
      // highlightWrap = APP.dom.createSvgNode('g', null, svgNode),
      hud = APP.hud.createHud(canvas, viewport),
      // highlightManager = APP.hud.createHighlightManager(canvas, highlightWrap),
      // kerningManager = APP.hud.createKerningManager(canvas, highlightWrap, viewport),
      // widthsManager = APP.hud.createWidthsManager(canvas, widthsWrap, viewport),
      shaderManager = createShaderManager(svgNode, router),
      transformProperty = APP.detection.meta.transformProperty,
      // dummyRect = createDummyRect(svgNode),
      kerningMode = false,
      widthsMode = false,
      measurement,
      textureScale = 1,
      matrix,
      inverseMatrix,
      defaultFontSize,
      textFields = [],
      currentTextField = null;
    svgNode.style.position = "absolute";
    return canvas;
    function createWrapNode() {
      var wrapNode = document.createElement("div");
      wrapNode.style.position = "absolute";
      wrapNode.style[transformProperty + "Origin"] = "0 0 0";
      return wrapNode;
    }
    function getNode() {
      return wrapNode;
    }
    function getKerningMode() {
      return kerningMode;
    }
    function toggleKerningMode(active) {
      var newMode = active === undefined ? !kerningMode : !!active;
      if (newMode === kerningMode) return;
      kerningMode = newMode;
      canvas.trigger(canvas.events.kerningModeChange, kerningMode);
    }
    function getWidthsMode() {
      return kerningMode;
    }
    function toggleWidthsMode(active) {
      var newMode = active === undefined ? !widthsMode : !!active;
      if (newMode === widthsMode) return;
      widthsMode = newMode;
      canvas.trigger(canvas.events.widthsModeChange, widthsMode);
    }
    function getTextureScale() {
      return textureScale;
    }
    function changeTextField(textField) {
      if (textField === currentTextField) return;
      if (currentTextField) currentTextField.toggleHighlight(false);
      currentTextField = textField;
      if (textField) {
        fieldsWrap.appendChild(textField.node);
        textField.toggleHighlight(true);
      }
      canvas.trigger(canvas.events.textFieldChange, textField);
    }
    function updateGeometry(_measurement, bgWidth, bgHeight, _textureScale) {
      measurement = _measurement;
      textureScale = _textureScale;
      updateMatrix(measurement);
      updateDimensions(measurement, bgWidth, bgHeight);
      defaultFontSize = Math.round(
        Math.min(measurement.width, measurement.height) * 0.075
      );
      if (!textFields.length) addDefaultTextField();
      canvas.trigger("dimensionsChange", measurement);
    }
    function getDimensions() {
      return measurement || { width: 0, height: 0 };
    }
    function viewportToCanvas(point) {
      var invMatrix = getInverseMatrix();
      point = viewport.viewportToContent(point);
      point.x /= textureScale;
      point.y /= textureScale;
      return invMatrix ? APP.geom.applyMatrix(invMatrix, point) : point;
    }
    function canvasToViewport(point) {
      var scaledPoint = {
        x: point.x * textureScale,
        y: point.y * textureScale,
      };
      return viewport.contentToViewport(
        matrix ? APP.geom.applyMatrix(matrix, scaledPoint) : scaledPoint
      );
    }
    function addDefaultTextField(instantEdit) {
      var font = fonts.defaultFont;
      var material = fonts.defaultFont.availableMaterials[0];
      if (APP.controls.font || APP.controls.material) {
        font = APP.controls.font;
        material = APP.controls.material;
      }
      console.log("material.getName() :>> ", material.getName());
      addTextField(
        {
          font: font,
          material: material,
          fontSize: defaultFontSize,
          text: editBox.defaultText,
        },
        true,
        instantEdit
      );
    }
    function addOrnament() {
      ornamentBrowser.open().then(function (spec) {
        if (spec)
          addTextField(
            {
              font: spec.font,
              text: spec.text,
              material: spec.font.availableMaterials[0],
              fontSize: defaultFontSize * 2,
            },
            true,
            false
          );
      });
    }
    function addTextField(spec, makeCurrent, instantEdit) {
      console.log("APP :>> ", APP);
      console.log("spec :>> ", spec);
      var field = APP.textField.createTextField(canvas, viewportToCanvas, spec),
        fieldRect;
      if (measurement && !("x" in spec || "y" in spec)) {
        fieldRect = field.rect;
        field.reposition(
          (measurement.width - fieldRect.width) / 2,
          (measurement.height - fieldRect.height) / 2
        );
      }
      textFields.push(field);
      if (makeCurrent) changeTextField(field);
      else fieldsWrap.appendChild(field.node);
      shaderManager.registerTextField(field);
      if (instantEdit) editTextField(field);
      canvas.trigger(canvas.events.textFieldAdd, field);
      return field;
    }
    function editTextField(field) {
      if (field.font.getIsOrnament()) ornamentBrowser.open(field);
      else editBox.editField(field);
    }
    function removeTextField(field) {
      var index = textFields.indexOf(field);
      if (index >= 0) textFields.splice(index, 1);
      field.destroy();
      if (currentTextField === field) changeTextField(null);
      if (index >= 0) canvas.trigger(canvas.events.textFieldRemove, field);
    }
    function updateMatrix(measurement) {
      var inQuad = APP.geom.rectAttributesToPoints(
          0,
          0,
          measurement.width * textureScale,
          measurement.height * textureScale
        ),
        outQuad = measurement.points;
      matrix = outQuad
        ? APP.geom.createProjectionMatrix(inQuad, outQuad)
        : null;
      wrapNode.style[transformProperty] = matrix
        ? APP.geom.toCssMatrix(matrix)
        : "";
      // dummyRect.setRect(0, 0, measurement.width, measurement.height);
      inverseMatrix = null;
      return matrix;
    }
    function getInverseMatrix() {
      return !matrix
        ? null
        : inverseMatrix || (inverseMatrix = APP.geom.invertMatrix(matrix));
    }
    function updateDimensions(measurement, bgWidth, bgHeight) {
      // ToDo: optimize size determination for 100% coverage of bg with minimal overscan
      var width = 2 * measurement.width, //Math.max(3 * measurement.width, bgWidth * bgWidth / measurement.width),
        height = 2 * measurement.height, //Math.max(3 * measurement.height, bgHeight * bgHeight / measurement.height),
        left = Math.round((measurement.width - width) / 2),
        top = Math.round((measurement.height - height) / 2);
      APP.dom.panScanSvgNode(svgNode, left, top, width, height, textureScale);
    }
    function getCurrentTextField() {
      return currentTextField;
    }
    function getTextFields(excludeOrnamentFields) {
      return excludeOrnamentFields
        ? textFields.filter(function (field) {
            return !field.font.getIsOrnament();
          })
        : textFields.slice();
    }
    function getStorageData() {
      sortTextFields();
      return {
        textFields: textFields.map(function (textField) {
          return APP.textModel.textModelToStorageObject(textField);
        }),
        camLines: textFields.reduce(function (camLines, textField) {
          return camLines.concat(APP.textModel.textModelToCamLines(textField));
        }, []),
      };
    }
    function sortTextFields() {
      var nodes = Array.prototype.slice.call(fieldsWrap.childNodes);
      textFields.sort(function (fieldA, fieldB) {
        return nodes.indexOf(fieldA.node) - nodes.indexOf(fieldB.node);
      });
    }
    function restoreTextFields(storageObjects) {
      clear();
      storageObjects.forEach(function (storageObject) {
        addTextField(storageObjectToSpec(storageObject));
      });
      function storageObjectToSpec(storageObject) {
        var spec = Object.assign({}, storageObject);
        spec.font = tryAndGetFont(spec.fontId);
        delete spec.fontId;
        spec.material = tryAndGetMaterial(spec.materialId);
        delete spec.materialId;
        return spec;
      }
      function tryAndGetFont(fontId) {
        var font = APP.fonts.getFontById(fontId);
        if (!font) {
          font = fonts.defaultFont;
          APP.dialogs.showAlert("legacyFontReplaced");
        }
        return font;
      }
      function tryAndGetMaterial(materialId) {
        var material = APP.materials.getMaterialById(materialId);
        if (!material) {
          material = APP.materials.get()[0];
          APP.dialogs.showAlert("legacyMaterialReplaced");
        }
        return material;
      }
    }
    function clear() {
      var i = textFields.length;
      while (i) removeTextField(textFields[--i]);
    }
  }
  function createShaderManager(svgNode, router) {
    var shaders = [],
      defs = initDefs();
    router.on(router.events.layoutIdChange, onLayoutIdChange);
    return {
      registerTextField: registerTextField,
    };
    function registerTextField(textField) {
      var shader = createShader(textField, defs);
      shaders.push(shader);
      shader.on(shader.events.destroy, onDestroy);
    }
    function initDefs() {
      var defs = svgNode.querySelector("defs");
      if (!defs) {
        defs = APP.dom.createSvgNode("defs");
        APP.dom.prependChild(svgNode, defs);
      }
      return defs;
    }
    function onLayoutIdChange() {
      shaders.forEach(function (shader) {
        shader.relink();
      });
    }
    function onDestroy(event) {
      var shader = event.target,
        index = shaders.indexOf(event.target);
      if (index >= 0) shaders.splice(index, 1);
      shader.off(shader.events.destroy, onDestroy);
    }
  }
  function createShader(textField, defs) {
    var simplified = APP.detection.meta.browser === "safari" /*|| APP.detection.meta.browser === "chrome"*/,
      gradient = createGradient(defs),
      filter = simplified ? null : createFilter(defs),
      shader = APP.utils.makeEventDispatcher(
        {
          relink: relink,
        },
        "destroy"
      );

    relink();
    toggleListeners(true);
    adjust(true);
    return shader;
    function relink() {
      var gradientId = idToUrl(gradient.getId());
      textField.visualNode.setAttribute("fill", gradientId);
      if (filter) {
        textField.visualNode.setAttribute("filter", idToUrl(filter.getId()));
        textField.visualNode.setAttribute("stroke", gradientId);
      }
    }
    function onMaterialChange() {
      adjust(true);
    }
    function onFontChange() {
      adjust();
    }
    function onFontSizeChange() {
      adjust();
    }
    function adjust(materialChanged) {
      if (filter)
        filter.adjust(textField.material, textField.font, textField.fontSize);
      addStroke(textField, !filter);
      if (materialChanged) gradient.adjust(textField.material);
    }
    function onDestroy() {
      toggleListeners(false);
      gradient.destroy();
      if (filter) filter.destroy();
      shader.trigger(shader.events.destroy);
    }
    function idToUrl(id) {
      return "url('" + location.href.replace(/#.*$/, "") + "#" + id + "')";
    }
    function toggleListeners(active) {
      var fnName = active ? "on" : "off";
      textField[fnName](textField.events.fontChange, onFontChange);
      textField[fnName](textField.events.fontSizeChange, onFontSizeChange);
      textField[fnName](textField.events.materialChange, onMaterialChange);
      textField[fnName](textField.events.destroy, onDestroy);
    }
  }
  function addStroke(textField, useFallback) {
    var grooved = textField.font.getGrooved(),
      unitsPerEm = textField.font.getUnitsPerEm();
    textField.visualNode.setAttribute("stroke-linejoin", "round");
    if (useFallback) {
      textField.visualNode.setAttribute(
        "stroke",
        textField.material.getBevelColor()
      );
    }
    textField.visualNode.setAttribute(
      "stroke-width",
      grooved && useFallback
        ? 1000 / textField.fontSize // ToDo: find out why this works
        : unitsPerEm * (useFallback ? 0.006 : 0.005)
    );
  }
  function createGradient(defs) {
    var id = APP.utils.defaultIdGenerator.next(),
      node;
    return {
      getId: getId,
      adjust: adjust,
      destroy: destroy,
    };
    function getId() {
      return id;
    }
    function adjust(material) {
      APP.dom.removeNode(node);
      node = appendNode(material);
    }
    function appendNode(material) {
      return defs.appendChild(
        parseSvg(
          '<linearGradient id="' +
            id +
            '" x1="0" y1="0" x2="0" y2="100%" gradientUnits="objectBoundingBox">' +
            '<stop offset="0" stop-color="' +
            material.getTopColor() +
            '"/>' +
            '<stop offset="1" stop-color="' +
            material.getBottomColor() +
            '"/>' +
            "</linearGradient>"
        )
      );
    }
    function destroy() {
      APP.dom.removeNode(node);
    }
  }
  function createFilter(defs) {
    var id = APP.utils.defaultIdGenerator.next(),
      node;
    return {
      getId: getId,
      adjust: adjust,
      destroy: destroy,
    };
    function getId() {
      return id;
    }
    function adjust(material, font, fontSize) {
      APP.dom.removeNode(node);
      node = appendNode(material, font, fontSize);
    }
    function appendNode(material, font, fontSize) {
      var grooved = font.getGrooved(),
        baseSize = fontSize * (font.getIsOrnament() ? 0.5 : 1);
      return defs.appendChild(
        parseSvg(
          '<filter id="' +
            id +
            '" x="-10%" y="-10%" width="120%" height="120%">' +
            // (grooved ? '<feMorphology operator="erode" radius="1" in="SourceAlpha"/>' : '')+
            (grooved
              ? '<feGaussianBlur stdDeviation="0.25" in="SourceAlpha"/>' +
                "<feComponentTransfer>" +
                '<feFuncA type="linear" slope="51" intercept="-50"/>' +
                "</feComponentTransfer>"
              : "") +
            '<feGaussianBlur stdDeviation="' +
            (grooved ? 0.5 : baseSize * 0.008 + '" in="SourceAlpha') +
            '" result="bevelBlur"/>' +
            '<feComponentTransfer in="bevelBlur" result="innerShadow">' +
            (grooved
              ? '<feFuncA type="table" tableValues="0 0.2 0.05 1"></feFuncA>'
              : '<feFuncA type="gamma" amplitude="1.5" exponent="12" offset="0.15"/>') +
            "</feComponentTransfer>" +
            '<feFlood result="bevelColor" flood-color="' +
            material.getBevelColor() +
            '"/>' +
            '<feComposite result="bevelComp" operator="out" in2="innerShadow"/>' +
            '<feOffset result="bevelOffset"' +
            (grooved ? "" : ' dy="-' + baseSize * 0.004 + '"') +
            "/>" +
            '<feBlend result="beveledRgb" in2="SourceGraphic"/>' +
            '<feComposite result="beveledComp" operator="in" in2="SourceAlpha"/>' +
            '<feOffset dy="' +
            baseSize * 0.025 +
            '" in="SourceAlpha"/>' +
            '<feGaussianBlur result="blur" stdDeviation="' +
            baseSize * 0.03 +
            '"/>' +
            '<feFlood flood-color="' +
            material.getShadowColor() +
            '" flood-opacity="0.75"/>' +
            '<feComposite result="composite" operator="in" in2="blur"/>' +
            '<feBlend result="blend" in="beveledComp"/>' +
            "</filter>"
        )
      );
    }
    function destroy() {
      APP.dom.removeNode(node);
    }
  }
  function parseSvg(nodeStr) {
    var wrap = document.createElement("div");
    wrap.innerHTML =
      '<svg xmlns="http://www.w3.org/2000/svg">' + nodeStr + "</svg>";
    return wrap.firstChild.firstChild;
  }
  // function createDummyRect (parentNode) {
  //   var rect = APP.dom.createSvgNode('rect', {
  //     'fill': 'rgba(255, 0, 0, 0.2)',
  //     'stroke': 'black',
  //     'stroke-width': 2,
  //   }, parentNode);
  //   return {
  //     setRect: setRect,
  //   };
  //   function setRect (left, top, width, height) {
  //     rect.setAttribute('x', left);
  //     rect.setAttribute('y', top);
  //     rect.setAttribute('width', width);
  //     rect.setAttribute('height', height);
  //   }
  // }
})(this.APP || (this.APP = {}), this.document);
