(function(APP){ 'use strict';
  APP.storage = {
    createLayout: createLayout,
  };
  function createLayout (router) {
    var layoutId, layoutIdPromise, layoutIdIsVirgin,
      imageUrl, imageUploadPromise, dataUrl,
      lines, textModels,
      measurement, textFields,
      blockLoader = APP.loading.createBlockLoader(),
      uploader = createUploader(getLayoutIdPromise, getImageUploadPromise, setLayoutId),
      layout = Object.create(Object.prototype, {
        imageUrl: { enumerable: true,
          get: function(){ return imageUrl; },
          set: function(url){
            var isDataUrl = url && url.substr(0,5) === 'data:';
            imageUrl = url;
            if (isDataUrl) dataUrl = url;
            if (imageUploadPromise && imageUploadPromise.abort) {
              imageUploadPromise.abort();
            }
            imageUploadPromise = !url ? null :
              isDataUrl ? postImage() : Promise.resolve(true);
          }
        },
        dataUrl: {
          get: function(){
            return dataUrl;
          }
        },
        measurement: { enumerable: true,
          get: function() { return measurement; },
          set: function(value){
            if (APP.utils.jsonEquals(value, measurement)) return;
            measurement = value;
            if (measurement) uploader.persistLayout(layout);
          }
        },
        textFields: { enumerable: true,
          get: function() { return textFields; },
          set: function(value){
            if (APP.utils.jsonEquals(value, textFields)) return;
            textFields = value;
            if (textFields) uploader.persistLayout(layout);
          }
        },
        textModels: {
          get: function(){
            return textModels;
          }
        },
        setTextFieldData: {
          value: function (fields, camLines, textFieldModels) {
            lines = camLines;
            layout.textFields = fields;
            textModels = textFieldModels;
          }
        },
        getStorageData: {
          value: function () {
            return Object.keys(layout).reduce(function(data, key){
              if (layout[key]) data[key] = layout[key];
              return data;
            }, {});
          }
        },
        getCamData: {
          value: function () {
            var camData = {
              lines: lines,
            };
            if (measurement && measurement.width && measurement.height) {
              camData.width = measurement.width;
              camData.height = measurement.height;
            }
            return camData;
          }
        },
        ensurePersistence: {
          value: function ensurePersistence () {
            return uploader.getUploadPromise() || layoutIdPromise || uploader.persistLayout(layout);
          }
        }
      });
    router.on('layoutIdChange', onLayoutIdChange);
    adjustToLayoutId(router.getLayoutId());
    return layout;
    function onLayoutIdChange (event) {
      adjustToLayoutId(event.data);
    }
    function adjustToLayoutId (newLayoutId) {
      if (newLayoutId) {
        if (newLayoutId !== layoutId) loadLayout(newLayoutId);
      } else {
        clearLayout();
        router.goTo('landingpage');
        blockLoader.hide();
      }
    }
    function getLayoutIdPromise () {
      return layoutIdPromise || (layoutIdPromise = createLayoutIdPromise());
    }
    function getImageUploadPromise () {
      return imageUploadPromise;
    }
    function setLayoutId (responseId, isVirgin) {
      layoutId = responseId;
      layoutIdIsVirgin = isVirgin;
      if (layoutIdPromise && layoutIdPromise.abort) layoutIdPromise.abort();
      layoutIdPromise = responseId ? Promise.resolve(responseId) : null;
      router.setLayoutId(responseId);
    }
    function createLayoutIdPromise () {
      var netPromise = APP.api.getLayoutIdWithPromise();
        netPromise.then(function(responseId){
          setLayoutId(responseId, true);
        })
        .catch(function(err){
          clearLayout();
          router.goTo('image-load');
          APP.dialogs.showAlert('layoutIdError');
          throw err;
        });
      return netPromise;
    }
    function loadLayout (newLayoutId) {
      blockLoader.show();
      if (layoutIdPromise && layoutIdPromise.abort) layoutIdPromise.abort();
      // I'd say the assignment and inner workings of the abort method
      // qualify as control flow obfuscation. Maybe there’s a better way.
      var netPromise;
      layoutIdPromise = new Promise(function(resolve, reject) {
        netPromise = APP.api.getLayoutData(newLayoutId,
          function(layoutData){
            transcribeLayoutData(layoutData);
            setLayoutId(newLayoutId);
            if (layout.measurement) router.goTo('object-config');
            else router.goTo('object-size');
            blockLoader.hide();
            resolve(newLayoutId);
          }, function(err){ // ToDo: implement dedicated handling of 404s
            layoutIdPromise = null;
            APP.dialogs.showConfirm('layoutLoadError', { id: newLayoutId })
              .then(function(confirmed){
                if (confirmed) loadLayout(newLayoutId);
                else router.setLayoutId(null);
              });
            reject(err);
          }, function(event){
            layoutIdPromise = null;
            reject(event);
          });
      });
      layoutIdPromise.abort = netPromise.abort;
    }
    function clearLayout () {
      transcribeLayoutData({});
      setLayoutId(null);
    }
    function postImage () {
      var idPromise = getLayoutIdPromise(),
        imageUploadPromise = idPromise.then(function(responseId){
        // if (layoutId !== responseId) {
        //   throw 'layoutId '+responseId+' is no longer current';
        // }
        var imagePromise = APP.api.postImageWithPromise(responseId, imageUrl)
          .then(function(url){
            return imageUrl = url;
          })
          .catch(function(reason){
            if (reason && reason.type === 'abort') return;
            layout.imageUrl = null;
            router.goTo('image-load');
            APP.dialogs.showAlert('imageUploadError');
          });
        imageUploadPromise.abort = imagePromise.abort;
        imagePromise.then(function(){
          if (layoutIdIsVirgin) uploader.persistLayout(layout);
        });
      });
      imageUploadPromise.abort = idPromise.abort;
      return imageUploadPromise;
    }
    function transcribeLayoutData (layoutData) {
      layout.imageUrl = layoutData.imageUrl;
      measurement = layoutData.measurement;
      textFields = updateLegacyFontIds(fixCharsDataType(layoutData.textFields));
    }
    function updateLegacyFontIds (textFields) {
      return textFields && textFields.map(function(textField){
        textField.fontId = textField.fontId.replace(/^binder(\d+)$/, '$1');
        return textField;
      });
    }
    function fixCharsDataType (textFields) {
      // We do this solely to compensate for the unintended server-side conversion
      // of empty objects to empty arrays. Guess that’s what you get for using PHP :-)
      return textFields && textFields.map(function(textField){
        if (Array.isArray(textField.charsData)) {
          textField.charsData = arrayToObject(textField.charsData);
        }
        return textField;
      });
    }
    function arrayToObject (arr) {
      return arr.reduce(function(obj, value, key){
        obj[key] = value;
        return obj;
      }, {});
    }
  }
  function createUploader (getLayoutIdPromise, getImageUploadPromise, layoutIdCallback) {
    var uploadPromise;
    return {
      persistLayout: persistLayout,
      getUploadPromise: getUploadPromise,
    };
    function persistLayout (layout) {
      return initiateUpload(layout.getStorageData(), layout.getCamData());
    }
    function getUploadPromise () {
      return uploadPromise;
    }
    function initiateUpload (data, camData) {
      var aborted = false, abortFn = function(){
        aborted = true;
      };
      if (uploadPromise && uploadPromise.abort) uploadPromise.abort();
      uploadPromise = Promise.all([getLayoutIdPromise(), getImageUploadPromise()]).then(function(values){
        if (aborted) throw 'aborted';
        var responseId = values[0],
          netPromise = APP.api.putLayoutWithPromise(responseId, data, camData);
        abortFn = netPromise.abort;
        return netPromise.then(function(layoutId){
          if (typeof layoutIdCallback === 'function') layoutIdCallback(layoutId);
          return layoutId;
        }, function(error){
          if (!error || error.type !== 'abort') {
            return new Promise(function(resolve, reject){
              APP.dialogs.showConfirm('layoutStoreError').then(function(confirmed){
                if (confirmed) {
                  initiateUpload(data, camData).then(
                    function(value){ resolve(value); },
                    function(err){ reject(err); }
                  );
                } else {
                  reject(error);
                }
              });
            });
          }
        });
      });
      uploadPromise.abort = function(){
        abortFn();
      };
      return uploadPromise;
    }
  }
})(this.APP || (this.APP = {}));
