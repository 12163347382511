(function(APP){ 'use strict';
  APP.pdfForm = {
    enablePdfForm: enablePdfForm,
  };
  function enablePdfForm (wrapNode, layout) {
    var form = wrapNode.querySelector('.pdfForm');
    ensurePropertiesOnForm(form, form.querySelectorAll('fieldset'));
    enableModeHandling(form);
    enableAdvancedToggle(form);
    enableFormatHandling(form);
    enableMarginsHandling(form);
    enableSubmission(form, layout);
  }
  function ensurePropertiesOnForm (form, nodeList) {
    Array.prototype.slice.call(nodeList).forEach(function(node){
      var name = node.name || node.getAttribute('name');
      if (name && !(name in form)) form[name] = node;
    });
  }
  function enableModeHandling (form) {
    APP.dom.observeRadioValue(form, 'mode', adapt);
    function adapt () {
      var hasPageFormat = formHasPageFormat(form),
        isMultiPage = formIsMultiPage(form);
      toggleFieldset(form.formatGroup, hasPageFormat);
      toggleFieldset(form.marginsGroup, hasPageFormat);
      ['overlap','addPageNumbering','addCropMarks','skipEmptyPages'].forEach(function(name){
        APP.dom.toggleClass(form[name].parentNode, 'disabled', !isMultiPage);
      });
    }
    function toggleFieldset (node, active) {
      node.disabled = !active;
      APP.dom.toggleClass(node, 'disabled', !active);
    }
  }
  function enableAdvancedToggle (form) {
    var button = form.querySelector('.advancedSettingsToggle'),
      icon = button.querySelector('.fa'),
      wrap = form.querySelector('.advancedSettings'),
      active = false;
    button.addEventListener('click', toggle, false);
    function toggle () {
      active = !active;
      APP.dom.toggleClass(icon, 'fa-caret-right', !active);
      APP.dom.toggleClass(icon, 'fa-caret-down', active);
      APP.dom.toggleClass(wrap, 'active', active);
    }
  }
  function enableFormatHandling (form) {
    var formatMap = getPageFormatMap();
    form.format.addEventListener('change', adaptToFormat, false);
    form.width.addEventListener('change', adaptToDimension, false);
    form.height.addEventListener('change', adaptToDimension, false);
    adaptToFormat();
    APP.dom.observeRadioValue(form, 'orientation', adaptToOrientation);
    function adaptToFormat () {
      var formatName = form.format.value,
        dimensions = formatMap[formatName],
        isLandscape = APP.dom.getRadioValue(form, 'orientation') === 'landscape';
      if (dimensions) {
        form.width.value = isLandscape ? dimensions.long : dimensions.short;
        form.height.value = isLandscape ? dimensions.short : dimensions.long;
      } else {
        if (formatName !== 'custom') console.warn('Unknown format '+formatName);
        ensureValidValue(form.width);
        ensureValidValue(form.height);
      }
    }
    function adaptToDimension () {
      var width = Number(form.width.value),
        height = Number(form.height.value);
      if (!width || !height) return;
      var isLandscape = width > height;
      if (APP.dom.getRadioValue(form, 'orientation') !== 'autoRotate') {
        APP.dom.setRadioValue(form, 'orientation', isLandscape ? 'landscape' : 'portrait');
      }
      form.format.value = getMatchingFormat(width, height, isLandscape);
    }
    function adaptToOrientation () {
      var orientation = APP.dom.getRadioValue(form, 'orientation'),
        width = Number(form.width.value) || 0,
        height = Number(form.height.value) || 0;
      if (orientation === 'portrait' && width > height ||
          orientation === 'landscape' && height > width) {
        form.width.value = height;
        form.height.value = width;
      }
    }
    function getMatchingFormat (width, height, isLandscape) {
      var short = isLandscape ? height : width,
        long = isLandscape ? width : height,
        matchingFormat = Object.keys(formatMap).find(function(key){
          var dimensions = formatMap[key];
          return dimensions.short === short && dimensions.long === long;
        });
      return matchingFormat || 'custom';
    }
    function ensureValidValue (numberInput) {
      if (!numberInput.value) numberInput.value = Number(numberInput.min) || 0;
    }
  }
  function enableMarginsHandling (form) {
    var inputs = [form.marginLeft, form.marginRight, form.marginTop, form.marginBottom];
    form.syncMargins.addEventListener('change', onSyncChange, false);
    inputs.forEach(function(input){
      input.addEventListener('change', onInputChange, false);
    });
    function onSyncChange (event) {
      if (event.target.checked) {
        updateInputs(APP.math.getModalValue(collectInputValues()));
      }
    }
    function onInputChange (event) {
      if (form.syncMargins.checked) updateInputs(getNumericValue(event.target));
    }
    function updateInputs (value) {
      inputs.forEach(function(input){
        if (getNumericValue(input) !== value) input.value = value;
      });
    }
    function collectInputValues () {
      return inputs.map(function(input){
          return Number(input.value);
        }).filter(function(value){
          return isFinite(value);
        });
    }
  }
  function enableSubmission (form, layout) {
    form.addEventListener('submit', onSubmit, false);
    if (pdfDataUrlWorksInBrowser(APP.detection.meta.browser)) {
      form.viewButton.addEventListener('click', onViewClick, false);
    } else {
      form.viewButton.disabled = true;
    }
    function pdfDataUrlWorksInBrowser (browserName) {
      // As of 2017-10-17 jsPDF view fails in Chrome-61, IE-11, Edge-13
      return browserName === 'safari' || browserName === 'firefox';
    }
    function onSubmit (event) {
      event.preventDefault();
      download();
    }
    function onViewClick (event) {
      event.preventDefault();
      view();
    }
    function download () {
      tryAndCreatePdf('save', (APP.i18n.strings.pdfFileName || 'layout') + '.pdf');
    }
    function view () {
      tryAndCreatePdf('dataurlnewwindow');
    }
    function tryAndCreatePdf (outputType, outputOptions) {
      if (!validateForm(form)) return;
      var textModels = layout.textModels,
        options = assembleOptions(form),
        mode = APP.dom.getRadioValue(form, 'mode');
      switch (mode) {
        case 'canvasSize':
          return outputPdf(APP.pdf.createCanvasSizePdf(textModels, layout.measurement, options));
        case 'subjectSize':
          return outputPdf(APP.pdf.createCroppedPdf(textModels, options));
        case 'pageSize':
          return outputPdf(APP.pdf.createPageSizePdf(textModels, options));
        case 'tiled':
          return outputPdf(APP.pdf.createTiledPdf(textModels, options));
        default:
          alert('Unknown PDF mode '+mode);
      }
      function outputPdf (doc) {
        doc.output(outputType, outputOptions);
      }
    }
  }
  function assembleOptions (form) {
    var options = {};
    addValues('mode drawMode addDimensions addLayoutLink addDate');
    if (formHasPageFormat(form)) {
      addValues('format orientation width height marginLeft marginRight marginTop marginBottom');
    }
    if (formIsMultiPage(form)) {
      addValues('overlap addPageNumbering addCropMarks skipEmptyPages');
    }
    return options;
    function addValues (names) {
      names.split(' ').forEach(addValue);
    }
    function addValue (name) {
      var element = form[name], type = element.type;
      return options[name] = type === 'checkbox' ? element.checked :
        type === 'number' ? getNumericValue(element) :
        element[0] && element[0].type === 'radio' ? APP.dom.getRadioValue(form, name) :
        element.value;
    }
  }
  function validateForm (form) {
    if (!formHasPageFormat(form)) return true;
    if (!validateFormat()) return showError('pdfFormatError');
    if (!validateMargins()) return showError('pdfMarginsError');
    if (formIsMultiPage(form) && !validateOverlap()) return showError('pdfOverlapError');
    if (!validateTypeArea()) return showError(formIsMultiPage(form) && form.overlap.value ?
        'pdfTypeAreaOverlapError' : 'pdfTypeAreaError');
    return true;
    function showError (name) {
      APP.dialogs.showAlert(name);
      return false;
    }
    function validateFormat () {
      return [form.width, form.height].every(validateNumericInput);
    }
    function validateMargins () {
      return [form.marginLeft, form.marginRight, form.marginTop, form.marginBottom].
          every(validateNumericInput);
    }
    function validateOverlap () {
      return validateNumericInput(form.overlap);
    }
    function validateTypeArea () {
      var minRemainder = 10, overlap = formIsMultiPage(form) ? 'overlap' : null;
      return getRemainder('width', ['marginLeft', 'marginRight', overlap]) >= minRemainder &&
        getRemainder('height', ['marginTop', 'marginBottom', overlap]) >= minRemainder;
      function getRemainder (baseName, subtractionNames) {
        return subtractionNames.reduce(function(remainder, name){
          return name ? remainder - getNumericValue(form[name]) : remainder;
        }, getNumericValue(form[baseName]));
      }
    }
    function validateNumericInput (input) {
      var value = getInputProp(input, 'value', NaN),
        min = getInputProp(input, 'min', -Infinity),
        max = getInputProp(input, 'max', Infinity);
      return isFinite(value) && value >= min && value <= max;
    }
    function getInputProp (input, name, defaultValue) {
      var str = name in input ? input[name] : input.getAttribute(name),
        value = Number(str);
      return str && isFinite(value) ? value : defaultValue;
    }
  }
  function formHasPageFormat (form) {
    var mode = APP.dom.getRadioValue(form, 'mode');
    return mode === 'pageSize' || mode === 'tiled';
  }
  function formIsMultiPage (form) {
    return APP.dom.getRadioValue(form, 'mode') === 'tiled';
  }
  function getNumericValue (input) {
    return Number(input.value) || 0;
  }
  function getPageFormatMap () {
    // taken from jspdf, unfortunately pageFormats is not publicly accessible
    var pageFormats = { // Size in pt of various paper formats
      'a0': [2383.94, 3370.39],
      'a1': [1683.78, 2383.94],
      'a2': [1190.55, 1683.78],
      'a3': [841.89, 1190.55],
      'a4': [595.28, 841.89],
      'a5': [419.53, 595.28],
      'a6': [297.64, 419.53],
      'a7': [209.76, 297.64],
      'a8': [147.40, 209.76],
      'a9': [104.88, 147.40],
      'a10': [73.70, 104.88],
      'b0': [2834.65, 4008.19],
      'b1': [2004.09, 2834.65],
      'b2': [1417.32, 2004.09],
      'b3': [1000.63, 1417.32],
      'b4': [708.66, 1000.63],
      'b5': [498.90, 708.66],
      'b6': [354.33, 498.90],
      'b7': [249.45, 354.33],
      'b8': [175.75, 249.45],
      'b9': [124.72, 175.75],
      'b10': [87.87, 124.72],
      'c0': [2599.37, 3676.54],
      'c1': [1836.85, 2599.37],
      'c2': [1298.27, 1836.85],
      'c3': [918.43, 1298.27],
      'c4': [649.13, 918.43],
      'c5': [459.21, 649.13],
      'c6': [323.15, 459.21],
      'c7': [229.61, 323.15],
      'c8': [161.57, 229.61],
      'c9': [113.39, 161.57],
      'c10': [79.37, 113.39],
      'dl': [311.81, 623.62],
      'letter': [612, 792],
      'government-letter': [576, 756],
      'legal': [612, 1008],
      'junior-legal': [576, 360],
      'ledger': [1224, 792],
      'tabloid': [792, 1224],
      'credit-card': [153, 243]
    };
    return Object.keys(pageFormats).reduce(function(mmFormatMap, key){
      var dimensions = pageFormats[key];
      mmFormatMap[key] = {
        short: APP.math.pointsToMillimeters(dimensions[0]),
        long: APP.math.pointsToMillimeters(dimensions[1])
      };
      return mmFormatMap;
    }, {});
  }
})(this.APP || (this.APP = {}));
