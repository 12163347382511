(function(APP, JSON, undefined){ 'use strict';
  APP.utils = {
    makeEventDispatcher: makeEventDispatcher,
    createColorGenerator: createColorGenerator,
    createUniqueIdGenerator: createUniqueIdGenerator,
    defaultIdGenerator: createUniqueIdGenerator(),
    jsonEquals: jsonEquals,
    reducePrecision: reducePrecision,
    reduce: reduce,
  };
  function reduce(obj, key) {
    var keySplit = key.split('.');
    if (keySplit.length > 1) {
      return reduce(obj[keySplit[0]], keySplit.slice(1, keySplit.length).join("."));
    }
    if (keySplit.length == 1) {
      return obj[key];
    } else {
      return obj;
    }
  }
  function makeEventDispatcher (that, names) {
    var events, dict = {};
    if (!that) that = {};
    that.events = events = stringListToMap(splitNames(names));
    that.on = on;
    that.off = off;
    that.once = once;
    that.trigger = trigger;
    return that;
    function on (names, callbackFn) {
      if (!isFunction(callbackFn)) throw new Error('event callbackFn expected');
      validateNames(names).forEach(function(name){
        if (!dict[name]) dict[name] = makeUniqueList();
        dict[name].addItem(callbackFn);
      });
    }
    function off (names, callbackFn) {
      var callbackGiven = isFunction(callbackFn);
      validateNames(names).forEach(function(name){
        if (callbackGiven && dict[name]) dict[name].removeItem(callbackFn);
        else delete dict[name];
      });
    }
    function once (names, callbackFn) {
      on(names, proxy);
      function proxy (e) {
        off(names, proxy);
        callbackFn(e);
      }
    }
    function trigger (names, data) {
      validateNames(names).forEach(function(name){
        if (!dict[name]) return;
        var e = { target:that, type:name, data:data };
        dict[name].iterate(function(callbackFn){
          callbackFn(e);
        });
      });
    }
    function splitNames (names) {
      if (!isString(names) || !/[^ ]/.test(names)) {
        throw new Error('You need to specify a space-separated token string of event names.');
      }
      return names.split(' ');
    }
    function validateNames (names) {
      var cleanNames = splitNames(names).filter(function(name){
        var isKnown = name in events;
        if (!isKnown && console && console.warn) console.warn('unknown event '+name);
        return isKnown;
      });
      if (!cleanNames.length) throw new Error('no known event name given');
      return cleanNames;
    }
  }
  function makeUniqueList (that) {
    if (!that) that = {};
    var items = [];
    that.addItem = addItem;
    that.removeItem = removeItem;
    that.iterate = iterate;
    return that;
    function addItem (item) {
      if (items.indexOf(item) < 0) items.push(item);
    }
    function removeItem (item) {
      var index = items.indexOf(item);
      if (index >= 0) items.splice(index, 1);
    }
    function iterate (callbackFn) {
      items.slice().forEach(callbackFn);
    }
  }
  function stringListToMap (arr) {
    return arr.reduce(function(map, str){
      map[str] = str;
      return map;
    }, {});
  }
  function isString (value) {
    return typeof value === 'string';
  }
  function isFunction (value) {
    return typeof value === 'function';
  }
  function jsonEquals (value1, value2) {
    return value1 === value2 ||
      isVoid(value1) && isVoid(value2) ||
      JSON.stringify(value1) === JSON.stringify(value2);
  }
  function isVoid (value) {
    return value === undefined || value === null;
  }
  function createColorGenerator () {
    var goldenRatio = (Math.sqrt(5) - 1) / 2,
      ratio = Math.random();
    return {
      next: next,
    };
    function next () {
      ratio = (ratio + goldenRatio) % 1;
      return 'hsla(' + ratio * 360 + ', 100%, 50%, 0.5';
    }
  }
  function createUniqueIdGenerator (prefix) {
    var counter = 0;
    if (!prefix) prefix = 'id';
    return {
      next: next,
    };
    function next () {
      return prefix + counter++;
    }
  }
  function reducePrecision (dataStructure, numDecimals) {
    var factor = Math.pow(10, numDecimals || 0),
      keys = Object.keys,
      round = Math.round;
    return reduce(dataStructure);
    function reduce (obj) {
      if (typeof obj === 'object' && obj !== null) {
        keys(obj).forEach(function(key){
          obj[key] = reduce(obj[key]);
        });
        return obj;
      } else if (typeof obj === 'number') {
        return round(obj * factor) / factor;
      } else {
        return obj;
      }
    }
  }
})(this.APP || (this.APP = {}), this.JSON);
