(function(APP){ 'use strict';
  APP.localSettings = {
    getItem: getItem,
    setItem: setItem,
    removeItem: removeItem,
  };
  function getItem (key, maxAgeMs) {
    try {
      var item = decodeStorageItem(localStorage.getItem(key));
      return item && isFresh(item.date, maxAgeMs) ? item.value : null;
    } catch (err) {
      return null;
    }
  }
  function setItem (key, value) {
    try {
      localStorage.setItem(key, encodeStorageItem(value));
    } catch (err) {
      console.error(err);
    }
  }
  function removeItem (key, value) {
    try {
      localStorage.removeItem(key);
    } catch (err) {
      console.error(err);
    }
  }
  function encodeStorageItem (value) {
    return JSON.stringify({
      value: value,
      date: Date.now(),
    });
  }
  function decodeStorageItem (record) {
    try {
      var item = JSON.parse(record);
      return isValidObject(item) && 'value' in item ? item : record;
    } catch (err) {
      return record;
    }
  }
  function isFresh (date, maxAgeMs) {
    return !date || !isValidNumber(maxAgeMs) || date + maxAgeMs >= Date.now()
  }
  function isValidNumber (value) {
    return typeof value === 'number' && isFinite(value);
  }
  function isValidObject (value) {
    return typeof value === 'object' && value !== null;
  }
})(this.APP || (this.APP = {}), this.document);
