(function(APP, document){ 'use strict';
  APP.dom = {
    createHtmlNode: createHtmlNode,
    createSvgNode: createSvgNode,
    panScanSvgNode: panScanSvgNode,
    setAttributes: setAttributes,
    prependChild: prependChild,
    removeNode: removeNode,
    removeChildren: removeChildren,
    addClass: addClass,
    removeClass: removeClass,
    toggleClass: toggleClass,
    loadImage: loadImage,
    defocus: defocus,
    getRadioValue: getRadioValue,
    setRadioValue: setRadioValue,
    observeRadioValue: observeRadioValue,
    observeDrag: observeDrag,
  };
  function createHtmlNode (name, propertiesSpec, parentNode) {
    var node = document.createElement(name);
    if (propertiesSpec) Object.assign(node, propertiesSpec);
    if (parentNode) parentNode.appendChild(node);
    return node;
  }
  function createSvgNode (localName, attributesSpec, parentNode) {
    var node = document.createElementNS('http://www.w3.org/2000/svg', localName);
    if (attributesSpec) setAttributes(node, attributesSpec);
    if (parentNode) parentNode.appendChild(node);
    return node;
  }
  function panScanSvgNode (svgNode, left, top, width, height, scale) {
    if (typeof scale !== 'number') scale = 1;
    svgNode.style.left = left * scale + 'px';
    svgNode.style.top = top * scale + 'px';
    svgNode.setAttribute('width', width);
    svgNode.setAttribute('height', height);
    svgNode.setAttribute('viewBox', [left, top, width, height].join(' '));
    svgNode.style.width = width * scale + 'px';
    svgNode.style.height = height * scale + 'px';
  }
  function setAttributes (node, spec) {
    Object.keys(spec).forEach(function(key){
      node.setAttribute(key, spec[key]);
    });
  }
  function prependChild (parentNode, childNode) {
    var firstChild = parentNode.firstChild;
    if (firstChild !== childNode) {
      if (firstChild) parentNode.insertBefore(childNode, firstChild);
      else parentNode.appendChild(childNode);
    }
  }
  function removeNode (node) {
    if (node && node.parentNode) node.parentNode.removeChild(node);
  }
  function removeChildren (parentNode) {
    var childNode;
    if (!parentNode) return;
    while (childNode = parentNode.lastChild) {
      parentNode.removeChild(childNode);
    }
  }
  function addClass (node, name) {
    toggleClass(node, name, true);
  }
  function removeClass (node, name) {
    toggleClass(node, name, false);
  }
  function toggleClass (node, name, active) {
    var classes = node.className.split(/\s+/)
        .filter(function(name){ return !!name; }),
      index = classes.indexOf(name),
      present = index >= 0;
    active = active === undefined || active === null ? !present : !!active;
    if (active !== present) {
      if (active) classes.push(name);
      else classes.splice(index, 1);
      node.className = classes.join(' ');
    }
    return active;
  }
  function loadImage (url, successFn, errorFn) {
    var img = document.createElement('img');
    toggleListeners(true);
    img.src = url;
    if (img.width) {
      toggleListeners(false);
      setTimeout(succeed, 0);
    }
    function onLoad () {
      toggleListeners(false);
      succeed();
    }
    function onError (err) {
      toggleListeners(false);
      if (errorFn) errorFn(err);
    }
    function toggleListeners (active) {
      var fnName = active ? 'addEventListener' : 'removeEventListener';
      img[fnName]('load', onLoad, false);
      img[fnName]('load', onError, false);
    }
    function succeed () {
      if (successFn) successFn(img);
    }
  }
  function defocus () {
    var node = document.querySelector(':focus');
    if (node && [document, document.documentElement, document.body].indexOf(node) < 0) {
      node.blur();
    }
  }
  function getRadioValue (formNode, radioName) {
    return formNode[radioName].value ||
      toArray(formNode[radioName]).reduce(function(value, node){
        return value || (node.checked ? node.value : undefined);
      }, undefined);
  }
  function setRadioValue (form, radioName, value) {
    var collection = form[radioName];
    if ('value' in collection) collection.value = value;
    else toArray(collection).forEach(function(node){
      node.checked = node.value === value;
    });
  }
  function observeRadioValue (formNode, radioName, valueCallback) {
    var nodes = toArray(formNode.querySelectorAll('[name="'+radioName+'"]')),
      currentValue = null;
    toggleListeners(true);
    update();
    return destroy;
    function update () {
      var newValue = getRadioValue(formNode, radioName);
      if (currentValue === newValue) return;
      currentValue = newValue;
      valueCallback(currentValue);
    }
    function toggleListeners (active) {
      var fnName = active ? 'addEventListener' :'removeEventListener';
      nodes.forEach(function(node){
        node[fnName]('change', update, false);
      });
    }
    function destroy () {
      if (!nodes) return;
      toggleListeners(false);
      nodes = null;
    }
  }
  function toArray (arrayLikeObject) {
    return Array.prototype.slice.call(arrayLikeObject);
  }
  // (function(node){
  //   observeDrag(node, function(initPoint){
  //     var left = parseInt(node.style.left);
  //     return function(dragPoint, deltaX, deltaY){
  //       node.style.left = left + deltaX + 'px';
  //     };
  //   });
  // })(document.createElement('div'));
  
  function observeDrag (node, onStartDragCallback, onEndDragCallback) {
    var enabled = false, dragging = false;
    enable();
    return {
      enable: enable,
      disable: disable
    };
    function enable () {
      if (!enabled) toggleStartListeners(true);
    }
    function disable () {
      if (enabled) toggleStartListeners(false);
    }
    function toggleStartListeners (active) {
      var fnName = active ? 'addEventListener' : 'removeEventListener';
      node[fnName]('mousedown', onMouseDownTouchStart, false);
      node[fnName]('touchstart', onMouseDownTouchStart, false);
      enabled = active;
    }
    function onMouseDownTouchStart (event) {
      if (dragging || event.button) return;
      event.preventDefault();
      var initPoint = getPointerCoords(event),
        events = event.type === 'touchstart' ?
          ['touchmove', 'touchend', 'touchcancel'] :
          ['mousemove', 'mouseup'],
        onMoveCallback = onStartDragCallback(initPoint);
      toggleListeners(true);
      function onMove (event) {
        if (enabled) {
          var point = getPointerCoords(event);
          onMoveCallback(point, point.x - initPoint.x, point.y - initPoint.y);
        } else toggleStartListeners(false);
      }
      function onEnd () {
        toggleListeners(false);
        if (typeof onEndDragCallback === 'function') onEndDragCallback();
      }
      function toggleListeners (active) {
        var fnName = active ? 'addEventListener' : 'removeEventListener';
        events.forEach(function(name, i){
          document[fnName](name, i ? onEnd : onMove, false);
        });
        dragging = active;
      }
    }
    function getPointerCoords (event) {
      var obj = event.touches && event.touches[0] || event;
      return { x: obj.pageX, y: obj.pageY };
    }
  }
})(this.APP || (this.APP = {}), this.document);
