(function(APP, numeric){ 'use strict';
  APP.geom = {
    applyMatrix: applyMatrix,
    toCssMatrix: toCssMatrix,
    parseMatrix: parseMatrix,
    transposeMatrix: transposeMatrix,
    invertMatrix: numeric.inv,
    createProjectionMatrix: createProjectionMatrix,
    getBoundingRect: getBoundingRect,
    getRectsBoundingRect: getRectsBoundingRect,
    rectToPoints: rectToPoints,
    rectAttributesToPoints: rectAttributesToPoints,
    createRect: createRect,
    expandRect: expandRect,
    rectEncompassesRect: rectEncompassesRect,
    rectsCollide: rectsCollide,
    pointInRect: pointInRect,
    round: round,
  };
  // var matrix = [
  //     [1, 0, 0],
  //     [0, 1, 0],
  //     [0, 0, 1],
  //   ],
  //   matrix3d = [
  //     [1, 0, 0, 0],
  //     [0, 1, 0, 0],
  //     [0, 0, 1, 0],
  //     [0, 0, 0, 1],
  //   ];
  function applyMatrix (matrix, point) {
    return vectorToPoint(cross(matrix, pointToVector(point, matrix.length === 4)));
  }
  function pointToVector (point, includeZ) {
      var vector = [
        point.x,
        point.y,
        includeZ ? point.z || 0 : 1
      ];
    if (includeZ) vector.push(1);
    return vector;
  }
  function vectorToPoint (vector) {
    var divisor = vector[vector.length - 1],
      point = {
        x: vector[0] / divisor,
        y: vector[1] / divisor
      };
    if (vector.length === 4) point.z = vector[2] / divisor;
    return point;
  }
  function cross (matrix, vector) {
    return vector.map(function(vectorVal, i){
      return matrix[i].reduce(function(sum, val, j){
        return sum + val * vector[j];
      }, 0);
    });
  }
  function toCssMatrix (matrix) {
    var is3d = matrix.length === 4;
    return (is3d ? 'matrix3d' : 'matrix')+'('+serializeMatrix(matrix, !is3d)+')';
  }
  function serializeMatrix (matrix, skipLastRow) {
    var series = [],
      rows = matrix.length - (skipLastRow ? 1 : 0),
      cols = matrix[0].length,
      col = 0, row;
    while (col < cols) {
      row = 0;
      while (row < rows) {
        series.push(matrix[row][col]);
        row++;
      }
      col++;
    }
    return series;
  }
  function parseMatrix (cssMatrix) {
    var series = cssMatrix
                  .replace(/^.+\(([^\)]+)\).*$/, '$1')
                  .split(',')
                  .map(function(chunk){
                    return Number(chunk.trim());
                  });
    if (series.length < 6 || series.length > 16) return null;
    if (series.length === 6) {
      series.splice(2, 0, 0);
      series.splice(5, 0, 0);
      series.splice(8, 0, 1);
    }
    return seriesToMatrix(series);
  }
  function seriesToMatrix (series) {
    var length = series.length,
      rows =  Math.sqrt(length),
      cols = rows,
      matrix = [],
      i = 0;
    if (typeof length !== 'number') {
      throw new Error('seriesToMatrix needs array-like argument');
    }
    while (i < length) addEntry(i % cols, Math.floor(i / cols), series[i++]);
    return matrix;
    function addEntry (rowIndex, colIndex, value) {
      var row = matrix[rowIndex] || (matrix[rowIndex] = []);
      row[colIndex] = value;
    }
  }
  function transposeMatrix (matrix) {
    var resultMatrix = [];
    matrix.forEach(function(row, i){
      row.forEach(function(value, j){
        addEntry(j, i, value);
      });
    });
    return resultMatrix;
    function addEntry (row, col, value) {
      (resultMatrix[row] || (resultMatrix[row] = []))[col] = value;
    }
  }
  function createProjectionMatrix (inQuad, outQuad) {
    var A = [], b = [], X;
    inQuad.forEach(function(inPoint, i){
      var x1 = inPoint.x, y1 = inPoint.y, x2 = outQuad[i].x, y2 = outQuad[i].y;
      A.push([x1, y1, 1, 0, 0, 0, -x1 * x2, -y1 * x2],
             [0, 0, 0, x1, y1, 1, -x1 * y2, -y1 * y2]);
      b.push(x2, y2);
    });
    X = numeric.solve(A, b, false).map(function(num){ return round(num, 6); });
    return seriesToMatrix([
      X[0], X[3], 0, X[6],
      X[1], X[4], 0, X[7],
         0,    0, 1,    0,
      X[2], X[5], 0,    1
    ]);
  }
  function getBoundingRect (points) {
    var max = Math.max, min = Math.min,
      point = points[0] || { x:0, y:0 },
      rect = points.reduce(function(rect, point){
        rect.left = min(rect.left, point.x);
        rect.top = min(rect.top, point.y);
        rect.right = max(rect.right, point.x);
        rect.bottom = max(rect.bottom, point.y);
        return rect;
      }, { left:point.x, top:point.y, right:point.x, bottom:point.y });
    rect.width = rect.right - rect.left;
    rect.height = rect.bottom - rect.top;
    return rect;
  }
  function getRectsBoundingRect (rects) {
    if (!rects.length) return createRect(0, 0, 0, 0);
    var left = Infinity, top = Infinity, right = -Infinity, bottom = -Infinity,
      min = Math.min, max = Math.max;
    rects.forEach(function(rect){
      left = min(left, rect.left);
      top = min(top, rect.top);
      right = max(right, rect.right);
      bottom = max(bottom, rect.bottom);
    });
    return createRect(left, top, right, bottom);
  }
  function rectToPoints (rect) {
    return rectAttributesToPoints(rect.left, rect.top, rect.width, rect.height);
  }
  function rectAttributesToPoints (left, top, width, height) {
    var right = left + width, bottom = top + height;
    return [
      { x:left, y:top },
      { x:right, y:top },
      { x:right, y:bottom },
      { x:left, y:bottom }
    ];
  }
  function createRect (left, top, right, bottom) {
    return {
      left: left, top: top, right: right, bottom: bottom,
      width: right - left, height: bottom - top
    };
  }
  function expandRect (rect, margin) {
    return createRect(
      rect.left - margin,
      rect.top - margin,
      rect.right + margin,
      rect.bottom + margin
    );
  }
  function rectEncompassesRect (outerRect, innerRect) {
    return innerRect.left >= outerRect.left && innerRect.top >= outerRect.top &&
      innerRect.right <= outerRect.right && innerRect.bottom <= outerRect.bottom;
  }
  function rectsCollide (rect1, rect2) {
    return !(rect1.right < rect2.left || rect1.bottom < rect2.top ||
      rect1.left > rect2.right || rect1.top > rect2.bottom);
  }
  function pointInRect (point, rect) {
    return point.x >= rect.left && point.x <= rect.right &&
      point.y >= rect.top && point.y <= rect.bottom;
  }
  function round (num, decimals) {
    var factor = Math.pow(10, decimals || 0);
    return Math.round(num * factor) / factor;
  }
})(this.APP || (this.APP = {}), this.numeric);
