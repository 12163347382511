(function(APP, document){ 'use strict';
  APP.transformer = {
    makeDraggable: makeDraggable,
    startDrag: startDrag,
  };
  function makeDraggable (sprite, coordMappingFn) {
    var node = sprite.node;
    node.addEventListener('mousedown', onMouseDownTouchStart, false);
    node.addEventListener('touchstart', onMouseDownTouchStart, false);
    node.style.cursor = 'move';
    function onMouseDownTouchStart (event) {
      event.preventDefault();
      startDrag(sprite, event, coordMappingFn);
    }
  }
  function startDrag (sprite, event, coordMappingFn, dragRect) {
    var useTouch = event.type === 'touchstart',
      initSpriteX = sprite.x, initSpriteY = sprite.y,
      getCoords = createCoordsGetter(),
      initPoint = getCoords(event),
      events = useTouch ?
        ['touchmove', 'touchend', 'touchcancel'] :
        ['mousemove', 'mouseup'];
    toggleListeners(true);
    function onMove (event) {
      var point = getCoords(event);
      sprite.x = getValidX(initSpriteX + point.x - initPoint.x);
      sprite.y = getValidY(initSpriteY + point.y - initPoint.y);
    }
    function onEnd () {
      toggleListeners(false);
    }
    function toggleListeners (active) {
      var fnName = active ? 'addEventListener' : 'removeEventListener';
      events.forEach(function(name, i){
        document[fnName](name, i ? onEnd : onMove, false);
      });
    }
    function createCoordsGetter () {
      return typeof coordMappingFn !== 'function' ? getPointerCoords : function(event){
        return coordMappingFn(getPointerCoords(event));
      };
    }
    function getValidX (x) {
      return dragRect ? Math.max(dragRect.left, Math.min(dragRect.right, x)) : x;
    }
    function getValidY (y) {
      return dragRect ? Math.max(dragRect.top, Math.min(dragRect.bottom, y)) : y;
    }
  }
  function getPointerCoords (event) {
    var obj = event.touches && event.touches[0] || event;
    return { x: obj.pageX, y: obj.pageY };
  }
})(this.APP || (this.APP = {}), this.document);
